@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessHeader {
	width: 100%;
	height: auto;
	@include flex_row(flex-start, center);

	&_left {
		width: 50%;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_count {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}
	&_right {
		width: 50%;
		height: 100%;
		@include flex_row(flex-end, flex-end);

		&_count {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-family: map-get($map: $fonts, $key: "altSans");
			& > b {
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
}
