@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UrgentBanner {
	position: fixed;
	top: 50%;
	left: 50%;
	max-width: 90%;
	max-height: 90%;
	width: 90vw;
	height: 90vh;
	margin-left: -45vw;
	margin-top: -45vh;
	padding: 0 1rem;
	background-color: #ffffff;
	border-radius: 1rem;
	@include slide_in_center;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	box-shadow: 10px 10px 108px 200px rgba(0, 0, 0, 0.91);
	border-top: 5px solid map-get($map: $redMap, $key: "600");
	z-index: 99999;

	&_top {
		width: 100%;
		height: auto;
		padding: 3rem 2rem;
		@include flex_row(flex-start, center);

		&_iconWrapper {
			width: 10rem;
			min-width: 10rem;
			max-width: 10rem;
			height: 10rem;
			min-height: 10rem;
			max-height: 10rem;
			border-radius: 50%;
			// border: 1px solid #ccc;
			background-color: map-get($map: $redMap, $key: "600");
			@include flex_row(center, center);
			box-shadow: map-get($map: $shadows, $key: "medium");
			margin-right: 2rem;

			&_icon {
				margin-top: -1rem;
				width: 8rem;
				height: 8rem;
				fill: map-get($map: $redMap, $key: "50");
				cursor: pointer;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;

				&:hover {
					background-color: map-get($map: $greys, $key: "chalk");
					border-radius: 0.5rem;
					-webkit-transform: rotateZ(360deg);
					transform: rotateZ(360deg);
				}
			}
		}

		&_titles {
			width: 100%;
			height: auto;

			&_title {
				font-size: 4rem;
				font-weight: 600;
				color: map-get($map: $redMap, $key: "600");
			}
			&_subtitle {
				font-size: 2.5rem;
				font-weight: 500;
				color: map-get($map: $redMap, $key: "400");
			}
		}
	}
	&_main {
		width: 100%;
		height: auto;
		max-height: 70%;
		padding: 2rem 2rem;
		padding-bottom: 4rem;
		overflow-y: auto;
	}
	&_msg {
		width: 100%;
		padding: 0 1rem;
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $yellowMap, $key: "600");
	}
	&_actions {
		position: absolute;
		bottom: 0rem;
		right: 0;
		width: 100%;
		height: 7rem;
		padding: 0 1rem;
		@include flex_row(flex-end, center);
		// background-color: map-get($map: $redMap, $key: "200");
	}
}

@media only screen and (max-width: 700px) {
	.UrgentBanner {
		position: fixed;
		top: 50%;
		left: 50%;
		max-width: 98%;
		max-height: 98%;
		width: 98vw;
		height: 98vh;
		margin-left: -49vw;
		margin-top: -49vh;
		background-color: #ffffff;
		border-radius: 1rem;
		box-shadow: map-get($map: $shadows, $key: "focusMode");
		box-shadow: 10px 10px 108px 200px rgba(0, 0, 0, 0.91);
		z-index: 20;

		&_top {
			width: 100%;
			height: auto;
			padding: 3rem 0.8rem;
			@include flex_row(flex-start, center);

			&_iconWrapper {
				width: 5rem;
				min-width: 5rem;
				max-width: 5rem;
				height: 5rem;
				min-height: 5rem;
				max-height: 5rem;
				border-radius: 50%;
				// border: 1px solid #ccc;
				background-color: map-get($map: $redMap, $key: "600");
				@include flex_row(center, center);
				box-shadow: map-get($map: $shadows, $key: "medium");
				margin-right: 0.5rem;

				&_icon {
					margin-top: -1rem;
					width: 4rem;
					height: 4rem;
					fill: map-get($map: $redMap, $key: "50");
					cursor: pointer;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;

					&:hover {
						background-color: map-get($map: $greys, $key: "chalk");
						border-radius: 0.5rem;
						-webkit-transform: rotateZ(360deg);
						transform: rotateZ(360deg);
					}
				}
			}
			&_titles {
				width: 100%;
				height: auto;

				&_title {
					font-size: 2rem;
					font-weight: 600;
					color: map-get($map: $redMap, $key: "600");
				}
				&_subtitle {
					font-size: 1.5rem;
					font-weight: 500;
					color: map-get($map: $redMap, $key: "400");
				}
			}
		}
		&_main {
			width: 100%;
			height: auto;
			min-height: 30rem;
			max-height: 65%;
		}
		&_msg {
			width: 100%;
			height: auto;
			padding: 1rem 1rem;
		}
	}
}
