@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CustomResidentSearchResults {
	width: 100%;
	height: auto;

	&_list {
		list-style-type: circle;
	}
	&_noResults {
		width: 100%;
		height: auto;
		min-height: 15rem;
		@include flex_row(center, center);
	}
}

.ResidentSearchEntry {
	width: 100%;
	padding: 1rem 1rem;
	@include flex_col(flex-start, flex-start);
	border: 1px solid map-get($map: $blueGreyMap, $key: "300");
	border-radius: 1rem;
	background-color: #ffffff;
	margin: 0.8rem 0;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	&_inner {
		width: 100%;
		@include flex_row(space-between, center);

		&_name {
			width: 100%;
			margin-left: 2rem;
			@include flex_row(space-between, center);
			position: relative;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			&_isArchived {
				position: absolute;
				top: 0.5rem;
				left: -2rem;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				background-color: map-get($map: $redMap, $key: "300");
				border: 1px solid map-get($map: $redMap, $key: "500");
			}

			&_msg {
				font-size: 1.6rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "600");
				margin-bottom: 0.5rem;

				& b {
					font-family: map-get($map: $fonts, $key: "altSans");
					font-weight: 500;
				}
			}
			&_editBtn {
				margin-left: auto;
				border: none;
				outline: none;
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "600");
				background-color: transparent;
				cursor: pointer;
			}
		}
		&_email {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_showMoreBtn {
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			border: none;
			outline: none;
			background-color: transparent;
			margin-left: 0.3rem;
			&:hover {
				cursor: pointer;
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
	&_showMore {
		width: 100%;
		min-height: 7rem;
		padding: 1rem 0.5rem;
		@include flex_row(space-between, flex-start);

		&_section {
			width: auto;
			margin-right: 2rem;

			&_desc {
				font-size: 1.4rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "400");
				text-decoration: underline;
				margin-bottom: 0.5rem;
			}

			&_details {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 0.3rem;
			}
		}
		&_options {
			width: auto;

			&_item {
				font-size: 1.3rem;
				font-weight: 500;
			}
		}
	}
	&_isDeletedIndicator {
		position: absolute;
		top: 0.2rem;
		left: 12rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: map-get($map: $redMap, $key: "400");
		z-index: 100;
		cursor: pointer;
	}
}

.LoadingSearchResults {
	margin-top: 2rem;
	@include flex_col(center, center);

	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
