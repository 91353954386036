@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.OTPChar {
	width: 4rem;
	height: 5rem;
	border-radius: 1rem;
	background-color: #eaecef;

	&_fieldWrapper {
		@include fill_container;
		border: 5px solid map-get($map: $blueGreyMap, $key: "300");
		border-radius: 1rem;

		&_field {
			@include fill_container;
			@include flex_row(center, center);
			background-color: map-get($map: $blueGreyMap, $key: "50");
			text-align: center;
			font-size: 3rem;
			font-weight: 600;
			border-radius: 1rem;
			border: none;
			outline: none;
			padding: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
	}
}
