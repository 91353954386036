@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ModulePermission {
	width: 100%;
	height: auto;
	padding: 1rem 2rem;
	@include flex_row(flex-start, center);
	&:nth-child(odd) {
		background-color: map-get($map: $blueMap, $key: "50");
	}

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
		&:hover {
			cursor: pointer;
			color: map-get($map: $blueMap, $key: "600");
		}
	}
}
.ModuleEnabler {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	@include flex_row(center, center);
	border: 1px solid #ccc;
	margin-left: auto;
	background-color: #ffffff;
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $blueMap, $key: "300");
	}

	&_icon {
		width: 2.7rem;
		height: 2.7rem;
		fill: #ffffff;
	}
}
