@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateActions {
	width: 100%;
	// height: 9rem;
	height: 12rem;
	border-radius: 1rem 1rem 0 0;
	background-color: map-get($map: $blueGreyMap, $key: "800");
	margin-bottom: 4rem;

	&_top {
		width: 100%;
		height: 3rem;
		padding: 0.5rem 1rem;

		&_heading {
			font-size: 1.8rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "100");
		}
	}

	&_main {
		padding: 0 1rem;
		width: 100%;
		height: 100%;
		@include flex_row(space-between, center, wrap);
	}
}
