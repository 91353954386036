@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FeedbackForm {
	width: 100%;
	height: auto;
	padding: 0.5rem 1rem;
	border-radius: 1rem;

	&_textarea {
		width: 100%;
		height: 7rem;
		resize: none;
		border: 2px solid map-get($map: $blueGreyMap, $key: "200");
		outline: none;
		padding: 0.5rem 0.5rem;
		border-radius: 1rem;
		font-family: map-get($map: $fonts, $key: "mainSans");
		font-size: 1.6rem;

		&::placeholder {
			color: map-get($map: $blueGreyMap, $key: "300");
			font-weight: 500;
		}
	}
	&_bottom {
		width: 100%;
		height: 5rem;
		@include flex_row(center, center);

		&_screenshotBtn {
			padding: 1rem 1rem;
			border-radius: 1rem;
			@include flex_row(center, center);
			border: none;
			outline: none;
			margin-right: 0.5rem;
			background-color: map-get($map: $blueGreyMap, $key: "200");

			&_icon {
				width: 1.7rem;
				height: 1.7rem;
				fill: map-get($map: $blueGreyMap, $key: "700");
			}
		}
		&_sendBtn {
			width: 100%;
			padding: 1rem 1rem;
			border-radius: 1rem;
			font-size: 1.4rem;
			font-weight: 600;
			background-color: map-get($map: $purpleMap, $key: "700");
			color: #ffffff;
			border: none;
			outline: none;
			@include flex_row(center, center);
			&:disabled {
				background-color: map-get($map: $blueGreyMap, $key: "200");
				color: map-get($map: $blueGreyMap, $key: "600");
				opacity: 0.9;
			}
		}
	}
}
