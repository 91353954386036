@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MaintenanceModePage {
	@include fill_container;
	@include flex_col(center, center);
	padding: 2rem;
	text-align: center;

	&_logo {
		@include flex_row(center, center);
		width: 100%;
		max-width: 100%;
		max-height: 15rem;
		height: 15rem;
		background-color: transparent;
		margin-bottom: 4rem;

		&_image {
			width: 100%;
			max-width: 100%;
			max-height: 15rem;
			height: 15rem;
			object-fit: contain;
		}
	}
}

.MaintenanceMessage {
	width: 100%;
	height: auto;
	@include flex_col(center, center);
	text-align: center;
	margin-top: 4rem;

	&_title {
		font-size: 1.8rem;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_link {
		margin-top: 2rem;
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueMap, $key: "600");
	}
}
