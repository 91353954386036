@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCModal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 70rem;
	height: 55rem;
	margin-left: -35rem;
	margin-top: -27.5rem;
	border-radius: 1rem;
	background-color: #ffffff;
	// box-shadow: map-get($map: $shadows, $key: "focusMode");
	@include add_center_focus(70rem, 55rem);

	&_top {
		width: 100%;
		height: 4rem;
		padding: 0.5rem 1rem;
		background-color: #eaecef;
		border-radius: 1rem 1rem 0 0;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_close {
			width: 3rem;
			height: 3rem;
			margin-left: auto;
			fill: map-get($map: $redMap, $key: "600");
			cursor: pointer;
		}
	}
	&_main {
		width: 100%;
		height: auto;
		max-height: 92%;
		overflow-y: auto;

		/* Background tracking */
		&::-webkit-scroll-track {
			background-color: #eaecef;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.8rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "400");
			border-radius: 5rem;
		}
	}
}
