@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AccountUnlockNotice {
	width: 100%;
	padding: 1rem 2rem;

	&_heading {
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "800");
		margin-bottom: 1.5rem;

		& > mark {
			padding: 0 0.3rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "200");
			color: map-get($map: $purpleMap, $key: "700");
		}
	}

	&_details {
		font-size: 1.5rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");

		&_list {
			margin-top: 0.5rem;
			padding-left: 2rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
			list-style: disc;

			&_option {
				margin-bottom: 0.3rem;
			}
		}

		& mark {
			padding: 0 0.3rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $greenMap, $key: "200");
			color: map-get($map: $greenMap, $key: "800");
		}
	}
	&_otpInfo {
		width: 100%;
		padding: 2rem;

		&_title {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}

		&_item {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			padding: 0.3rem;
		}
	}
}
