@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AppMessagesPanel {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_desc {
			font-size: 1.6rem;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
	&_editor {
		@include fill_container;

		&_action {
			width: auto;
			margin-bottom: 2rem;

			&_btn {
				padding: 0.8rem 1.7rem;
				border-radius: 5rem;
				font-size: 1.6rem;
				font-weight: 600;
				border: none;
				outline: none;
				background-color: map-get($map: $redMap, $key: "600");
				color: #ffffff;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}
			}
		}
	}
	&_actions {
		width: 100%;
		margin-top: 2rem;
		@include flex_row(flex-end, center);
	}
}
