@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTableCell {
	position: relative;
	@include fill_container;
	@include flex_row(center, center);
	// border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	overflow-x: auto;

	&:last-child {
		border-right: none;
	}

	&:hover {
		cursor: pointer;
	}
	// 1, 5, 9, 13, 17, 21
	&_value {
		text-align: center;
		font-size: 1.4rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "600");
		overflow-y: auto;
	}
	&_input {
		width: 100%;
		height: 100%;
		padding-left: 0.5rem;
		font-size: 1.4rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}

.LOCInput {
	width: 100%;
	height: 100%;
	padding-left: 0.5rem;
	font-size: 1.4rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "altSans");
	color: map-get($map: $blueGreyMap, $key: "600");
	text-align: center;
	border-radius: 0.5rem;
	border: none;
	overflow-x: scroll;
	scrollbar-width: none;

	// -webkit-appearance: none;
	// HIDE ARROWS ON WINDOWS DEVICES
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	&:focus-within {
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
		cursor: pointer;
	}

	// HIDE SCROLLBAR ON WINDOWS DEVICES

	/* Background Tracking */
	&::-webkit-scroll-track {
		background-color: #ffffff;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 0.7rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "500");
		border-radius: 5rem;
	}
}

// remove arrows on input
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="text"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="text"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.Notice {
	position: absolute;
	top: 3.2rem;
	left: 0;
	width: 100%;
	height: 4rem;
	background-color: map-get($map: $redMap, $key: "800");
	@include flex_row(center, flex-start);
	z-index: 1000;

	&_msg {
		width: 100%;
		height: 100%;
		@include flex_row(center, flex-start);
		background-color: transparent;
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "50");
	}
}
