@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserType {
	width: 70rem;
	max-width: 100%;
	height: auto;
	padding: 0.5rem 0.5rem;
	@include flex_row(flex-start, flex-start);
	border: 1px solid #ccc;
	border-radius: 1rem;
	margin: 1rem 0;
	background-color: #ffffff;
	&:hover {
		box-shadow: map-get($map: $shadows, $key: "medium");
		opacity: 1;
	}

	&_left {
		width: 5rem;
		height: auto;

		&_id {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background-color: map-get($map: $blueGreyMap, $key: "500");
			color: map-get($map: $blueGreyMap, $key: "100");
			font-size: 1.7rem;
			font-weight: 700;
			font-family: map-get($map: $fonts, $key: "altSans");
			@include flex_row(center, center);
		}
	}

	&_main {
		width: fit-content;
		height: 100%;

		&_details {
			@include flex_col(center, flex-start);
			margin-bottom: 1rem;

			&_name {
				font-size: 1.8rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "800");
			}
			&_desc {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
		&_status {
			width: auto;
		}
	}
	&_actions {
		width: auto;
		height: 100%;
		@include flex_col(space-between, flex-end);
		margin-left: auto;

		&_remove {
			width: auto;
			height: auto;

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $redMap, $key: "600");
				cursor: pointer;
			}
		}
		&_edit {
			width: auto;
			height: auto;
			margin-top: 3rem;

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $orangeMap, $key: "500");
				cursor: pointer;
			}
		}
	}
}

.InActiveBadge {
	width: 10rem;
	height: 3rem;
	padding: 0.5rem 1.7rem;
	border-radius: 5rem;
	background-color: map-get($map: $redMap, $key: "200");
	border: 2px solid map-get($map: $redMap, $key: "700");
	@include flex_row(center, center);
	outline: none;
	&:hover {
		cursor: pointer;
	}

	&_text {
		color: map-get($map: $redMap, $key: "700");
		font-size: 1.5rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}
.ActiveBadge {
	width: 10rem;
	height: 3rem;
	padding: 0.5rem 1.7rem;
	border-radius: 5rem;
	background-color: map-get($map: $greenMap, $key: "200");
	border: 2px solid map-get($map: $greenMap, $key: "700");
	@include flex_row(center, center);
	outline: none;
	&:hover {
		cursor: pointer;
	}

	&_text {
		color: map-get($map: $greenMap, $key: "700");
		font-size: 1.5rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}
