@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCControllerContainer {
	width: 100%;
	height: auto;
	border-radius: 1rem;
	border: 1px solid #ccc;
}
