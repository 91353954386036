@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangePassword {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;

		&_title {
			font-size: 1.7rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_desc {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_form {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}

	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
		margin-bottom: 4rem;
	}
}
