@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateNameInput {
	// min-width: 50rem;
	// max-width: 80rem;
	height: auto;
	margin-bottom: 4rem;
	@include flex_row(center, center);
}
