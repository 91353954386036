@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DropdownOptionsMenu {
	position: absolute;
	top: 112%;
	left: 0;
	width: 25rem;
	max-width: 100%;
	height: 20rem;
	border-radius: 0.5rem;
	padding: 2rem 0;
	overflow-y: auto;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	z-index: 20;

	/* Background tracking */
	&::-webkit-scroll-track {
		background-color: #ffffff;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 0.8rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "400");
		border-radius: 5rem;
	}

	&_list {
		@include fill_container;
		list-style: none;
	}
}
