@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ManageResidentsView {
	@include fill_container;

	&_warning {
		width: 100%;
		height: auto;
		@include flex_row(center, flex-start);
	}

	&_label {
		font-size: 1.6rem;
		color: map-get($map: $purpleMap, $key: "700");
		margin-bottom: 2rem;
	}

	&_options {
		@include fill_container;
		font-size: 1.6rem;
	}
}
