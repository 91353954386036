@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSecurityQuestionsSummary {
	width: 100%;
	height: auto;

	&_subtitle {
		width: 100%;
		padding-left: 0;
		margin-bottom: 2rem;
		margin-top: 2rem;

		&_text {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}

.QuestionPreview {
	width: 100%;
	height: auto;
	margin-bottom: 0.5rem;
	@include flex_row(flex-start, center);

	&_number {
		font-size: 2rem;
		font-weight: 700;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueMap, $key: "600");
		margin-right: 0.5rem;
	}
	&_text {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}
.AnswerPreview {
	width: 100%;
	height: auto;
	padding: 0.3rem 2.5rem;
	margin-bottom: 0.5rem;
	@include flex_row(flex-start, center);
}
.ResetMethod {
	width: auto;
	height: auto;
	margin-bottom: 1rem;

	&_status {
		@include flex_row(flex-start, center);
		@include fill_container;

		&_iconOff,
		&_iconOn {
			width: 1.8rem;
			height: 1.8rem;
			margin-right: 0.5rem;
		}
		&_iconOn {
			fill: map-get($map: $greenMap, $key: "500");
		}
		&_iconOff {
			fill: map-get($map: $redMap, $key: "600");
		}

		&_name {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
