@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AboutUser {
	width: 100%;
	height: auto;

	&_heading {
		font-size: 2.5rem;
		font-weight: 600;
		margin-bottom: 2rem;
		color: map-get($map: $blueGreyMap, $key: "600");
	}

	&_inner {
		@include fill_container;
		@include flex_col(flex-start, flex-start);

		&_item {
			@include flex_row(flex-start, center);
			margin-bottom: 1.5rem;

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $blueGreyMap, $key: "500");
				margin-right: 0.5rem;
			}
			&_field {
				@include flex_row(flex-start, center);
				font-size: 1.6rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "400");

				&_text {
					font-size: 1.6rem;
					font-weight: 600;
					color: map-get($map: $blueGreyMap, $key: "800");
				}
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.AboutUser {
		width: 100%;
		height: auto;

		&_inner {
			@include fill_container;
			padding: 0 1rem;
			@include flex_col(flex-start, flex-start);

			&_item {
				@include flex_row(flex-start, center);
				margin-bottom: 1.5rem;

				&_icon {
					width: 1.6rem;
					height: 1.6rem;
					fill: map-get($map: $blueGreyMap, $key: "500");
					margin-right: 0.5rem;
				}
				&_field {
					font-size: 1.3rem;
					font-weight: 500;
					color: map-get($map: $blueGreyMap, $key: "400");

					&_text {
						font-size: 1.3rem;
						font-weight: 600;
						color: map-get($map: $blueGreyMap, $key: "800");
					}
				}
			}
		}
	}
}
