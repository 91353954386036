@import "./_variables";

@mixin fill_container {
	width: 100%;
	height: 100%;
}

@mixin grow_container {
	width: 100%;
	height: auto;
}

// base flex - display: flex;
@mixin base_flex {
	display: -webkit-box;
	display: flex;
}

///////////////////////////////////
////// DATE PICKER MIXIN(S) //////
///////////////////////////////////

// "FOCUS MODE" STYLES FOR DATE PICKER COMPONENTS
@mixin focusMode {
	position: fixed;
	top: 50%;
	left: 50%;
	box-shadow: 2px 9px 217px 34px rgba(0, 0, 0, 0.52);
	z-index: 50;
}

///////////////////////////////////
////////// Grid MIXINS ////////////
///////////////////////////////////

@mixin grid_even($percent) {
	display: grid;
	grid-template-columns: repeat(auto-fit, $percent);
	grid-template-rows: repeat(auto-fit, $percent);
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

// 4x4 grid - centered
@mixin grid_4x4 {
	display: grid;
	grid-template-columns: repeat(auto-fit, 25%);
	grid-template-rows: repeat(auto-fit, 25%);
	-webkit-box-pack: center;
	justify-content: center;
	grid-gap: 2rem 2rem;
	-webkit-box-align: center;
	align-items: center;
}

// 3x3 grid - centered
@mixin grid_3x3 {
	display: grid;
	grid-template-columns: repeat(auto-fit, 33.33%);
	grid-template-rows: repeat(auto-fit, 33.33%);
	-webkit-box-pack: center;
	justify-content: center;
	grid-gap: 2rem 2rem;
	-webkit-box-align: center;
	align-items: center;
}

// 6x6 grid - centered
@mixin grid_6x6 {
	display: grid;
	grid-template-columns: repeat(auto-fit, 16.66%);
	grid-template-rows: repeat(auto-fit, 16.66%);
	-webkit-box-pack: center;
	justify-content: center;
	grid-gap: 2rem 2rem;
	-webkit-box-align: center;
	align-items: center;
}

// calendar in date picker component
@mixin grid_7x4 {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, calc(100% / 7));
	grid-template-rows: repeat(5, minmax(5rem, 7rem));
}

// <Calendar/>component
@mixin calendar {
	display: grid;
	grid-template-columns: repeat(auto-fit, calc(100% / 7));
	grid-template-rows: repeat(4, auto-fill);
}

// MIXIN VERSION:
// - Supports custom base color
// - Supports custom hover line color
@mixin hoverEffect($baseColor, $effectColor) {
	color: $baseColor;
	text-decoration: none;
	// background-image: linear-gradient(180deg, transparent 65%, $effectColor 0);
	background-image: linear-gradient(180deg, transparent 85%, $effectColor 0);
	background-size: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.4s ease;

	&:hover {
		background-size: 100% 100%;
	}
}
