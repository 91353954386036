@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ViewContainer {
	@include fill_container;
	padding: 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "headings");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
	}
	&_view {
		@include fill_container;
	}
}

@media only screen and (max-width: 780px) {
	.ViewContainer {
		padding: 1rem;
	}
}
@media only screen and (max-width: 620px) {
	.ViewContainer {
		padding: 1rem 0.5rem;
	}
}
