@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SubListItem {
	width: max-content;
	margin-bottom: 1.5rem;

	& a {
		text-decoration: none;
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "saturated");
	}
}
