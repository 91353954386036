@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AddAltEmail {
	margin: 1rem 0 2rem 0;

	&_addEmail {
		margin: 1rem 0 2rem 0;

		&_desc {
			width: 60rem;
			max-width: 70vw;
			@include flex_row(flex-start, center);
			margin-top: 2rem;
			padding: 0.3rem 0.5rem;
			border: 1px solid #ccc;
			border-radius: 1rem;

			&_icon {
				min-width: 3rem;
				min-height: 3rem;
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $yellowMap, $key: "400");
			}

			&_text {
				font-size: 1.3rem;
				font-weight: 500;
				margin: 2rem;
				color: map-get($map: $blueGreyMap, $key: "500");
				word-wrap: break-word;
				white-space: pre-wrap;
			}
		}

		&_btn {
			padding: 0.9rem 1.5rem;
			border-radius: 5rem;
			background-color: map-get($map: $blueMap, $key: "600");
			color: map-get($map: $blueMap, $key: "100");
			font-size: 1.4rem;
			font-weight: 600;
			border: none;
			outline: none;
			@include flex_row(center, center);

			&_icon {
				width: 2.2rem;
				height: 2.2rem;
				fill: map-get($map: $blueMap, $key: "100");
				margin-right: 0.3rem;
			}

			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
	&_altEmail {
		width: 100%;
		margin-top: 2rem;

		&_actions {
			width: 100%;
			margin-top: 4rem;
			@include flex_row(flex-end, center);
		}
	}
}

@media only screen and (max-width: 500px) {
	.AddAltEmail {
		&_desc {
			width: 80vw;
			max-width: 90vw;
		}
	}
}
