@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SubList {
	width: 100%;
	height: auto;
	@include flex_col(flex-start, flex-start);
	border: none;
	outline: none;
	padding: 0 2rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");

	&_content {
		@include fill_container;
		@include flex_row(flex-start, center);
		padding: 0 1rem;
		&:hover {
			cursor: pointer;
		}

		&:hover &_name {
			color: map-get($map: $colors, $key: "mainRed");
		}
		&:hover &_icon {
			fill: map-get($map: $colors, $key: "mainRed");
			cursor: pointer;
		}
		&:hover &_caret {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainRed");
		}
		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_name {
			text-decoration: none;
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "saturated");
			margin-right: 0.5rem;
		}
		&_caret {
			width: 1.5rem;
			height: 1.5rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			&:hover {
				cursor: pointer;
			}
		}
	}
	&_list {
		list-style: none;
		padding: 1rem 3rem;
	}
}

.CollapsedSubList {
	width: 100%;
	height: auto;
	@include flex_col(flex-start, flex-start);

	&_content {
		@include fill_container;
		@include flex_row(flex-start, center);
		padding: 0 0.5rem;
		&:hover {
			cursor: pointer;
		}

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
	}

	&_list {
		@include fill_container;
		list-style: none;
		// padding: 1rem 3rem;
	}
}
