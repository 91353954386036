@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PageNotFound {
	@include fill_container;
	max-height: 40vh;
	padding: 0 2rem;
	@include flex_col(center, center);

	&_logoContainer {
		width: 100%;
		@include flex_row(center, flex-start);
	}

	&_titles {
		width: 100%;
		@include flex_col(center, center);

		&_title,
		&_subtitle {
			font-size: 4rem;
			font-weight: 700;
			font-family: map-get($map: $fonts, $key: "mainSans");
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_subtitle {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_redirects {
		margin-top: 10rem;
		width: 100%;
		height: auto;
		@include flex_row(center, flex-start);

		&_redirectBtn {
			padding: 1.2rem 2.4rem;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			font-size: 1.4rem;
			font-weight: 500;
			margin-right: 2rem;
			background-color: map-get($map: $colors, $key: "mainBlackBlue");
			color: map-get($map: $blueGreys, $key: "light");

			&:hover {
				cursor: pointer;
				-webkit-box-shadow: map-get($map: $shadows, $key: "medSubtle");
				box-shadow: map-get($map: $shadows, $key: "medSubtle");
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.PageNotFound {
		@include fill_container;
		max-height: 60vh;

		&_logoContainer {
			width: 100%;
			max-width: 100vw;
			object-fit: contain;
		}
	}
}
