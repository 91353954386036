@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VerifyEmailPage {
	@include fill_container;

	&_logo {
		width: 100%;
		height: auto;
	}

	&_main {
		width: 100%;
		height: auto;
		height: 75rem;
		// min-height: 60rem;
	}
	&_actions {
		width: 100%;
		@include flex_row(center, center);
	}
}

.ActionButton {
	padding: 1.2rem 2rem;
	border-radius: 5rem;
	background-color: map-get($map: $blueMap, $key: "500");
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 1.8rem;
	font-weight: 600;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}
}

.IsVerifyingEmail {
	width: 100%;
	height: auto;
	@include flex_col(center, center);

	&_loader {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}
	&_info {
		width: 100%;
		@include flex_row(center, center);

		&_msg {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
}

@media only screen and (max-width: 550px) {
	.VerifyEmailPage {
		&_main {
			width: 100%;
			height: auto;
			min-height: 35rem;
		}
	}
}
