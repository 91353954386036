@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserCardList {
	width: 100%;
	height: 100%;

	&_about {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;
		@include flex_row(center, flex-start);
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}

	&_cards {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-rows: repeat(auto-fit, 15rem);
		grid-template-columns: repeat(auto-fit, 40rem);
		place-items: center;
		justify-content: center;
		grid-gap: 2rem 1rem;
	}
}

@media only screen and (max-width: 450px) {
	.UserCardList {
		@include fill_container;

		&_cards {
			grid-gap: 1rem 0.5rem;
			justify-content: center;
		}
	}
}
