@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessRow {
	width: 100%;
	height: 6rem;
	padding: 0.3rem 2.5rem;
	border-radius: 0.5rem;
	@include flex_row(space-between, center);
	border: 1px solid #eee;

	&_justUpdated {
		@extend .FacilityAccessRow;
		@include pulse;
		box-shadow: map-get($map: $shadows, $key: "heavySubtle");
	}

	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "light");
	}

	&_facility {
		width: 27%;
		@include flex_row(flex-start, center);
		&:hover .FacilityBadge {
			background-color: map-get($map: $colors, $key: "mainBlackBlue");
			&_icon {
				fill: map-get($map: $colors, $key: "mainBlue");
			}
		}

		&_badge {
			position: relative;
			width: max-content;
			height: 100%;
			@include flex_row(flex-start, center);
			margin-right: 0.5rem;
		}
		&_details {
			@include flex_col(flex-start, flex-start);

			&_name {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainBlackBlue");
				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
			}
			&_address {
				font-size: 1.2rem;
				color: map-get($map: $blueGreys, $key: "headings");
			}
		}
	}
	&_option {
		// width: 25%;
		width: 20%;
		height: 100%;
		@include flex_row(center, center);

		&_btn {
			border: none;
			outline: none;
			padding: 0.5rem 1.2rem;
			font-size: 1.4rem;
			border-radius: 0.5rem;
			background-color: transparent;

			&_enabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "redOutline");
				color: map-get($map: $colors, $key: "mainRed");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainRed");
					color: #ffffff;
				}
			}
			&_disabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "greenOutline");
				color: map-get($map: $colors, $key: "mainGreen");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainGreen");
					color: #ffffff;
				}
			}
		}
	}
	&_emarOption {
		// width: 25%;
		width: 20%;
		height: 100%;
		@include flex_row(flex-end, center);

		&_status {
			width: 100%;
			height: 100%;
			@include flex_row(center, center);
		}

		&_btn {
			border: none;
			outline: none;
			padding: 0.5rem 1.2rem;
			font-size: 1.4rem;
			border-radius: 0.5rem;
			background-color: transparent;

			&_enabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "redOutline");
				color: map-get($map: $colors, $key: "mainRed");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainRed");
					color: #ffffff;
				}
			}
			&_disabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "greenOutline");
				color: map-get($map: $colors, $key: "mainGreen");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainGreen");
					color: #ffffff;
				}
			}
		}
	}
	&_ePayOption {
		// width: 25%;
		width: 20%;
		height: 100%;
		@include flex_row(flex-end, center);

		&_status {
			width: 100%;
			height: 100%;
			@include flex_row(center, center);
		}

		&_btn {
			border: none;
			outline: none;
			padding: 0.5rem 1.2rem;
			font-size: 1.4rem;
			border-radius: 0.5rem;
			background-color: transparent;

			&_enabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "redOutline");
				color: map-get($map: $colors, $key: "mainRed");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainRed");
					color: #ffffff;
				}
			}
			&_disabled {
				@extend .FacilityAccessRow_option_btn;
				box-shadow: map-get($map: $hoverShadows, $key: "greenOutline");
				color: map-get($map: $colors, $key: "mainGreen");
				&:hover {
					cursor: pointer;
					background-color: map-get($map: $colors, $key: "mainGreen");
					color: #ffffff;
				}
			}
		}
	}
}

.FacilityBadge {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: #eaecef;
	@include flex_row(center, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: #111;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainBlue");
		}
	}

	&:hover &_icon {
		fill: map-get($map: $colors, $key: "mainBlue");
		cursor: pointer;
	}
}

.EnabledBadge {
	padding: 0.7rem 1.4rem;
	border-radius: 0.5rem;
	@include flex_row(center, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: map-get($map: $colors, $key: "mainGreen");
	}

	&_text {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainGreen");
	}
}

.DisabledBadge {
	padding: 0.7rem 1.4rem;
	border-radius: 0.5rem;
	@include flex_row(center, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: map-get($map: $colors, $key: "mainRed");
	}

	&_text {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainRed");
	}
}

.IndyBadge {
	position: absolute;
	top: -0.2rem;
	left: -0.2rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $pinkMap, $key: "600");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
	}
}
.ParentBadge {
	position: absolute;
	top: -0.2rem;
	left: -0.2rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $purpleMap, $key: "700");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
	}
}
.ChildBadge {
	position: absolute;
	top: -0.2rem;
	left: -0.2rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $tealMap, $key: "600");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
		@include flex_row(center, center);
		text-align: center;
	}
}

.ReactivateButton {
	border: none;
	outline: none;
	padding: 0.5rem 1.2rem;
	font-size: 1.4rem;
	border-radius: 0.5rem;
	background-color: transparent;
	box-shadow: map-get($map: $hoverShadows, $key: "greenOutline");
	color: map-get($map: $colors, $key: "mainGreen");
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $colors, $key: "mainGreen");
		color: #ffffff;
	}
	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}

.DeactivateButton {
	border: none;
	outline: none;
	padding: 0.5rem 1.2rem;
	font-size: 1.4rem;
	border-radius: 0.5rem;
	background-color: transparent;
	box-shadow: map-get($map: $hoverShadows, $key: "redOutline");
	color: map-get($map: $colors, $key: "mainRed");

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $colors, $key: "mainRed");
		color: #ffffff;
	}

	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}
