@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SidebarVersion {
	width: 100%;
	height: auto;
	@include flex_row(center, center);

	&_version {
		text-align: center;
		font-size: 1.2rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "100");
	}
}
