@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserCard {
	width: 40rem;
	height: 15rem;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	background-color: #ffffff;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $hoverShadows, $key: "bright");
	}

	&_user {
		width: auto;
		height: auto;
		max-height: 37rem;
		@include flex_row(flex-start, flex-start, wrap);

		&_badge {
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background-color: map-get($map: $purpleMap, $key: "700");
			@include flex_row(center, center);
			margin-right: 1rem;
			flex-grow: auto;

			&_initials {
				color: map-get($map: $purpleMap, $key: "100");
				font-size: 1.6rem;
				font-weight: 600;
			}
		}
		&_about {
			width: auto;

			&_name {
				font-size: 1.5rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
			&_login {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
			&_title {
				margin-top: 0.3rem;
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
		&_type {
			margin-left: auto;
		}
	}
	&_middle {
		width: 100%;
		height: 70%;
		max-height: 100%;
		padding: 0.5rem 0;
		@include flex_row(flex-start, flex-end);

		&_access {
			padding-top: 2rem;
			padding-bottom: 0.5rem;
			padding-left: 3rem;
			width: 100%;
			margin-top: 2%;
			@include flex_col(flex-end, flex-start);
		}
	}

	&_badges {
		width: auto;
		max-height: 100%;
		@include flex_row(flex-start, flex-end);
		margin-top: 0.5rem;
		padding-left: 3.2rem;
	}

	&_isSelected {
		@extend .UserCard;
		border: 2px solid map-get($map: $purpleMap, $key: "700");
		box-shadow: map-get($map: $hoverShadows, $key: "bright");
		cursor: pointer;
	}
}

.UserTypeBadge {
	width: max-content;
	height: max-content;
	padding: 0.3rem 0.8rem;
	border-radius: 5rem;
	@include flex_row(center, center);

	&_label {
		width: 100%;
		height: 100%;
		background-color: transparent;
		font-size: 1.3rem;
		font-weight: 600;
		border-radius: 5rem;
	}
}

@media only screen and (max-width: 450px) {
	.UserCard {
		width: 32rem;
		height: 13rem;
		padding: 0.5rem 0.7rem;

		&_user {
			@include flex_row(flex-start, flex-start, wrap);

			&_type {
				margin-top: 0.3rem;
				width: 100%;
				padding-left: 3.5rem;
				margin-right: auto;
			}
		}
	}
}
