@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RequestOTP {
	width: auto;
	padding: 0 2rem;

	&_sendRequest {
		width: auto;
		margin-bottom: 2rem;

		&_btn {
			padding: 0.5rem 1.4rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "600");
			color: map-get($map: $purpleMap, $key: "100");
			border: none;
			outline: none;
			font-size: 1.5rem;
			font-weight: 600;
		}
	}
	&_preview {
		width: auto;
	}
}
