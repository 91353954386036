@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.NewUserTab {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 2rem;

		&_title {
			color: map-get($map: $blueGreyMap, $key: "600");
			font-size: 2.5rem;
			font-weight: 700;
		}
		&_desc {
			margin-top: 0.5rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}

	&_main {
		width: 100%;
		max-width: 140rem;
		display: block;
		margin: 0 auto;
		@include flex_row(center, center);
		height: auto;
		background-color: #ffffff;
		border-radius: 0.5rem;
		margin-bottom: 4rem;
	}
}
