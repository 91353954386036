@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ForgotPassword {
	@include fill_container;
	max-height: 70vh;
	overflow-y: scroll;

	&_loader {
		width: 100%;
		@include flex_row(center, flex-start);
	}
}
