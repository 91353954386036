@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ALAStepCounter {
	width: 100%;
	height: 8rem;
	@include flex_row(center, flex-end);

	&_stepCircle {
		display: block;
		width: 1.4rem;
		height: 1.4rem;
		background-color: #eaecef;
		border-radius: 50%;
		margin: 0 0.5rem;
		&:hover {
			cursor: pointer;
			border: 1px solid #ccc;
		}
	}
}
