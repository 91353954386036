@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationPanel {
	width: 100%;
	height: 100%;
	min-height: 62rem;
	padding: 1rem 2rem;
	border-radius: 1rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "light");
	@include flex_row(flex-start, flex-start);

	&_left {
		width: 50%;
		height: auto;

		&_top {
			width: 100%;
			height: auto;
			&_title {
				font-size: 3rem;
				font-weight: 800;
				color: map-get($map: $purpleMap, $key: "700");
			}
			&_desc {
				margin-top: 1rem;
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 2rem;

				& > b {
					color: map-get($map: $purpleMap, $key: "700");
				}
			}
		}
		&_main {
			width: 100%;

			&_selector {
				width: 100%;
			}
			&_residents {
				width: 100%;
				margin-top: 3rem;

				&_label {
					font-size: 1.5rem;
					font-weight: 500;
					color: map-get($map: $blueGreyMap, $key: "400");
				}
			}
			&_aboutFacility {
				width: 100%;
				height: auto;
				margin-bottom: 2rem;
			}
			&_actions {
				width: 100%;
				height: 15rem;
				@include flex_row(flex-start, flex-end);
			}
		}
	}

	&_right {
		width: 50%;
		height: auto;

		&_icon {
			width: 100%;
			height: 40rem;
			@include flex_col(center, center);
		}
	}
	&_confirm {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

.MigrateIcon {
	width: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 14rem;
		height: 14rem;
		fill: map-get($map: $blueGreyMap, $key: "600");
		margin: 0 1rem;
		fill: map-get($map: $purpleMap, $key: "500");
	}
}

@media only screen and (max-width: 700px) {
	.MigrationPanel {
		width: 100%;
		height: 100%;
		min-height: 70rem;
		padding: 1rem 2rem;
		border-radius: 1rem;
		background-color: #ffffff;
		box-shadow: map-get($map: $shadows, $key: "light");
		@include flex_row(flex-start, flex-start, wrap);

		&_left {
			width: 100%;
			height: auto;

			&_top {
				width: 100%;
				height: auto;

				&_title {
					font-size: 2.2rem;
					font-weight: 800;
					color: map-get($map: $purpleMap, $key: "700");
				}
				&_desc {
					margin-top: 1rem;
					font-size: 1.4rem;
					font-weight: 500;
					color: map-get($map: $blueGreyMap, $key: "500");
					margin-bottom: 2rem;
				}
			}

			&_main {
				width: 100%;
				height: auto;

				&_selector {
					width: 100%;
				}
				&_residents {
					width: 100%;
				}
			}
		}
		&_right {
			width: 100%;
		}
	}

	// MIGRATION ICON(S)
	.MigrateIcon {
		width: 100%;
		@include flex_row(center, center);

		&_icon {
			width: 8rem;
			height: 8rem;
			fill: map-get($map: $blueGreyMap, $key: "600");
			margin: 0 1rem;
			fill: map-get($map: $purpleMap, $key: "500");
		}
	}
}
