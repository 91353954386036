@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Sidebar {
	width: 20rem;
	height: 100vh;
	max-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	transition: all 0.2s ease-in-out;
	z-index: 1;

	&_top {
		width: 100%;
		height: 10vh;
		padding: 1rem;
		margin-bottom: 4rem;
		@include flex_row(center, center);

		&_icon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			// fill: map-get($map: $purpleMap, $key: "300");
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
	}
	&_inner {
		@include fill_container;
		padding: 2rem 1rem;
		overflow-y: scroll;
	}

	&_user {
		width: 100%;
		position: absolute;
		bottom: 2rem;
		@include flex_row(center, center);
	}
}

.CollapsedSidebar {
	width: 7rem;
	height: 100vh;
	max-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	transition: all 0.2s ease-in-out;
	z-index: 1;

	&_top {
		width: 100%;
		height: 10vh;
		padding: 1rem;
		@include flex_row(flex-end, center);

		&_icon {
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
	}
	&_inner {
		@include fill_container;
		padding: 2rem 1rem;
		overflow-y: scroll;
	}
}
