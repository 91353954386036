@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAccessTableBody {
	width: 100%;
	height: 40rem;
	position: relative;
	overflow-y: scroll;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "subtleInset");

	/* Background tracking */
	&::-webkit-scroll-track {
		background-color: #eaecef;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 0.8rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "400");
		border-radius: 5rem;
	}
}
