@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCController {
	@include fill_container;
	padding: 4rem 0;
	@include flex_col(center, flex-start);

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
			@include flex_row(flex-start, center);
		}
		&_about {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_reset {
		width: 100%;
		height: auto;
		@include flex_row(center, center, wrap);
		margin-bottom: 6rem;

		&_buttons {
			width: auto;
			padding: 0 2rem;
			margin-left: 2rem;
			border-left: 1px solid map-get($map: $blueGreyMap, $key: "500");
			@include flex_row(center, center, wrap);
		}
	}
	&_selector {
		width: 60rem;
		display: block;
		margin: 0 auto;
		height: auto;
		@include flex_col(center, center);
		margin-bottom: 4rem;

		&_label {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
			// margin-left: 1rem;
		}

		&_container {
			width: 100%;
			height: auto;
			@include flex_row(center, center);
		}
	}

	&_notice {
		width: 100%;
		margin-bottom: 4rem;
		@include flex_row(center, center);
	}

	&_loader {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		margin-bottom: 2rem;
	}

	&_tables {
		width: 100%;
		height: auto;
		@include flex_row(space-evenly, center, wrap);
	}
	&_options {
		width: 100%;
		padding-left: 10rem;
		margin-bottom: 4rem;

		&_checkbox {
			margin-bottom: 1rem;
		}
	}

	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

.ReadOnlyBadge {
	width: auto;
	padding: 2rem 2rem;
	border-radius: 5rem;
	border: 1px solid map-get($map: $yellowMap, $key: "500");
	background-color: map-get($map: $yellowMap, $key: "500");
	@include flex_row(center, center);
	box-shadow: map-get($map: $shadows, $key: "pronounced");
	margin-bottom: 2rem;

	&_icon {
		width: 3.5rem;
		height: 3.5rem;
		fill: map-get($map: $yellowMap, $key: "500");
		fill: map-get($map: $yellowMap, $key: "50");
		margin-right: 1rem;
	}
	&_text {
		font-size: 2.8rem;
		font-weight: 600;
		color: map-get($map: $yellowMap, $key: "500");
		color: map-get($map: $yellowMap, $key: "50");
	}
}

@mixin blink {
	-webkit-animation: blink 0.9s 3 both;
	animation: blink 0.9s 3 both;
}

@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

// indicates what LOC is loaded (eg. 'Current LOC' or 'LOC Template')
.LOCNotice {
	width: 100%;
	@include flex_row(center, center);

	&_msg {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		-webkit-animation: blink 0.9s 6 both;
		animation: blink 0.9s 6 both;
	}
}
.NewMsg {
	font-size: 2rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
	-webkit-animation: blink 0.9s 3 both;
	animation: blink 0.9s 3 both;
}
.TemplateMsg {
	font-size: 2rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
	-webkit-animation: blink 0.9s 3 both;
	animation: blink 0.9s 3 both;
}

.FacilityIndicator {
	width: auto;
	align-self: center;
	font-size: 1.8rem;
	font-weight: 600;
	color: map-get($map: $purpleMap, $key: "600");
	margin-left: 1rem;
}
