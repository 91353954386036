@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ManageUserTypes {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 2rem;

		&_title {
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 2.5rem;
			font-weight: 700;
		}
		&_desc {
			margin-top: 0.5rem;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
}
