@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserProfile {
	@include fill_container;
	position: relative;
	display: block;
	margin: 0 auto;

	&_main {
		width: 100%;
		height: auto;

		&_avatar {
			width: 100%;
			@include flex_row(center, flex-start);
		}
		&_editForm {
			width: 100%;
			height: auto;
			padding: 0 10rem;
		}
	}
}

@media only screen and (max-width: 650px) {
	.UserProfile {
		@include fill_container;
		display: block;
		margin: 0 auto;

		&_main {
			width: 100%;

			&_editForm {
				padding: 0 0.5rem;
			}
		}
	}
}
