@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSearchResults {
	width: 100%;
	height: auto;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_title {
			font-size: 1.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
	}
	&_searchResults {
		width: 100%;
		height: 4rem;
		padding: 0 6rem;
		margin-bottom: 2rem;
	}

	&_list {
		list-style-type: circle;
	}
	&_noResults {
		width: 100%;
		height: auto;
		min-height: 15rem;
		@include flex_row(center, center);
	}
}

.LoadingSearchResults {
	margin-top: 2rem;
	@include flex_col(center, center);

	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

.UserEntry {
	width: 100%;
	padding: 1rem 1rem;
	@include flex_col(flex-start, flex-start);
	border: 1px solid map-get($map: $blueGreyMap, $key: "300");
	border-radius: 1rem;
	background-color: #ffffff;

	&_inner {
		width: 100%;
		@include flex_row(space-between, center, wrap);

		&_name {
			width: 25rem;
			position: relative;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			&_msg {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");
			}

			&_editBtn {
				display: none;
				position: absolute;
				top: -0.5rem;
				right: -1rem;
				padding: 0.5rem 1.2rem;
				border: none;
				outline: none;
				color: map-get($map: $purpleMap, $key: "600");
				font-size: 1.4rem;
				font-weight: 600;
				background-color: transparent;
				&:hover {
					cursor: pointer;
				}
			}
		}
		&_email {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_showMoreBtn {
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			border: none;
			outline: none;
			background-color: transparent;
			margin-left: 0.3rem;
			&:hover {
				cursor: pointer;
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
	&_showMore {
		width: 100%;
		min-height: 7rem;
		padding: 1rem 0.5rem;
		@include flex_row(space-between, flex-start);

		&_section {
			width: auto;
			margin-right: 2rem;

			&_desc {
				font-size: 1.4rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "400");
				text-decoration: underline;
				margin-bottom: 0.5rem;
			}

			&_details {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 0.3rem;
			}
		}
		&_options {
			width: auto;

			&_item {
				font-size: 1.3rem;
				font-weight: 500;
			}
		}
	}
	&_isDeletedIndicator {
		position: absolute;
		top: 0.2rem;
		left: 12rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: map-get($map: $redMap, $key: "400");
		z-index: 100;
		cursor: pointer;
	}
}

.UserEntry:hover .UserEntry_inner_name_editBtn {
	display: block;
}
