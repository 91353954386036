@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ControllableTabsButton {
	border: none;
	outline: none;
	border-radius: 0.5rem 0.5rem 0 0;
	padding: 2rem 2rem;
	font-size: 1.8rem;
	font-weight: 600;
	background-color: transparent;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin: 0 1rem;
	border: none;
	outline: none;
	@include flex_row(center, center);

	&:hover {
		cursor: pointer;
	}
	&:focus-within {
		outline: none;
	}

	&_isActive {
		@extend .ControllableTabsButton;
		border: none;
		outline: none;
		border-bottom: 3px solid map-get($map: $purpleMap, $key: "700");
		color: map-get($map: $purpleMap, $key: "700");
	}
	&:disabled {
		opacity: 0.4;
		color: map-get($map: $blueGreyMap, $key: "400");
		&:hover {
			cursor: not-allowed;
		}
	}
}
