@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.List {
	width: 100%;
	height: auto;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	overflow-y: auto;

	&_list {
		list-style: none;
		@include flex_col(flex-start, flex-start);
		padding: 0 2rem;
	}
}
