@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DeleteTemplate {
	@include fill_container;
	padding: 4rem 2rem;

	&_template {
		width: 100%;

		&_name {
			font-size: 2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");

			& > b {
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "800");
			}
		}
	}
	&_warning {
		margin: 4rem 0;
		@include flex_row(center, center);
	}
	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

.DeleteMsg {
	@include flex_col(center, center);
	margin-bottom: 6rem;

	&_name {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $redMap, $key: "600");
		margin-bottom: 4rem;
	}
	&_msg {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $yellowMap, $key: "500");
	}
}
