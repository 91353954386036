@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.JobTitleHandler {
	width: 100%;
	height: auto;

	&_search {
		width: 100%;

		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 0.5rem;
			background-color: #eaecef;
			font-size: 1.6rem;
			font-weight: 500;
		}
	}
	&_results {
		width: 100%;
		height: auto;
		max-height: 15rem;
		overflow-y: auto;
		/* Background Tracking */
		&::-webkit-scroll-track {
			background-color: #ffffff;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.7rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "500");
			border-radius: 5rem;
		}

		&_match {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			padding: 0.6rem 0.5rem;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.NoMatches {
	width: 100%;
	padding: 0 1rem;
	@include flex_col(flex-start, flex-start);

	&_btn {
		margin-top: 2rem;
		padding: 0.5rem 1.4rem;
		border-radius: 5rem;
		border: none;
		outline: none;
		font-size: 1.6rem;
		font-weight: 500;
		background-color: transparent;
		color: map-get($map: $purpleMap, $key: "600");
		&:hover {
			cursor: pointer;
			border: 2px solid map-get($map: $purpleMap, $key: "600");
		}
	}
}
