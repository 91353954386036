@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangePassword {
	@include fill_container;
	padding: 0 2rem;
	margin-bottom: 5rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}

	&_current {
		width: 100%;
	}
	&_new {
		width: 100%;
		border: none;

		&:disabled {
			opacity: 0.4;
		}
	}
	&_actions {
		width: 100%;
		margin-top: 5rem;
		@include flex_row(flex-end, center);
	}
	&_confirmed {
		width: 100%;
		height: 2rem;
	}

	&_matchingStatus {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_confirmation {
			width: auto;
		}
	}
}
