@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FeedbackOption {
	width: 8rem;
	height: 11rem;
	border-radius: 1rem;
	@include flex_col(center, center);
	background-color: #eaecef;
	border: none;
	outline: none;
	margin: 0 0.5rem;
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $blueGreyMap, $key: "200");
	}

	&_stepTitle {
		font-size: 1.4rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
}

.LightIcon,
.BugIcon {
	width: 5rem;
	height: 5rem;
	margin-bottom: 0.5rem;
	opacity: 0.8;
}

.DotsIcon {
	width: 3rem;
	height: 3rem;
	opacity: 0.8;
	margin-top: 0.5rem;
	margin-bottom: 2rem;
}
