@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LevelsOfCarePage {
	@include fill_container;

	&_main {
		@include fill_container;
	}
}
