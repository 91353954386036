@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AppMessage {
	@include fill_container;

	&_form {
		@include fill_container;

		&_item {
			margin-bottom: 2rem;
		}
		&_toggle {
			margin-bottom: 2rem;
			margin-top: 2rem;
		}
	}
}
