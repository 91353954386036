@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HiddenChar {
	width: max-content;
	height: auto;
	font-size: 1.6rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "700");
}
