@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UsernameValidator {
	width: 100%;
	height: auto;

	&_inputWrapper {
		display: block;
		width: 100%;

		&_label {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_input {
			width: 100%;
			height: 3.5rem;
			padding-left: 0.5rem;
			border-radius: 0.5rem;
			font-size: 1.6rem;
			border: none;
			outline: none;
			background-color: #eaecef;
			color: map-get($map: $blueGreys, $key: "headings");

			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "400");
				opacity: 0.6;
				font-size: 1.5rem;
			}
			&:hover,
			&:focus,
			&:focus-within {
				cursor: pointer;
				-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
}
// :hover && :focus styles
input[class*="UsernameValidator_UsernameValidator_inputWrapper_input"]:focus-within,
input[class^="UsernameValidator_UsernameValidator_inputWrapper_input"]:focus-within {
	cursor: pointer;
	-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
	box-shadow: map-get($map: $shadows, $key: "mainOutline");
}
input[class*="UsernameValidator_UsernameValidator_inputWrapper_input"]:focus,
input[class^="UsernameValidator_UsernameValidator_inputWrapper_input"]:focus {
	cursor: pointer;
	-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
	box-shadow: map-get($map: $shadows, $key: "mainOutline");
}
input[class*="UsernameValidator_UsernameValidator_inputWrapper_input"]:hover,
input[class^="UsernameValidator_UsernameValidator_inputWrapper_input"]:hover {
	cursor: pointer;
	-webkit-box-shadow: map-get($map: $shadows, $key: "mainOutline");
	box-shadow: map-get($map: $shadows, $key: "mainOutline");
}

.Errors {
	width: 100%;
	height: auto;
	margin-top: 1rem;
}

.ErrorMsg {
	width: max-content;
	height: auto;
	padding: 0.5rem 1rem;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $redMap, $key: "600");
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
		margin-left: 0.3rem;
	}
}

.ValidMsg {
	width: max-content;
	height: auto;
	padding: 0.5rem 1rem;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $greenMap, $key: "500");
		margin-left: 0.3rem;
	}
}
