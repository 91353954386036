@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.LoginForm {
	background-color: #fff;
	margin: 0 auto;
	max-width: 100%;
	width: 35rem;
	min-height: 30rem;
	max-height: 33rem;
	padding: 2rem 5rem;
	padding-bottom: 1.5rem;
	border-radius: 0.5rem;
	-webkit-box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.75);

	&_form {
		@include fill_container;
		@include flex_col(flex-start, flex-start);

		&_formTitles {
			width: 100%;
			margin-bottom: 2rem;
			&_title {
				font-size: 2.5rem;
				font-weight: 500;
				font-family: map-get($map: $fonts, $key: "mainSerif");
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 1rem;

				& > b {
					font-weight: 500;
					color: map-get($map: $redMap, $key: "600");
				}
			}
			&_subtitle {
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $redMap, $key: "600");
			}
		}

		& > label {
			width: 100%;
			font-size: 1.6rem;
			font-family: map-get($map: $fonts, $key: "altSans");
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "headings");
		}

		& > input[type="text"],
		& > input[type="password"] {
			width: 100%;
			height: 3.5rem;
			background-color: map-get($map: $colors, $key: "mainGrey");
			padding-left: 0.5rem;
			font-size: 1.6rem;
			font-weight: 400;
			font-family: map-get($map: $fonts, $key: "altSans");
			border-radius: 0.5rem;
			border: none;
			outline: none;
			margin-bottom: 2rem;

			&::-webkit-input-placeholder {
				font-weight: 100;
				font-size: 1.6rem;
			}

			&:-ms-input-placeholder {
				font-weight: 100;
				font-size: 1.6rem;
			}

			&::-ms-input-placeholder {
				font-weight: 100;
				font-size: 1.6rem;
			}

			&::placeholder {
				font-weight: 100;
				font-size: 1.6rem;
			}
		}

		&_btn {
			padding: 1.2rem 2.4rem;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			background-color: map-get($map: $flatColors, $key: "main");
			color: map-get($map: $colors, $key: "main");
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 1.5rem;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				cursor: pointer;
				-webkit-box-shadow: map-get($map: $shadows, $key: "light");
				box-shadow: map-get($map: $shadows, $key: "light");
			}
		}

		&_switch {
			font-size: 1.2rem;
			color: map-get($map: $colors, $key: "main");

			&_button {
				margin-top: 2rem;
				background-color: transparent;
				border: none;
				outline: none;
				color: map-get($map: $colors, $key: "main");

				&:hover {
					cursor: pointer;
					color: map-get($map: $colors, $key: "mainBlue");
				}
			}
		}
		&_bottom {
			width: 100%;
			height: 100%;
			margin-top: 2rem;

			&_switch {
				font-size: 1.2rem;
				color: map-get($map: $colors, $key: "main");

				&_button {
					margin-top: 2rem;
					background-color: transparent;
					border: none;
					outline: none;
					color: map-get($map: $colors, $key: "main");

					&:hover {
						cursor: pointer;
						color: map-get($map: $colors, $key: "mainBlue");
					}
				}
			}
			&_version {
				width: 100%;
				@include flex_col(flex-end, flex-end);
				font-size: 1rem;
				font-weight: 400;
				color: map-get($map: $blueGreys, $key: "headings");
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.LoginForm {
		height: auto;
		padding: 2rem 3rem;

		&_form {
			&_title {
				margin-bottom: 2rem;
			}
		}

		&_form_switch_button {
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}
	}
}
