@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ALASelectorController {
	@include fill_container;
	min-width: 100%;
	height: 8rem;
	padding: 1rem;
	margin-top: 1rem;
	@include flex_col(center, center);

	&_actionButtons {
		width: 100%;
		height: 4rem; // 100%
		margin-top: 2rem;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_prevBtn {
			padding: 0.5rem 1rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $colors, $key: "mainRed");
			color: #ffffff;
			border: none;
			outline: none;
			margin-right: auto;
			&:hover,
			&:focus-within,
			&:focus {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				opacity: 0.8;
			}
		}
		&_nextBtn {
			padding: 0.5rem 1rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $colors, $key: "main");
			color: #ffffff;
			border: none;
			outline: none;
			margin-left: auto;
			&:hover,
			&:focus-within,
			&:focus {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				opacity: 0.8;
			}
		}
	}
}
