@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCReset {
	@include fill_container;
	padding: 2rem;

	&_info {
		font-size: 3rem;
		font-weight: 800;
		color: map-get($map: $redMap, $key: "600");
	}
	&_details {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
	&_more {
		margin-top: 2rem;
		font-size: 1.7rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");

		&_list {
			list-style: circle;
			padding: 1rem 2rem;

			&_unit {
				font-size: 1.6rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "700");
				margin: 0.3rem 0;
			}
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		margin-top: 10rem;
		@include flex_row(flex-end, center);
	}
}
