@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ImgWithFallback {
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;

	& > img {
		object-fit: contain;
	}
}
