@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditUsername {
	@include fill_container;

	&_about {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_warning {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $yellowMap, $key: "500");
			margin-top: 1rem;
		}
	}

	&_form {
		margin: 1rem 0;
		@include fill_container;

		&_field {
			@include fill_container;
			margin-bottom: 2rem;
		}

		&_info {
			width: 100%;
			height: auto;
			margin-bottom: 1rem;

			&_warning {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $yellowMap, $key: "500");
			}
		}

		&_actions {
			width: 100%;
			@include flex_row(flex-start, center);
			margin-top: 2rem;
		}
	}
}
