@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessTable {
	@include fill_container;
	min-width: 60rem;
	min-height: 70rem;
	max-height: 70rem;
	padding: 1rem 2rem;
	border-radius: 1rem;
	background-color: #ffffff;

	&_main {
		width: 100%;
		height: 55rem;
		@include flex_col(center, center);
		max-height: 55rem;
		overflow-y: scroll;
		box-shadow: map-get($map: $shadows, $key: "subtleInset");
		z-index: 0;

		&_empty {
			@include fill_container;
			padding: 6rem 2rem;
			@include flex_col(center, center);
		}
	}
}
