@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserManagement {
	@include fill_container;
	padding-bottom: 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
		&_id {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
		}
	}
	&_main {
		@include fill_container;
		max-height: 100%;

		&_desc {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 2rem;
		}
		&_confirmDeleteDialog {
			width: 100%;
			height: 100%;
			@include flex_row(flex-end, center);
		}
		&_deleteIcon {
			width: 1.8rem;
			height: 1.8rem;
			fill: #ffffff;
			margin-right: 0.3rem;
		}
	}
}

@media only screen and (max-width: 700px) {
	.UserManagement {
		@include fill_container;

		&_header {
			margin-bottom: 2rem;

			&_title {
				font-size: 2.3rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");

				& > b {
					color: map-get($map: $blueMap, $key: "600");
				}
			}
			&_id {
				@include flex_row(flex-start, center);
				width: 100%;
				margin-top: 0.5rem;
				color: map-get($map: $blueGreyMap, $key: "500");
				font-size: 1.5rem;
				font-weight: 600;
			}
		}
		&_main {
			@include fill_container;
			padding: 0 0.3rem;
			max-height: 100%;

			&_desc {
				font-size: 1.1rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 2rem;
			}
		}
	}
}
