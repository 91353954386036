@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSecurityQuestion {
	width: 100%;
	height: max-content;
	margin-bottom: 0.5rem;

	&_fieldset {
		border: none;

		&:disabled {
			opacity: 0.6;
		}
	}
	&_edit {
		position: relative;
		width: auto;
		z-index: initial;
	}
}

.EditButton {
	position: absolute;
	top: 1.5rem;
	left: -6rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: map-get($map: $shadows, $key: "light");
	z-index: 10;
	@include flex_row(center, center);
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $orangeMap, $key: "400");
	}
	&:hover &_icon {
		fill: #ffffff;
	}

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $orangeMap, $key: "400");
	}
}

.CancelButton {
	position: absolute;
	top: 1.5rem;
	left: -6rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: map-get($map: $redMap, $key: "500");
	box-shadow: map-get($map: $shadows, $key: "light");
	@include flex_row(center, center);
	z-index: 10;
	&:hover {
		cursor: pointer;
	}
	&:hover &_icon {
		fill: #ffffff;
	}

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: #ffffff;
	}
}

@media only screen and (max-width: 500px) {
	.UserSecurityQuestion {
		width: 100%;

		&_fieldset {
			border: none;
			width: 100%;
			max-width: 100%;
		}
	}

	.CancelButton,
	.EditButton {
		position: absolute;
		top: 2.2rem;
		left: -2.7rem;
		width: 2.5rem;
		height: 2.5rem;
		z-index: 10;

		&_icon {
			width: 1.7rem;
			height: 1.7rem;
		}
	}
}
