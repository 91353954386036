@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessColumns {
	width: 100%;
	height: 5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");

	&_inner {
		@include fill_container;
		@include flex_row(space-around, center);
		// @include flex_row(space-between, center);
		padding: 1rem 2rem;

		&_item,
		&_residentCol,
		&_deactivate {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "saturated");
		}
		&_residentCol {
			width: 10%;
			height: 100%;
			margin-right: auto;
			@include flex_row(flex-start, center);
			text-align: left;
			&:hover {
				cursor: pointer;
			}
			&:hover &_name {
				color: map-get($map: $colors, $key: "mainRed");
			}
			&:hover &_icon {
				fill: map-get($map: $colors, $key: "mainRed");
			}

			&_name {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "saturated");
			}
			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $blueGreys, $key: "saturated");
				align-self: center;
				margin-left: 0.3rem;
			}
		}

		&_item {
			margin-left: auto;
			margin-right: auto;
			@include flex_row(flex-start, center);
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
			}
			&:hover &_icon {
				fill: map-get($map: $colors, $key: "mainRed");
				cursor: pointer;
			}

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $blueGreys, $key: "saturated");
				align-self: center;
				margin-left: 0.3rem;
			}
		}

		&_deactivate {
			width: max-content;
			color: map-get($map: $colors, $key: "mainRed");
			margin-left: auto;
			@include flex_row(flex-start, center);

			&_btn {
				text-align: left;
			}
		}
	}
}
