@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VerifyEmail {
	display: block;
	margin: 1rem auto;
	width: 40rem;
	height: 50rem;
	max-width: 70vw;
	max-height: 40vh;
	padding: 1rem 2rem;
	border-radius: 1rem;
	margin-top: 2rem;

	&_header {
		width: 100%;
		height: 15rem;
		text-align: center;
		@include flex_col(center, center);
		margin-bottom: 2rem;

		&_title {
			font-size: 2.7rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
			margin-bottom: 1rem;
		}
		&_welcome {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			text-align: center;
			margin-bottom: 1rem;
		}
		&_desc {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
			text-align: center;
		}
	}
	&_action {
		width: 100%;
		@include flex_row(center, center);
		margin-top: 1rem;

		&_btn {
			padding: 1rem 1.6rem;
			border-radius: 5rem;
			border: none;
			outline: none;
			font-size: 1.5rem;
			font-weight: 600;
			color: #ffffff;
			background-color: map-get($map: $redMap, $key: "600");
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "medium");
			}
		}
	}
}
