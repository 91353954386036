@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentSearchDetails {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		padding-bottom: 4rem;
		margin-bottom: 4rem;
		border-bottom: 1px solid #ccc;

		&_title {
			@include flex_row(flex-start, center, wrap);
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			& > b {
				margin-left: 0.5rem;
				color: map-get($map: $blueMap, $key: "600");
			}
		}
		&_id {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
		}
		&_unitType {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 1rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			&_label {
				margin-right: 0.5rem;
			}
		}

		&_dateCreated {
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			margin-bottom: 0.5rem;
		}
		&_archiveDate {
			color: map-get($map: $redMap, $key: "600");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			margin-bottom: 0.5rem;
		}
		&_status {
			@include flex_row(flex-start, center);
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			& > span {
				margin-right: 0.5rem;
			}
		}
	}
	&_main {
		width: 100%;
		height: auto;
		max-height: 80%;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "500");
		}

		&_data {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
		}
	}
	&_other {
		width: 100%;
		height: auto;
		// margin-bottom: 4rem;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_contacts {
			width: 90%;
			max-width: 90%;
			height: auto;
			@include flex_row(flex-start, center, wrap);

			&_entry {
				width: 25rem;
				height: auto;
				overflow-x: auto;
				margin: 0.5rem 1rem;
				padding: 1rem 0.5rem;
				border: 1px solid #eaecef;
				border-radius: 0.5rem;
				@include flex_col(flex-start, flex-start);
				&:hover {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
				}

				& > span {
					font-size: 1.3rem;
					font-weight: 400;
					margin: 0.3rem 0;

					& > b {
						font-weight: 500;
						color: map-get($map: $blueGreys, $key: "headings");
					}
				}
			}
		}
	}
}

.ArchiveBadge {
	width: 10rem;
	height: auto;
	padding: 0.4rem 1.2rem;
	border-radius: 5rem;
	@include flex_row(center, center);
	background-color: map-get($map: $redMap, $key: "200");
	border: 2px solid map-get($map: $redMap, $key: "700");
	margin-left: 1rem;

	&_text {
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "700");
		text-align: center;
	}
}

.SectionDesc {
	font-size: 1.6rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin-bottom: 3rem;
	margin-top: -0.8rem;
}
.ArchiveButton {
	padding: 1rem 1.8rem;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $redMap, $key: "600");
	color: map-get($map: $redMap, $key: "600");
	background-color: transparent;
	font-size: 1.6rem;
	font-weight: 600;
	&:hover {
		background-color: map-get($map: $redMap, $key: "600");
		color: #ffffff;
		cursor: pointer;
	}
}
.UnarchiveButton {
	padding: 1rem 1.8rem;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $greenMap, $key: "500");
	color: map-get($map: $greenMap, $key: "600");
	background-color: transparent;
	font-size: 1.6rem;
	font-weight: 600;
	&:hover {
		background-color: map-get($map: $greenMap, $key: "500");
		color: #ffffff;
		cursor: pointer;
	}
}
