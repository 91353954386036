@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditFacilityInfo {
	@include fill_container;

	&_header {
		width: auto;
		height: auto;
		@include flex_row(center, flex-start);

		&_badge {
			width: 15rem;
			height: 15rem;
			border-radius: 50%;
			background-color: map-get($map: $blueGreyMap, $key: "800");
			box-shadow: map-get($map: $shadows, $key: "medium");
			@include flex_row(center, center);

			&_icon {
				margin-top: 1.5rem;
				margin-right: 0.4rem;
				width: 13rem;
				height: 13rem;
				fill: #ffffff;
			}
		}
	}
	&_form {
		display: block;
		margin: 0 auto;
		width: 70%;
		min-width: 35rem;
		max-width: 80rem;
		height: auto;
		padding: 1rem 2rem;
		padding-bottom: 5rem;
		@include flex_col(center, center);

		&_section {
			@include fill_container;

			&_multiInput {
				width: 100%;
				height: auto;
				min-height: 8rem;
				@include flex_row(flex-start, center, wrap);

				&_city {
					width: 33.33%;
					height: 100%;
					@include flex_row(flex-start, flex-end);
					flex-shrink: 2;
				}
				&_state {
					width: 33.33%;
					height: 100%;
					@include flex_row(center, flex-end, wrap);
					flex-shrink: 2;
				}
				&_zip {
					width: 33.33%;
					height: 100%;
					@include flex_row(flex-end, flex-end, wrap);
					flex-shrink: 2;
				}
			}
		}
		&_actions {
			width: 100%;
			height: auto;
			@include flex_row(flex-end, center);
		}
	}
}

@media only screen and (max-width: 1100px) {
	.EditFacilityInfo {
		&_form {
			@include flex_col(center, center);

			&_section {
				@include fill_container;

				&_multiInput {
					@include flex_col(flex-start, flex-start, wrap);
					margin-bottom: 1rem;

					&_city {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-start);
					}
					&_state {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-end);
					}
					&_zip {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-end);
					}
				}
			}
		}
	}
}
