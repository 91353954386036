@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LockoutBanner {
	@include grow_container;
	@include flex_row(center, flex-start);
}

.NoSummary {
	font-size: 1.8rem;
	font-weight: 700;
	color: map-get($map: $purpleMap, $key: "700");

	&_container {
		width: max-content;
		height: auto;

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $redMap, $key: "600");
		}
	}
}
