@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangeQuestions {
	@include fill_container;
	margin-bottom: 10rem;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_notice {
			margin-top: 1rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");
		}

		&_hint {
			margin-top: 1rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			& > span {
				writing-mode: sideways-rl;
				// transform: rotate(180deg);
			}
		}
	}
	&_questions {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_instance {
			width: 100%;
			margin-bottom: 4rem;
		}
	}
	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

@media only screen and (max-width: 500px) {
	.ChangeQuestions {
		width: 100%;

		&_questions {
			width: 100%;

			&_instance {
				padding-left: 1.5rem;
			}
		}
	}
}
