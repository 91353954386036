@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AlertBanner {
	position: relative;
	width: 100%;
	height: 10rem;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "medium");

	&_inner {
		@include fill_container;
		padding: 0.5rem 1rem;
		@include flex_row(flex-start, flex-start);

		&_iconWrapper {
			width: 8rem;
			height: 100%;
			padding: 0.5rem 0.2rem;
			@include flex_row(flex-end, center);
			margin-right: 3rem;

			&_icon {
				width: 5rem;
				height: 5rem;
			}
		}

		&_headings {
			width: max-content;
			height: 100%;
			padding: 1rem 0.5rem;
			margin-right: 2rem;
			@include flex_col(center, flex-start);
			font-family: map-get($map: $fonts, $key: "mainSans");

			&_heading {
				font-size: 2.2rem;
				font-weight: 700;
			}
			&_subheading {
				font-size: 1.7rem;
				font-weight: 600;
			}
			&_msg {
				font-size: 1.3rem;
				font-weight: 500;
			}
		}
	}
	&_close {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		width: 2rem;
		height: 2rem;

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: #ffffff;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.AlertBanner {
		width: 100%;
		height: 7rem;

		&_inner {
			width: 100%;

			&_iconWrapper {
				width: 6rem;
				height: 100%;

				&_icon {
					width: 3.5rem;
					height: 3.5rem;
				}
			}
			&_headings {
				width: 100%;
				height: 100%;
				padding: 0.7rem 0.3rem;
				@include flex_col(center, flex-start);
				font-family: map-get($map: $fonts, $key: "mainSans");

				&_heading {
					font-size: 2rem;
					font-weight: 700;
				}
				&_subheading {
					font-size: 1.5rem;
					font-weight: 600;
				}
				&_msg {
					font-size: 1.1rem;
					font-weight: 500;
				}
			}
		}
	}
}
