@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetPasswordByAdmin {
	width: 100%;
	height: auto;
	padding: 0 2rem;
	margin-top: 4rem;

	&_details {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "500");
	}
	&_more {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
