@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetViaOTP {
	width: 100%;
	height: auto;
	margin-top: 2rem;

	&_main {
		width: 100%;
		@include flex_row(flex-start, flex-start);

		&_btn {
			padding: 1rem 1.6rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "700");
			color: #ffffff;
			border: none;
			outline: none;
			margin-top: 1.5rem;
			margin-right: 1rem;
		}
	}
}
.Success {
	width: 20rem;
	padding: 1rem 1.8rem;
	border-radius: 5rem;
	background-color: map-get($map: $greenMap, $key: "500");
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 600;
	@include flex_row(center, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: #ffffff;
		margin-right: 0.5rem;
	}
}
