@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UnitTypeBadge {
	padding: 0.3rem 1.4rem;
	border-radius: 5rem;
	@include flex_row(center, center);

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
	}
}
