@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SidebarCustom {
	width: 100%;
	height: auto;
	min-height: fit-content;
	@include flex_col(center, center);
	margin-top: 0.5rem;

	&_inner {
		@include fill_container;
		@include flex_col(center, center);
	}
}
