@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AvailableApps {
	width: 100%;
	height: auto;
	min-height: 30rem;
	max-height: 100%;
	@include flex_row(space-evenly, center, wrap);
}
