@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSecurity {
	width: 60vw;
	height: 100%;
	display: block;
	margin: 0 auto;

	&_actionCards {
		width: 100%;
		height: auto;
		@include flex_row(space-evenly, center, wrap);

		&_icon {
			width: 5rem;
			height: 5rem;
			fill: map-get($map: $purpleMap, $key: "600");
			margin-bottom: 1rem;
		}
		&_text {
			text-align: center;
			border-radius: 1rem;
			border: none;
			outline: none;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
	&_options {
		width: 100%;
		height: auto;
		margin-top: 6rem;
	}
}

.UserSecurityCard {
	width: 20rem;
	height: 15rem;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "light");
	@include flex_col(center, center);
	background-color: #ffffff;
	margin-bottom: 2rem;

	&_icon {
		width: 5rem;
		height: 5rem;
		fill: map-get($map: $purpleMap, $key: "600");
		margin-bottom: 1rem;
	}
	&_text {
		text-align: center;
		border-radius: 1rem;
		border: none;
		outline: none;
		font-size: 1.5rem;
		font-weight: 600;
	}

	&:hover {
		background-color: map-get($map: $purpleMap, $key: "600");
		box-shadow: map-get($map: $hoverShadows, $key: "bright");
		cursor: pointer;
	}
	&:hover &_icon {
		cursor: pointer;
	}
	&:hover &_text {
		cursor: pointer;
	}
}

@media only screen and (max-width: 600px) {
	.UserSecurity {
		width: 90vw;
		height: 100%;
		display: block;
		margin: 0 auto;
	}
}
