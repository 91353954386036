@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserTypes {
	width: 100%;
	height: auto;

	&_desc {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}
}

.TypeDescription {
	margin-top: 2rem;

	&_text {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");

		&_type {
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "700");
		}
	}
}
