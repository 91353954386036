@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Placeholder {
	width: max-content;
	max-width: 100%;
	height: max-content;
	text-align: center;
	overflow-wrap: break-word;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;

	&_msg {
		width: 100%;
		font-family: map-get($map: $fonts, $key: "mainSans");
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
	}
}
