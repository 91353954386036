@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.InvalidNotice {
	width: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $redMap, $key: "600");
		margin-right: 0.3rem;
	}
	&_msg {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
	}
}
