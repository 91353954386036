@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentsTableColumns {
	position: relative;
	width: 100%;
	height: 5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	z-index: 10;
	@include flex_row(space-around, center);

	&_column1,
	&_column2,
	&_column3,
	&_column4 {
		@include fill_container;
		padding: 1rem 1rem;
	}
	&_column1 {
		// @include flex_row(flex-start, center);
		margin-right: auto;
	}

	&_column4 {
		@include flex_row(center, center);
		// padding-left: 4rem;
	}
}

.ArchiveColumn {
	padding: 0.5rem 0.8rem;
	font-size: 1.7rem;
	color: map-get($map: $redMap, $key: "500");
	@include flex_row(center, center);

	&_label {
		@include fill_container;
		@include flex_row(center, center);
	}
}
