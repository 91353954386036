@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UsersSummarySearch {
	width: 100%;
	@include flex_row(center, center);

	&_search {
		position: relative;
		width: 40%;
		height: auto;
		@include flex_col(center, center);
		margin-bottom: 4rem;

		&_toggle {
			margin-top: 0.5rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "700");
			margin-bottom: 0.5rem;
			&:hover {
				cursor: pointer;
				color: map-get($map: $purpleMap, $key: "500");
			}
		}

		&_input {
			width: 40%;
			min-width: 25rem;
			height: 3.5rem;
			border-radius: 5rem;
			background-color: #ffffff;
			padding-left: 1rem;
			font-size: 1.6rem;
			font-weight: 500;
			border: none;
			outline: none;
			margin-bottom: 1rem;
			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "300");
			}
		}
		&_options {
			width: auto;
		}
	}
}
