@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PermissionsPanelPage {
	@include fill_container;

	&_selector {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;
		@include flex_col(center, center);

		&_label {
			font-size: 1.5rem;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_panels {
		@include fill_container;
		@include flex_row(space-evenly, center, wrap);
		gap: 2rem 1rem;
	}
}
