@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LevelsOfCareView {
	@include fill_container;

	&_info {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_notice {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
			margin-bottom: 1rem;
		}
	}

	&_msg {
		width: 100%;
		margin-top: 6rem;
		@include flex_row(center, center);
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $purpleMap, $key: "700");
	}

	&_selector {
		width: 100%;
		@include flex_row(center, center);
		margin-bottom: 2rem;
	}
	&_rules {
		width: 100%;
	}

	&_controller {
		width: 100%;
		height: auto;

		&_loader {
			width: 100%;
			@include flex_row(center, center);
		}
	}

	&_tabs {
		@include fill_container;
	}
}

.FacilityIndicator {
	width: auto;

	&_name {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}
