@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditUserProfile {
	@include fill_container;

	&_row {
		position: relative;
		width: 100%;
		max-width: 100vw;
		margin: 2rem 0;
		margin-top: 4rem;
		@include flex_row(center, center);

		&_phone {
			width: 100%;
		}
		&_phoneExt {
			width: 100%;
		}

		&_tile {
			width: 50%;

			&:first-child {
				margin-right: 0.5rem;
			}
			&:last-child {
				margin-left: 0.5rem;
			}
		}
		&_icon {
			width: auto;
			height: auto;
			position: absolute;
			top: 0.16rem;
			left: 7.5rem;
		}
	}
	&_section {
		width: 100%;
		height: auto;
		margin: 2rem 0;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "400");
			margin-bottom: 1rem;
		}
		&_main {
			width: 100%;
			height: auto;
			min-height: 10rem;
		}
	}
}

@media only screen and (max-width: 950px) {
	.EditUserProfile {
		&_row {
			width: 100%;
			@include flex_row(center, center, wrap);
			margin: 1rem 0;
			margin-bottom: 2rem;

			&_phone {
				width: 100%;
				margin-bottom: 2rem;
			}
			&_phoneExt {
				width: 100%;
				margin-bottom: 1rem;
			}

			&_tile {
				width: 100%;
				margin-bottom: 1rem;

				&:first-child {
					margin-right: 0;
				}
				&:last-child {
					margin-left: 0;
				}
			}
		}
	}
}
