@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ModulePermissionsPanel {
	width: 50rem;
	height: 50rem;
	padding: 1rem 2rem;
	background-color: #ffffff;
	border-radius: 1rem;

	&_header {
		width: 100%;
		height: 5rem;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_main {
		width: 100%;
		height: 83%;
		max-height: 83%;
		padding-top: 2rem;
		overflow-y: auto;
	}
	&_footer {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);

		&_selectAll {
			padding: 0.6rem 1.4rem;
			font-size: 1.6rem;
			font-weight: 500;
			color: #ffffff;
			background-color: map-get($map: $blueMap, $key: "600");
			border-radius: 5rem;
			border: none;
			outline: none;
			cursor: pointer;
		}
		&_deselectAll {
			padding: 0.6rem 1.4rem;
			font-size: 1.6rem;
			font-weight: 500;
			color: #ffffff;
			background-color: map-get($map: $redMap, $key: "600");
			border-radius: 5rem;
			border: none;
			outline: none;
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 820px) {
	.ModulePermissionsPanel {
		width: 100%;
	}
}
