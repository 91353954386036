@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LockoutEntry {
	width: 100%;
	height: 12rem;
	border-radius: 0.5rem;
	padding: 1rem 2rem;
	background-color: #ffffff;
	margin: 2rem 0;
	@include flex_row(flex-start, flex-start);
	&:hover {
		box-shadow: map-get($map: $shadows, $key: "light");
	}

	&_username {
		width: max-content;
		height: 100%;
		padding: 1rem 0;
		@include flex_row(flex-start, flex-start);

		&_details {
			width: max-content;
			@include flex_col(flex-start, flex-start);
			margin-left: 3rem;

			&_name {
				font-size: 1.8rem;
				font-weight: 700;
				color: map-get($map: $blueGreyMap, $key: "800");
			}
			&_login {
				font-size: 1.6rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
		&_status {
			margin-top: 1rem;
		}
	}
	&_info {
		margin-top: 1rem;
		width: 100%;
		@include flex_col(flex-end, flex-end);

		&_mins {
			font-size: 1.4rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
			& > b {
				color: map-get($map: $redMap, $key: "600");
			}
		}

		&_timestamp {
			font-size: 1.3rem;
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_status {
		width: auto;
		height: 100%;
		@include flex_col(center, center);
	}
}

.UserLockoutBadge {
	position: relative;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	@include flex_row(center, center);
	background-color: map-get($map: $purpleMap, $key: "600");

	&_initials {
		font-size: 1.7rem;
		font-weight: 600;
		color: #ffffff;
	}
	&_iconWrapper {
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		filter: drop-shadow(map-get($map: $shadows, $key: "heavy"));

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "500");
		}
	}
}

.LockoutBadge {
	width: max-content;
	padding: 0.7rem 1.3rem;
	border-radius: 5rem;
	background-color: map-get($map: $redMap, $key: "200");
	color: map-get($map: $redMap, $key: "600");
	font-size: 1.4rem;
	font-weight: 600;
}

.UnlockButton {
	width: max-content;
	padding: 0.7rem 1.3rem;
	border-radius: 5rem;
	background-color: map-get($map: $greenMap, $key: "500");
	font-size: 1.4rem;
	font-weight: 600;
	border: none;
	outline: none;
	margin: 1rem 0;
	color: map-get($map: $greenMap, $key: "50");

	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavy");
	}
}

// MEDIA QUERIES
@media only screen and (max-width: 700px) {
	.LockoutEntry {
		height: 10rem;
		padding: 0.6rem 0.5rem;
		margin: 1rem 0;

		&_username {
			width: max-content;
			height: 100%;
			padding: 0.5rem 0;
			@include flex_row(flex-start, flex-start);

			&_details {
				display: block;
				margin-left: 1.8rem;

				&_name {
					font-size: 1.5rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "800");
				}
				&_login {
					font-size: 1.3rem;
					font-weight: 500;
					color: map-get($map: $blueGreyMap, $key: "500");
				}
			}
			&_status {
				margin-top: 0.5rem;
			}
		}
		&_info {
			margin-left: 0.5rem;
			margin-top: 0.5rem;
			width: 100%;
			@include flex_col(flex-end, flex-end);

			&_mins {
				font-size: 1.2rem;
				font-weight: 600;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "500");
				& > b {
					color: map-get($map: $redMap, $key: "600");
				}
			}

			&_timestamp {
				font-size: 1rem;
				font-weight: 500;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}

	// LOCKOUT BADGE - MOBILE 700PX
	.LockoutBadge {
		width: max-content;
		padding: 0.4rem 0.5rem;
		border-radius: 5rem;
		background-color: map-get($map: $redMap, $key: "200");
		color: map-get($map: $redMap, $key: "600");
		font-size: 1.3rem;
		font-weight: 600;
	}

	// UNLOCK BUTTON - MOBILE 700PX
	.UnlockButton {
		width: max-content;
		padding: 0.5rem 1.1rem;
		border-radius: 5rem;
		background-color: map-get($map: $purpleMap, $key: "600");
		font-size: 1.3rem;
		font-weight: 600;
		color: #ffffff;
		border: none;
		outline: none;
		margin: 0.5rem 0;

		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "heavy");
		}
	}
}
