@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DisabledBadge {
	padding: 0.7rem 1.4rem;
	border-radius: 0.5rem;
	@include flex_row(center, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: map-get($map: $colors, $key: "mainRed");
	}

	&_text {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainRed");
	}
}
