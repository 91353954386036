@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessOption {
	width: 100%;
	height: auto;
	padding: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1.4rem;
	font-weight: 400;
	@include flex_row(space-between, center);
	margin-bottom: 1.2rem;

	&:hover {
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_isSelected {
		@extend .FacilityAccessOption;
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
	}

	&_icon {
		width: 1.4rem;
		height: 1.4rem;
		fill: map-get($map: $colors, $key: "main");
	}
}
