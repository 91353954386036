@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CustomHints {
	@include fill_container;

	& mark {
		&:nth-child(3),
		&:nth-child(1) {
			padding: 0 0.3rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $yellowMap, $key: "300");
			color: map-get($map: $blueMap, $key: "700");
		}
		&:nth-child(2) {
			padding: 0 0.3rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $greenMap, $key: "300");
			color: map-get($map: $greenMap, $key: "700");
		}
	}
}

.AppMessage {
	width: 100%;
	height: auto;
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin: 2rem 0;
}
.LoginPageMessages {
	width: 100%;
	margin-top: 2rem;
	@include flex_col(center, center);
}
