@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PasswordResetSummary {
	width: 100%;
	height: auto;
	padding-left: 0.5rem;
	margin-bottom: 2rem;

	&_subtitle {
		width: 100%;
		padding-left: 0;
		margin-bottom: 2rem;
		margin-top: 2rem;

		&_text {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_main {
		width: 100%;

		&_methods {
			width: 100%;

			&_type {
				width: 100%;
				@include flex_row(flex-start, center);
			}
		}
	}
}

.ResetMethod {
	width: 100%;
	height: auto;
	padding: 0 1rem;
	@include flex_row(flex-start, center);
	margin-bottom: 1rem;

	&_status {
		width: auto;
		@include flex_row(flex-start, center);
		margin-left: auto;

		&_iconOff,
		&_iconOn {
			width: 2.2rem;
			height: 2.2rem;
		}
		&_iconOn {
			fill: map-get($map: $greenMap, $key: "500");
		}
		&_iconOff {
			fill: map-get($map: $redMap, $key: "600");
		}
		&_label {
			font-size: 1.7rem;
			font-weight: 600;
			margin-left: 0.5rem;
		}
	}
	&_name {
		width: auto;
		min-width: fit-content;
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
		// margin-right: auto;
	}
}
