@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAppSummary {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
	&_main {
		@include fill_container;
		display: block;
		padding: 0 1rem;

		&_item {
			width: 100%;
			@include flex_row(space-between, center);
			margin-bottom: 1rem;

			&_label {
				font-size: 1.7rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
			&_access {
				width: auto;
				@include flex_row(flex-end, center);
			}
		}
	}
}

.Enabled {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
	&_label {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $greenMap, $key: "500");
		margin-left: 0.5rem;
	}
}
.Disabled {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $redMap, $key: "600");
	}
	&_label {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		margin-left: 0.5rem;
	}
}
