@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DownloadFile {
	width: auto;
	@include flex_col(center, center);

	&_link {
		@include flex_col(center, center);

		&_icon {
			width: 5rem;
			height: 5rem;
			fill: map-get($map: $blueMap, $key: "600");
		}
		&_text {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueMap, $key: "600");
		}
	}
}

.Flash {
	@include flex_row(flex-start, center);
	@include customFlash;

	&_text {
		// @include customFlash;
		font-size: 2rem;
		color: map-get($map: $redMap, $key: "600");
		font-family: map-get($map: $fonts, $key: "altSans");
		text-decoration: map-get($map: $redMap, $key: "600") underline;
	}
	&_badge {
		@include customFlash;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		background-color: map-get($map: $redMap, $key: "600");
		margin-left: 0.5rem;
	}
}
