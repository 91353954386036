@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LockoutStatus {
	width: max-content;

	&_status {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");

		& > b {
			color: map-get($map: $redMap, $key: "500");
			font-weight: 700;
		}
	}
}
.NoLockoutStatus {
	width: max-content;

	&_status {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");

		& > b {
			color: map-get($map: $purpleMap, $key: "600");
			font-weight: 700;
		}
	}
}
