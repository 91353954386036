@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetPasswordPage {
	@include fill_container;
	padding: 4rem 0;

	&_logo {
		width: 100%;
		@include flex_row(center, center);

		&_logoContainer {
			width: 25rem;
			height: 7rem;
			margin-bottom: 4rem;

			&_logo {
				@include fill_container;
				object-fit: contain;
			}
		}
	}
	&_header {
		width: 100%;
		height: auto;
		padding: 0 2rem;
		@include flex_col(center, center);
		margin-bottom: 2rem;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_ui {
		width: 100%;
		height: auto;
	}

	&_footer {
		width: 100%;
		@include flex_row(center, center);
	}
}
.ActionButton {
	padding: 1.2rem 2rem;
	border-radius: 5rem;
	background-color: map-get($map: $redMap, $key: "600");
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 1.8rem;
	font-weight: 600;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}
}
