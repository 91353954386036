@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangeUserType {
	@include fill_container;

	&_about {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_form {
		margin: 1rem 0;
		width: 30rem;
		height: auto;

		&_field {
			width: 100%;
			height: auto;
			margin-top: 2rem;
			margin-bottom: 4rem;

			&_label {
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
		&_msg {
			width: 100%;
			height: auto;
		}

		&_actions {
			width: 100%;
			@include flex_row(flex-end, center);
			margin-top: 2rem;
		}
	}
}

.Success {
	padding: 1rem 1.5rem;
	border-radius: 5rem;
	background-color: map-get($map: $purpleMap, $key: "700");
	@include flex_row(center, center);
	margin: 2rem 0;
}
