@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CustomFacilitySearch {
	@include fill_container;
	min-height: auto;

	&_header {
		width: 100%;
		margin-bottom: 2rem;

		&_title {
			color: map-get($map: $blueGreyMap, $key: "600");
			font-size: 2.5rem;
			font-weight: 700;
		}
		&_desc {
			margin-top: 0.5rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
	&_main {
		width: 100%;
		height: auto;

		&_actions {
			margin-top: 4rem;
			height: auto;
			@include flex_row(flex-end, center);

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: #ffffff;
			}
		}
	}
	&_results {
		width: 100%;
		padding: 2rem 3rem;
	}
}

.CustomSearch {
	margin-top: 2rem;
	margin: 4rem;
}
