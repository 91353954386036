@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MakeUserAdmin {
	@include fill_container;

	&_about {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_form {
		margin: 1rem 0;
		width: 30rem;
		height: auto;

		&_field {
			width: 100%;
			height: auto;
			margin-bottom: 4rem;

			&_label {
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
		&_actions {
			width: 100%;
			@include flex_row(flex-end, center);
			margin-top: 2rem;
		}
	}
}
