@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep5 {
	@include fill_container;
	width: 100%;
	@include flex_col(center, flex-start);

	&_access {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin-bottom: 0.5rem;

			& > b {
				font-weight: 500;
				color: map-get($map: $redMap, $key: "600");
			}
		}
		&_switch {
			position: relative;
			width: 100%;
			@include flex_row(flex-start, flex-end);

			&_state {
				margin-left: auto;
			}
		}
	}
	&_permissions {
		width: 100%;
		height: 10rem;
	}
}

.Enabled {
	width: auto;
	@include flex_row(flex-end, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 0.3rem;
		fill: map-get($map: $purpleMap, $key: "600");
	}
	&_label {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}
}
.Disabled {
	width: auto;
	@include flex_row(flex-end, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 0.3rem;
		fill: map-get($map: $redMap, $key: "600");
	}
	&_label {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
}
