@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AppAccessSummary {
	@include fill_container;

	&_main {
		@include fill_container;
		display: block;
		padding: 0 1rem;

		&_item {
			width: 100%;
			@include flex_row(space-between, center);
			margin-bottom: 1rem;

			&_label {
				font-size: 1.7rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
			&_access {
				width: auto;
				@include flex_row(flex-end, center);
			}
		}
	}
}
