@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EmailVerifiedError {
	@include fill_container;
	padding: 0 2rem;
	@include flex_col(center, center);

	&_ui {
		width: auto;
		height: auto;
	}
	&_details {
		@include fill_container;
		@include flex_col(center, center);

		&_title {
			font-size: 4rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
			text-align: center;
		}
		&_desc {
			font-size: 2.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			text-align: center;
		}
	}
}
