@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentsTable {
	@include fill_container;
	min-width: 65rem;
	max-height: 75rem;
	padding: 1rem 2rem;
	border-radius: 1rem;
	background-color: #ffffff;

	&_body {
		position: relative;
		@include fill_container;
		height: 55rem;
		z-index: 1;

		&_empty {
			margin-top: 15rem;
			@include flex_col(center, center);
		}
	}

	&_loading {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
		min-height: 35rem;

		&_msg {
			text-align: center;
			margin-top: 1.5rem;
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
}
