@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AssessmentNotificationList {
	@include fill_container;

	&_addRecipient {
		width: 100%;
		height: auto;
	}
}
