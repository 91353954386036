@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditAvatar {
	width: max-content;
	height: max-content;

	&_inner {
		width: max-content;
		height: max-content;
		position: relative;
		z-index: -1;

		&_source {
			width: max-content;
			height: max-content;
		}
		&_edit {
			position: absolute;
			top: 65%;
			right: -0.8rem;
			width: 4rem;
			height: 4rem;
			border: none;
			outline: none;
			border-radius: 50%;
			background-color: map-get($map: $purpleMap, $key: "200");
			@include flex_row(center, center);
			box-shadow: map-get($map: $shadows, $key: "heavySubtle");
			z-index: 1;
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $purpleMap, $key: "800");
			}
			&:hover &_icon {
				fill: map-get($map: $purpleMap, $key: "200");
				cursor: pointer;
			}
			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $purpleMap, $key: "700");
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
