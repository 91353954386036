@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SettingsPage {
	@include fill_container;

	&_maintenance,
	&_tabs {
		@include fill_container;
	}
	&_tabs {
		@include flex_col(center, center);
	}
}
.CustomButton {
	position: relative;
	@include fill_container;
	@include flex_row(center, center);
	z-index: -1;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		margin-right: 0.5rem;
	}
	&_name {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
	&_isActive {
		@extend .CustomButton;

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $purpleMap, $key: "500");
			margin-right: 0.5rem;
		}
		&_name {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "500");
		}
	}
	&_isDisabled {
		@extend .CustomButton;
		opacity: 0.5;
		cursor: not-allowed;
	}
}
