@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationPanelActions {
	width: 100%;
	height: auto;
	min-height: 12rem;
	@include flex_row(flex-end, flex-end);
}
