@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserNotifications {
	width: 100%;
	height: auto;
	margin-bottom: 5rem;

	&_addEmail {
		width: 100%;
		margin-bottom: 2rem;
	}
	&_today {
		width: 100%;
	}
}

@media only screen and (max-width: 500px) {
	.UserNotifications {
		width: 100%;
	}
}
