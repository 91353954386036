@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RequireSecurityQuestions {
	@include fill_container;
	@include flex_col(center, center);

	&_button {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		border-radius: 5rem;
		padding: 0.8rem 1.5rem;
		border: 2px solid map-get($map: $redMap, $key: "600");
		outline: none;
		background-color: transparent;
		margin-bottom: 2rem;
		@include flex_row(center, center);

		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $redMap, $key: "600");
			margin-left: 0.5rem;
			align-self: baseline;
		}
		&:hover {
			cursor: pointer;
			background-color: map-get($map: $redMap, $key: "600");
			color: #ffffff;
		}
		&:hover &_icon {
			fill: #ffffff;
		}
	}
}
