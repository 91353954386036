@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditUserInfo {
	@include fill_container;

	&_about {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_form {
		@include fill_container;
		padding: 1rem 0;

		&_field {
			width: 100%;
			margin-bottom: 2rem;
		}
		&_actions {
			width: 100%;
			height: auto;
			@include flex_row(flex-start, center);
		}
	}
}
