@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PasswordValidator {
	width: 100%;
	margin-bottom: 2rem;

	&_input {
		width: 100%;
	}

	&_indicator {
		width: max-content;
		margin-bottom: 1rem;
	}
	&_errors {
		width: 100%;
		height: auto;
		list-style-type: circle;
	}
}
