@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginPageMessage {
	width: 100%;
	height: auto;
	@include flex_row(center, center);
	text-align: center;
	font-size: 1.5rem;
	font-weight: 500;
}
