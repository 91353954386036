@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserLockouts {
	@include grow_container;
	min-height: 70rem;

	&_header {
		width: 100%;
		height: 5rem;
		display: block;
		margin-top: 4rem;
		margin-bottom: 2rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "700");
		}
		&_refresh {
			width: 100%;
			height: auto;
			display: block;
			margin-top: 1rem;
			margin-bottom: 2rem;

			&_btn {
				padding: 1rem 1.5rem;
				border-radius: 0.5rem;
				background-color: map-get($map: $purpleMap, $key: "600");
				color: #ffffff;
				border: none;
				outline: none;
				font-size: 1.5rem;
				font-weight: 600;
				margin-bottom: 2rem;
				&:hover {
					cursor: pointer;
					box-shadow: map-get($map: $shadows, $key: "medium");
				}
			}
		}
	}

	&_search {
		width: 100%;
		@include flex_col(center, center);
		margin: 2rem 0;
		margin-top: 4rem;

		&_results {
			margin-top: 0.3rem;
			font-size: 1.3rem;
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}

	&_lockouts {
		width: 100%;
		height: auto;
		min-height: 80rem;
		margin-top: 6rem;
	}
}

.SearchInput {
	width: 40rem;
	max-width: 100%;
	height: 4rem;
	background-color: #ffffff;
	border-radius: 5rem;
	border: 1px solid #ccc;
	@include flex_row(flex-start, center);
	&:hover,
	&:focus-within {
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
		cursor: pointer;
	}

	&_searchIcon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "600");
		margin-left: 0.5rem;
	}

	&_input {
		width: 100%;
		height: 100%;
		border-radius: 5rem;
		background-color: transparent;
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "900");
		padding-left: 1rem;
		border: none;
		outline: none;
		&::placeholder {
			color: map-get($map: $blueGreyMap, $key: "300");
		}
	}
	&_clear {
		width: 2.2rem;
		height: 2.2rem;
		min-width: 2.2rem;
		min-height: 2.2rem;
		background-color: map-get($map: $redMap, $key: "200");
		@include flex_row(center, center);
		border-radius: 50%;
		margin-right: 0.6rem;
		border: none;
		outline: none;
		&:hover,
		&:focus-within {
			box-shadow: map-get($map: $shadows, $key: "redOutline");
			cursor: pointer;
		}

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $redMap, $key: "600");
		}
	}
}
