@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep6 {
	@include fill_container;
	@include flex_col(center, center);

	&_selector {
		@include fill_container;
		@include flex_col(center, center);
		height: auto;
	}

	&_selections {
		width: 100%;
		height: auto;
		padding: 2rem;
		@include flex_row(center, center);

		&_count {
			font-size: 1.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "400");

			&_value {
				color: map-get($map: $redMap, $key: "600");
				font-family: map-get($map: $fonts, $key: "altSans");
			}
		}
	}
}
