@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetOptions {
	width: 100%;
	height: auto;

	&_verified {
		@include flex_row(flex-start, center);
		width: 100%;
		padding: 0 4rem;
		height: auto;
		margin-bottom: 2rem;

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $purpleMap, $key: "600");
			margin-right: 0.5rem;
		}
		&_msg {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
}

.ConfirmedMsg {
	@include flex_row(flex-start, center);
	width: 100%;
	padding: 0 4rem;
	height: auto;
	margin-bottom: 2rem;

	&_icon {
		width: 1.8rem;
		height: 1.8rem;
		fill: map-get($map: $purpleMap, $key: "600");
		margin-right: 0.5rem;
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "600");
	}
}

.InvalidMsg {
	@include flex_row(flex-start, center);
	width: 100%;
	padding: 0 4rem;
	height: auto;
	margin-bottom: 2rem;

	&_icon {
		width: 1.8rem;
		height: 1.8rem;
		fill: map-get($map: $redMap, $key: "600");
		margin-right: 0.5rem;
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
}
