@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.OutageUI {
	width: 100%;

	&_item {
		width: 100%;
		color: map-get($map: $blueMap, $key: "600");
		// @include flex_row(flex-start, flex-start);
	}
}

.LoginPage {
	@include fill_container;

	&_logo {
		@include flex_row(center, center);
		width: 100%;
		height: 15rem;
		background-color: transparent;
		margin-bottom: 4rem;
	}
	&_content {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
	}
	&_messages {
		display: block;
		margin: 0 auto;
		padding-top: 2rem;
		width: 40rem;
		max-width: 100%;
		height: auto;
		@include flex_col(center, center);

		&_warning {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
			text-align: center;
		}
	}
}

.Message {
	position: absolute;
	width: initial;
	height: initial;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	top: 1rem;
	right: 1rem;
	@include flex_row(flex-start, center);
	background-color: map-get($map: $flatColors, $key: "mainGreen");
	box-shadow: map-get($map: $shadows, $key: "heavySubtle");

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $colors, $key: "main");
		margin-right: 0.5rem;
	}
	&_msg {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "main");
	}
}

@media only screen and (max-width: 550px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			@include flex_row(center, center);

			&_image {
				max-width: 40rem;
				width: 100%;
				height: auto;
			}
		}
	}
}
@media only screen and (max-width: 430px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			@include flex_row(center, center);
			margin-bottom: 2rem;

			&_image {
				max-width: 30rem;
				width: 100%;
				height: 8rem;
			}
		}
	}
}
@media only screen and (max-width: 360px) {
	.LoginPage {
		@include fill_container;

		&_logo {
			width: 100%;
			height: 10rem;
			@include flex_row(center, center);
			margin-bottom: 2rem;

			&_image {
				max-width: 30rem;
				width: 100%;
				height: 8rem;
			}
		}
		&_content {
			padding: 0 0.8rem;
		}
	}
}
