@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AccountUnlockDialog {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50rem;
	height: 30rem;
	margin-top: -15rem;
	margin-left: -20rem;
	border-radius: 1rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	@include focusMode;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		appearance: none;
		width: 1rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "400");
		border-radius: 5rem;
		height: 1rem;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
		border: 4px solid transparent;
		border-radius: 5rem;
		background-clip: content-box; /* THIS IS IMPORTANT */
	}

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 1rem;
		@include flex_row(space-between, flex-start);

		&_title {
			font-size: 2rem;
			font-weight: 800;
			color: map-get($map: $blueGreyMap, $key: "600");
			padding-left: 0.5rem;
		}
		&_closeIcon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "600");
			&:hover {
				cursor: pointer;
			}
		}
	}

	&_inner {
		width: 100%;
		height: 100%;
	}
}

@media only screen and (max-width: 550px) {
	.AccountUnlockDialog {
		width: 97vw;
		height: 40rem;
		margin-top: -20rem;
		margin-left: -48.5vw;
	}
}
