@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCInfoModal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 60rem;
	height: 45rem;
	margin-left: -30rem;
	margin-top: -22.5rem;
	border-radius: 1rem;
	background-color: #ffffff;
	@include add_center_focus(60rem, 45rem);
	box-shadow: 2px 9px 1500px 1500px rgba(0, 0, 0, 0.72);

	&_top {
		width: 100%;
		height: 4rem;
		padding: 0 1rem;
		background-color: map-get($map: $blueGreyMap, $key: "200");
		@include flex_row(space-between, center);
		border-bottom: 1px solid map-get($map: $blueGreyMap, $key: "100");
		border-radius: 1rem 1rem 0 0;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueMap, $key: "600");
		}
		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "600");
			margin-left: auto;
			cursor: pointer;
		}
	}
	&_main {
		width: 100%;
		height: 90%;
		padding: 1rem 2rem;
		overflow-y: auto;
		/* Background Tracking */
		&::-webkit-scroll-track {
			background-color: #ffffff;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.7rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "500");
			border-radius: 5rem;
		}
	}
}

@media only screen and (max-width: 650px) {
	.LOCInfoModal {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 100vw;
		height: 45vh;
		margin-left: -50vw;
		margin-top: -22.5vh;
	}
}
