@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserFacilitySummary {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
	&_main {
		@include fill_container;

		&_count {
			width: 100%;

			&_value {
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");

				& > b {
					color: map-get($map: $redMap, $key: "600");
				}
			}
		}
		&_details {
			margin-top: 1rem;

			&_view {
				border: none;
				outline: none;
				padding: 0.6rem 1.2rem;
				border-radius: 0.5rem;
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "700");
				cursor: pointer;
				background-color: map-get($map: $purpleMap, $key: "50");
				margin-bottom: 1rem;
			}
			&_list {
				padding: 1rem 3rem;
				list-style: circle;
			}
		}
	}
	&_noList {
		width: 100%;
		height: auto;
		color: map-get($map: $redMap, $key: "600");
		font-size: 1.5rem;
		font-weight: 500;
	}
}
.FacilityEntry {
	font-size: 1.3rem;
	font-weight: 600;
	color: map-get($map: $blueGreyMap, $key: "600");
	font-family: map-get($map: $fonts, $key: "altSans");
}
