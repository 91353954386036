@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ContactUsPage {
	width: 100vw;
	height: auto;
	min-height: 100vh;
	padding: 2rem;
	background-color: map-get($map: $brandColors, $key: "secondary");
	font-family: map-get($map: $fonts, $key: "mainSans");

	&_header {
		width: 100%;
		height: 20rem;
		padding: 1rem 2rem;
		text-align: center;
		@include flex_col(center, center);

		&_title {
			font-size: 6rem;
			font-weight: 700;
			color: #ffffff;
		}
		&_altTitle {
			font-size: 6rem;
			font-weight: 700;
			color: #ffffff;
		}
		&_subtitle {
			font-size: 2.5rem;
			font-weight: 500;
			color: #ffffff;
			text-decoration: none;
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
	&_main {
		@include fill_container;
		padding-top: 8rem;
	}
}
