@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserDetails {
	@include fill_container;
	padding: 2rem 1rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			@include flex_row(flex-start, center);
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
				margin-left: 0.5rem;
			}
		}
		&_id {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
		}
		&_userType {
			width: 100%;
			@include flex_row(flex-start, center);
			margin-top: 0.5rem;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");

			&_label {
				margin-left: 0.5rem;
			}
		}
		&_dateCreated {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_msg {
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $redMap, $key: "600");
		@include flex_row(flex-start, center, nowrap);

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $redMap, $key: "600");
			margin-right: 0.5rem;
		}
	}

	&_facilityAccess {
		width: 100%;
		height: 10rem;
	}
	&_grantAccess {
		width: 100%;
		@include flex_row(flex-start, center);
	}
	&_currentAccess {
		width: 100%;

		margin-top: 3rem;

		&_view {
			width: 100%;

			&_btn {
				font-size: 1.3rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "700");
				background-color: transparent;
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
					color: map-get($map: $blueMap, $key: "600");
				}
			}
			&_noAccess {
				font-size: 1.3rem;
				font-weight: 600;
				color: map-get($map: $redMap, $key: "600");
				padding-left: 1rem;
				margin-top: 2rem;
			}
		}
		&_list {
			width: 100%;
			height: 15rem;
			overflow-y: auto;
			padding: 1rem 2rem;

			&_item {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "600");
				margin-bottom: 0.5rem;
			}
		}
	}

	&_desc {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}

	&_main {
		@include fill_container;
		max-height: 100%;
	}

	&_suspendUser {
		width: auto;
		margin-bottom: 2rem;

		&_details {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 2rem;
		}

		&_label {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin-bottom: 0.3rem;
		}

		&_isSuspended {
			margin-top: 2rem;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
		}
	}

	&_deleteIcon {
		width: 1.8rem;
		height: 1.8rem;
		fill: #ffffff;
		margin-right: 0.3rem;
	}

	&_confirmDeleteDialog {
		width: 100%;
		height: 100%;
		@include flex_row(flex-end, center);
	}
}

.Suspended {
	font-size: 2.5rem;
	font-weight: 700;
	color: map-get($map: $redMap, $key: "600");
	margin-left: 1rem;
}
.SuspendedDate {
	@include flex_row(flex-start, center);
	width: 100%;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
	&_date {
		color: map-get($map: $redMap, $key: "600");
		font-size: 1.6rem;
		font-weight: 600;
		font-family: "Open Sans";
		margin-left: 0.5rem;
	}
}
.SuspendedBy {
	@include flex_row(flex-start, center);
	width: 100%;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
	&_suspendedBy {
		color: map-get($map: $redMap, $key: "600");
		text-decoration: underline;
		font-size: 1.6rem;
		font-weight: 600;
		font-family: "Open Sans";
		margin-left: 0;
	}
}
.Locked {
	font-size: 1.8rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
	@include flex_row(flex-start, flex-start);
	margin-left: 1rem;
	& > span {
		margin-left: 0.5rem;
	}
}

.AboutText {
	@include flex_row(flex-start, center);
	width: 100%;
	margin-top: 0.5rem;
	color: map-get($map: $blueGreyMap, $key: "500");
	font-size: 1.6rem;
	font-weight: 500;

	&_label {
		color: map-get($map: $blueGreyMap, $key: "500");
		font-weight: 400;
	}
}

@media only screen and (max-width: 700px) {
	.UserDetails {
		@include fill_container;
		padding: 2rem 0.7rem;

		&_header {
			width: 100%;
			height: auto;
			margin-bottom: 4rem;

			&_title {
				font-size: 1.8rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");

				& > b {
					color: map-get($map: $blueMap, $key: "600");
				}
			}
			&_id {
				@include flex_row(flex-start, center);
				width: 100%;
				margin-top: 0.5rem;
				color: map-get($map: $blueGreyMap, $key: "500");
				font-size: 1.2rem;
				font-weight: 600;
				word-break: keep-all;
				word-wrap: normal;
			}
		}
	}
}
