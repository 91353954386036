@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LimitedTextInput {
	position: relative;
	height: auto;
	@include flex_col(flex-start, flex-start);

	&_maxChars {
		position: absolute;
		top: 100%;
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
	}
}
