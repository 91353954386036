@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserProfilePage {
	width: 70vw;
	max-width: 100rem;
	height: 100%;
	display: block;
	margin: 0 auto;
}

@media only screen and (max-width: 800px) {
	.UserProfilePage {
		width: 80vw;
		height: 100%;
		display: block;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 650px) {
	.UserProfilePage {
		width: 100%;
		height: 100%;
		display: block;
		margin: 0 auto;
	}
}
