@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MobileSidebarListItem {
	min-width: max-content;
	height: 100%;
	background-color: transparent;
	@include flex_row(center, center);
	margin: 0 2rem;

	& > a {
		@include fill_container;
		@include flex_col(center, center);
	}
	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "400");
	}
	&_text {
		font-size: 1.1rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "400");
	}

	&_isActive {
		@extend .MobileSidebarListItem;

		&_icon {
			fill: #ffffff;
		}
		&_text {
			color: #ffffff;
		}
	}
}

.MobileSidebarHelp {
	min-width: max-content;
	height: 100%;
	background-color: transparent;
	@include flex_row(center, center);
	margin: 0 2rem;

	&_btn {
		background-color: transparent;
		border: none;
		outline: none;
		@include fill_container;
		@include flex_col(center, center);

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $blueGreyMap, $key: "400");
		}
		&_text {
			font-size: 1.1rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
			text-decoration: 2px map-get($map: $purpleMap, $key: "400") underline;
		}

		&_isActive {
			@extend .MobileSidebarHelp;

			&_icon {
				fill: #ffffff;
			}
			&_text {
				color: #ffffff;
			}
		}
	}
}
