@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EmarUserAccess {
	width: max-content;
	height: auto;
	margin-bottom: 2rem;

	&_app {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		margin-bottom: 0.5rem;

		&_isLocked {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}
	&_switch {
		width: max-content;
	}
}
