@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTable {
	@include fill_container;
	max-width: 75rem;
	min-height: 66rem;
	border-radius: 1rem;
	border-top: none;
	margin-bottom: 2rem;

	&_wrapper {
		border-radius: 1rem;
		border: 1px solid #ccc;
		margin-bottom: 1rem;
	}

	&_footer {
		width: 100%;
		min-height: 5rem;
		height: 100%;
		@include flex_row(center, flex-end);
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}

.LOCHeader {
	width: 100%;
	height: 6rem;
	padding: 0 1rem;
	@include flex_row(space-between, center);
	background-color: map-get($map: $blueGreyMap, $key: "900");
	border-radius: 1rem 1rem 0 0;
	&_titleBar {
		width: 100%;
		height: 100%;
		padding: 0.5rem 1rem;
		border-radius: 1rem 1rem 0 0;
		background-color: map-get($map: $blueGreyMap, $key: "900");
		@include flex_col(flex-start, flex-start);

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "400");
		}
		&_subtitle {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "300");
		}
	}
	&_help {
		width: auto;
		margin-left: auto;
	}
}
