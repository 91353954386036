@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserManagementPage {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_facility {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_tabs {
		width: 100%;
		height: 100%;
		@include flex_row(center, center);
	}
}

.TabButton {
	width: 100%;
	height: 100%;
	@include flex_row(center, center);

	&_icon {
		width: 2.7rem;
		height: 2.7rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		margin-right: 0.5rem;
	}
	&_text {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

@media only screen and (max-width: 700px) {
	.UserManagementPage {
		@include fill_container;

		&_header {
			width: 100%;
			margin-bottom: 4rem;
		}
		&_tabs {
			width: 100%;
			height: 100%;
			@include flex_row(center, flex-start);
		}
	}

	.TabButton {
		width: 100%;
		height: 100%;
		@include flex_row(center, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
			margin-right: 0.5rem;
		}
		&_text {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
