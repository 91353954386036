@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ErrorPage {
	@include fill_container;
	// max-height: 80vh;
	padding: 0 2rem;
	@include flex_col(flex-start, center);

	&_wrapper {
		@include fill_container;
		@include flex_col(flex-start, center);
		max-height: 80vh;
	}

	&_logoContainer {
		width: 25rem;
		height: 7rem;
		margin-bottom: 4rem;

		&_logo {
			@include fill_container;
			object-fit: contain;
		}
	}
}

@media only screen and (max-width: 600px) {
	.ErrorPage {
		@include fill_container;
		max-height: 100vw;
		padding: 0 0.5rem;

		&_logoContainer {
			margin-bottom: 4rem;
		}
	}
}
@media only screen and (max-width: 400px) {
	.ErrorPage {
		@include fill_container;
		max-height: 100vw;
		padding: 0 0;
		&_logoContainer {
			margin-bottom: 4rem;
		}
	}
}
