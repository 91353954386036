@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MultiStepForm {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	border-radius: 1rem;

	&_inner {
		width: 100%;
		height: 90%;
		max-height: 90%;
		padding: 5rem 2rem;
		border-radius: 1rem;
		overflow-y: scroll;
	}

	&_footer {
		align-self: flex-end;
		width: 100%;
		height: auto;
		padding: 2rem 0.5rem;
	}
}

@media only screen and (max-width: 650px) {
	.MultiStepForm {
		@include fill_container;

		&_inner {
			padding: 5rem 0.5rem;
		}
	}
}
