@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationPanelSelector {
	width: 100%;
	height: auto;

	&_selector {
		width: 100%;
	}
	&_residents {
		width: 100%;
		margin-top: 3rem;

		&_label {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
	&_selectAll {
		width: 100%;
		height: auto;
		margin-top: 2rem;
	}
}

@media only screen and (max-width: 700px) {
	.MigrationPanelSelector {
		width: 100%;
		height: auto;

		&_selector {
			width: 100%;
		}
		&_residents {
			width: 100%;
		}
	}
}
