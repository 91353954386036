@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetUsersPassword {
	width: 100%;
	height: auto;

	&_lockedOut {
		font-size: 1.6rem;
		color: map-get($map: $redMap, $key: "600");
		margin-top: 2rem;
		text-decoration: underline;
	}

	&_info {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center, wrap);
		font-size: 1.5rem;
		font-weight: 500;
		overflow-wrap: break-word;
		word-wrap: break-word;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;

		& > span {
			overflow-wrap: break-word;
			word-wrap: break-word;
			margin: 0 0.5rem;
		}

		& > b {
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
	&_actions {
		width: 100%;
		margin-top: 6rem;
		@include flex_row(flex-start, center);
	}

	&_msg {
		width: 100%;
		margin: 2rem 0;
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}

	&_success {
		width: auto;
		height: 5rem;
		border-radius: 5rem;
		@include flex_row(flex-start, center);
		// background-color: map-get($map: $purpleMap, $key: "600");

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $purpleMap, $key: "600");
			margin-right: 0.5rem;
		}

		&_msg {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "600");

			& > b {
				color: map-get($map: $greenMap, $key: "500");
			}
		}
	}
}
