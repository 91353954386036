@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AvatarModal {
	width: 50rem;
	height: 40rem;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -25rem;
	margin-top: -20rem;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	z-index: 1;

	&_top {
		width: 100%;
		height: 5rem;
		padding: 0.3rem 1.5rem;
		margin-bottom: 1rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
		&_close {
			width: 2.8rem;
			height: 2.8rem;
			border-radius: 0.5rem;
			background-color: transparent;
			@include flex_row(center, center);
			transition: all 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $greyMap, $key: "100");
				transform: rotate(360deg);
			}
			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_main {
		@include fill_container;
		height: 80%;
		overflow-y: auto;
	}
}

@media only screen and (max-width: 550px) {
	.AvatarModal {
		width: 90vw;
		height: 50vh;
		position: fixed;
		margin-left: -45vw;
		margin-top: -25vh;
	}
}
