@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.InternalToolsPage {
	@include fill_container;
}

.TabButton {
	width: 100%;
	height: 100%;
	@include flex_row(center, center);

	&_icon {
		width: 2.7rem;
		height: 2.7rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		margin-right: 0.5rem;
	}
	&_text {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
