@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PortalView {
	@include fill_container;
	max-width: 100vw;
	max-height: 80%;

	&_routes {
		padding-top: 0;
		@include flex_col(center, center);
	}

	&_loading {
		width: 100%;
		height: 60vh;
		display: block;
		margin: 4rem auto;

		&_msg {
			width: 100%;
			height: auto;
			color: map-get($map: $blueGreyMap, $key: "600");
			text-align: center;
			margin-top: 1rem;
			font-size: 1.5rem;
			font-weight: 600;
		}
	}
}
