@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAccessIndicator {
	width: 100%;
	height: auto;
}
.HasAccess {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);
	margin-bottom: 0.5rem;

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $greenMap, $key: "500");
		margin-right: 0.3rem;
	}
	&_appName {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $greenMap, $key: "500");
	}
}
.NoAccess {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $redMap, $key: "600");
		margin-right: 0.3rem;
	}
	&_appName {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
	}
}
