@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MobileSidebar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 6rem;
	background-color: map-get($map: $blueGreyMap, $key: "700");
	z-index: 10;

	&_nav {
		@include fill_container;
		@include flex_row(flex-start, center);
		padding: 0 0.5rem;
	}
}
