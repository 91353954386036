@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAppAccessController {
	width: max-content;
	height: auto;
	margin-bottom: 2rem;

	&_app {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainBlackBlue");
		margin-bottom: 0.5rem;

		&_isLocked {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}
	&_switch {
		width: max-content;
	}
	&_checkbox {
		margin-top: 2rem;
		padding-left: 3rem;
	}
	&_warning {
		width: auto;
		padding-left: 3rem;
		margin-top: 1rem;
		font-size: 1.4rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "mainSans");
		font-style: italic;
		color: map-get($map: $orangeMap, $key: "600");
	}
}
