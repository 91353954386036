@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ControllableTabsWrapper {
	width: 100%;
	height: auto;
	min-height: 60rem;
	background-color: transparent;
}
