@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentDetails {
	@include fill_container;
	padding: 2rem 1rem;

	&_header {
		width: 100%;
		height: auto;
		padding-bottom: 4rem;
		margin-bottom: 4rem;
		border-bottom: 1px solid #ccc;

		&_title {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
		&_id {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
		}
		&_unitType {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 1rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			&_label {
				margin-right: 0.5rem;
			}
		}

		&_dateCreated {
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			margin-bottom: 0.5rem;
		}
		&_status {
			@include flex_row(flex-start, center);
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			& > span {
				margin-right: 0.5rem;
			}
		}
	}
	&_form {
		@include fill_container;

		&_label {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
			margin-bottom: 2rem;
		}
	}
}
.SectionDesc {
	font-size: 1.6rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin-bottom: 3rem;
	margin-top: -0.8rem;
}
.ArchiveButton {
	padding: 1rem 1.8rem;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $redMap, $key: "600");
	color: map-get($map: $redMap, $key: "600");
	background-color: transparent;
	font-size: 1.6rem;
	font-weight: 600;
	&:hover {
		background-color: map-get($map: $redMap, $key: "600");
		color: #ffffff;
		cursor: pointer;
	}
}
.UnarchiveButton {
	padding: 1rem 1.8rem;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $greenMap, $key: "500");
	color: map-get($map: $greenMap, $key: "600");
	background-color: transparent;
	font-size: 1.6rem;
	font-weight: 600;
	&:hover {
		background-color: map-get($map: $greenMap, $key: "500");
		color: #ffffff;
		cursor: pointer;
	}
}
