@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Home {
	// position: relative;
	@include fill_container;
	max-width: 100vw;
	height: auto;
	min-height: 100vh;
	max-height: auto;

	&_header {
		width: 100%;
		height: auto;
		padding: 1rem;
		margin-top: 3rem;

		&_welcome {
			text-align: center;
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "mainBlackBlue");

			& > b {
				color: map-get($map: $colors, $key: "mainBlue");
			}
		}
		&_username {
			margin-top: 0.5rem;
			text-align: center;
			font-size: 1.4rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}

		&_title {
			margin-top: 2rem;
			text-align: center;
			font-size: 2rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}

	&_main {
		@include fill_container;
		height: auto;
		@include flex_col(center, center);

		&_options {
			width: 100%;

			// MAIN CODE //
			// height: auto;
			// min-height: max-content;

			// SUBSTITUTE //
			height: inherit;

			flex-shrink: 1;
		}
		&_emar {
			width: 100%;
			height: auto;
			position: relative;
			// margin-top: -10rem;
			margin-bottom: 4rem;
		}
		&_other {
			// margin-top: -10rem;
			width: 100%;
			margin-bottom: 4rem;
			@include flex_col(center, center);

			&_notice {
				width: 100%;
				height: auto;
			}
		}
		&_noticeOfChanges {
			width: 100%;
			height: auto;
			display: block;
			margin-bottom: 4rem;
		}
	}

	&_hints {
		width: 100%;
		padding: 0 2rem;
		padding-bottom: 10rem;
	}
	&_notices {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
	}
}

.NoticeOfChanges {
	width: 70%;
	display: block;
	margin: 0 auto;
	padding: 1rem 1rem;
	background-color: map-get($map: $redMap, $key: "200");
	border-radius: 0.5rem;

	&_title {
		font-size: 2.2rem;
		font-weight: 700;
		color: map-get($map: $blueMap, $key: "600");
	}
	&_para {
		font-size: 1.6rem;
		font-weight: 500;
		margin: 1rem 0;
		line-height: 1.3;
	}
	&_list {
		width: 100%;
		padding-left: 2rem;
		list-style: none;
		counter-reset: step-counter;

		&_item {
			counter-increment: step-counter;
			font-size: 1.5rem;
			font-weight: 500;
			padding-left: 1rem;
			line-height: 1.5;

			// USER-TYPE UNDERLINES //

			// FACILITY-ADMIN
			& [data-id="fa"] {
				text-decoration: 2px solid underline
					map-get($map: $pinkMap, $key: "500");
			}
			// REGIONAL-ADMIN
			& [data-id="ra"] {
				text-decoration: 2px solid underline
					map-get($map: $yellowMap, $key: "300");
			}
			// EXECUTIVE-ADMIN
			& [data-id="ea"] {
				text-decoration: 2px solid underline
					map-get($map: $greenMap, $key: "500");
			}
			// MED-TECH
			& [data-id="mt"] {
				text-decoration: 2px solid underline map-get($map: $redMap, $key: "600");
			}
			// STANDARD-USER
			& [data-id="st"] {
				text-decoration: 2px solid underline
					map-get($map: $blueGreyMap, $key: "700");
			}

			&::marker {
				content: counter(step-counter) ".";
				font-size: 2rem;
			}
			& > mark {
				padding: 0.1rem 0.2rem;
				border-radius: 0.3rem;
				background-color: map-get($map: $pinkMap, $key: "600");
				color: #ffffff;
			}
		}
	}
	&_contact {
		width: 100%;
		font-size: 1.5rem;
		font-weight: 500;
		margin-top: 2rem;

		&_link {
			font-size: 1.5rem;
		}
	}
}

@media only screen and (max-width: 500px) {
	.Home {
		@include fill_container;
		max-height: 100vh;
		max-width: 100vw;

		&_options {
			height: auto;
		}
	}
	.NoticeOfChanges {
		width: 100%;
		padding: 1rem 2rem;
	}
}
