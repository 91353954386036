@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityDetails {
	@include fill_container;
	padding: 2rem 0;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "headings");
		}
		&_type {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_content {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_heading {
			font-size: 1.7rem;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}
	&_id {
		font-size: 1.6rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
		@include flex_row(flex-start, center);
		margin-bottom: 1rem;
	}
	&_apps {
		width: auto;

		&_warning {
			margin-bottom: 3rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);

		& button {
			&:first-child {
				box-shadow: map-get($map: $hoverShadows, $key: "redOutline");
				cursor: pointer;
			}
		}
	}
}

.FacilityAddress {
	width: 100%;

	&_name {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainBlackBlue");
	}
}

.AddressUI {
	display: block;

	&_text {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "headings");
	}
}

.EmarStatus {
	width: auto;

	&_enabled {
		width: 12rem;
		height: 3.5rem;
		@include flex_row(flex-start, center);
		border-radius: 5rem;
		color: map-get($map: $blueMap, $key: "100");
		border: 2px solid map-get($map: $greenMap, $key: "600");
		border: 2px solid #00c7a6;

		&_check {
			width: 3rem;
			height: 3rem;
			margin-right: auto;

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $greenMap, $key: "600");
				fill: #00c7a6;
			}
		}

		&_badge {
			color: map-get($map: $greenMap, $key: "600");
			color: #00c7a6;
			font-size: 1.6rem;
			font-weight: 700;
			margin-right: auto;
			margin-left: -0.5rem;
		}
	}
	&_disabled {
		width: 15rem;
		height: 3.5rem;
		@include flex_row(flex-start, center);
		border-radius: 5rem;
		border: 2px solid map-get($map: $redMap, $key: "600");

		&_check {
			width: 3rem;
			height: 3rem;
			margin-right: auto;

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}

		&_badge {
			color: map-get($map: $redMap, $key: "600");
			font-size: 1.6rem;
			font-weight: 700;
			margin-right: auto;
		}
	}
}
.EPay {
	width: auto;

	&_enabled {
		width: 12rem;
		height: 3.5rem;
		@include flex_row(flex-start, center);
		border-radius: 5rem;
		color: map-get($map: $blueMap, $key: "100");
		border: 2px solid map-get($map: $greenMap, $key: "600");
		border: 2px solid #00c7a6;

		&_check {
			width: 3rem;
			height: 3rem;
			margin-right: auto;

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $greenMap, $key: "600");
				fill: #00c7a6;
			}
		}

		&_badge {
			color: map-get($map: $greenMap, $key: "600");
			color: #00c7a6;
			font-size: 1.6rem;
			font-weight: 700;
			margin-right: auto;
			margin-left: -0.5rem;
		}
	}
	&_disabled {
		width: 15rem;
		height: 3.5rem;
		@include flex_row(flex-start, center);
		border-radius: 5rem;
		border: 2px solid map-get($map: $redMap, $key: "600");

		&_check {
			width: 3rem;
			height: 3rem;
			margin-right: auto;

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}

		&_badge {
			color: map-get($map: $redMap, $key: "600");
			font-size: 1.6rem;
			font-weight: 700;
			margin-right: auto;
		}
	}
}

.EPaySupport {
	width: auto;
	margin-top: 3rem;

	&_text {
		font-size: 1.5rem;
		font-weight: 600;
		font-style: italic;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 0.3rem;

		&_link {
			font-style: normal;
			color: map-get($map: $blueMap, $key: "600");
			// margin: 0 0.3rem;
		}
	}
}
.EmarSupport {
	width: auto;
	margin-top: 3rem;

	&_text {
		font-size: 1.5rem;
		font-weight: 600;
		font-style: italic;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 0.3rem;

		&_link {
			font-style: normal;
			color: map-get($map: $blueMap, $key: "600");
			// margin: 0 0.3rem;
		}
	}
}
