@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Hint {
	width: 100%;
	max-width: 80rem;
	height: auto;
	@include flex_row(center, center);

	&_wrapper {
		width: 10rem;
		height: 5rem;
		@include flex_row(center, center);

		&_icon {
			@include fill_container;
		}
	}
	&_hint {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $blueMap, $key: "600");
	}
}

@media only screen and (max-width: 500px) {
	.Hint {
		width: 100%;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		// @include flex_row(center, center);

		&_wrapper {
			width: 20rem;
			height: 8rem;
			float: left;
			border-radius: 50%;
			shape-outside: ellipse();
			text-align: center;
			// @include flex_row(center, center);
			// margin-bottom: 0.5rem;

			// vertical alignment css
			// width: 8rem;
			// height: 5rem;
			// @include flex_row(center, center);
			// margin-bottom: 0.5rem;

			&_icon {
				@include fill_container;
				float: left;
				shape-outside: ellipse();
			}
		}
		&_hint {
			display: inline-block;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueMap, $key: "600");
			text-align: right;
			& > * {
				display: inline-block;
			}
		}
	}
}
