@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAccessColumns {
	position: relative;
	width: 100%;
	height: 5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	z-index: 10;

	&_inner {
		@include fill_container;
		@include flex_row(space-around, center);
		padding: 1rem 2rem;

		&_item,
		&_userCol,
		&_deactivate {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "saturated");
			@include flex_row(center, center);

			&_name {
				margin-right: 0.3rem;
			}

			&:hover &_icon {
				fill: map-get($map: $redMap, $key: "500");
				cursor: pointer;
			}
			&:hover &_name {
				color: map-get($map: $redMap, $key: "500");
				cursor: pointer;
			}

			&_icon {
				width: 2.2rem;
				height: 2.2rem;
				fill: map-get($map: $blueGreyMap, $key: "300");
			}
		}

		&_item {
			margin-left: auto;
			margin-right: auto;
			@include flex_row(flex-start, center);
		}

		&_userCol {
			width: 20%;
			height: 100%;
			margin: 0 0;
			@include flex_row(flex-start, center);
			text-align: left;

			&_name {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "saturated");
			}

			&_icon {
				margin-left: 0.5rem;
				width: 2.2rem;
				height: 2.2rem;
				fill: map-get($map: $blueGreyMap, $key: "300");
				align-self: center;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $redMap, $key: "600");
				}
			}
			&:hover &_icon {
				cursor: pointer;
				fill: map-get($map: $redMap, $key: "600");
			}
			&:hover &_name {
				color: map-get($map: $redMap, $key: "600");
				cursor: pointer;
			}
		}

		&_deactivate {
			padding: 0.5rem 0.8rem;
			color: map-get($map: $redMap, $key: "500");
			&:hover {
				cursor: pointer;
			}
		}
	}
}
