@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdminPage {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_facility {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}

.CustomButton {
	position: relative;
	@include fill_container;
	@include flex_row(center, center);
	z-index: -1;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		margin-right: 0.5rem;
	}
	&_name {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
	&_isActive {
		@extend .CustomButton;

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $purpleMap, $key: "500");
			margin-right: 0.5rem;
		}
		&_name {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "500");
		}
	}
	&_isDisabled {
		@extend .CustomButton;
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.AlertsIndicator {
	position: absolute;
	top: -0.6rem;
	right: -1.2rem;
	width: 1.8rem;
	height: 1.8rem;
	border-radius: 50%;
	background-color: map-get($map: $redMap, $key: "500");
	@include beacon;
	z-index: 10;
	box-shadow: map-get($map: $shadows, $key: "heavy");

	&_count {
		@include fill_container;
		@include flex_row(center, center);
		font-family: map-get($map: $fonts, $key: "altSans");
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
		margin-top: -0.05rem;
	}
}

@media only screen and (max-width: 650px) {
	.CustomButton {
		@include fill_container;
		@include flex_row(center, center);

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
		}
		&_name {
			font-size: 1.3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
			word-wrap: break-word;
			white-space: pre-line;
		}
		&_isActive {
			@include fill_container;
			@include flex_row(center, center);
			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $purpleMap, $key: "600");
				margin-right: 0.5rem;
			}
			&_name {
				font-size: 1.3rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "600");
			}
		}
	}
}
