@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

$baseColor: map-get(
	$map: $blueMap,
	$key: "600",
);
$effectColor: map-get(
	$map: $blueMap,
	$key: "600",
);

.EmarErrorSummary {
	width: 100%;
	height: 100%;
	padding: 0.5rem 0.5rem;
	padding-top: 0.3rem;
	display: block;

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, flex-start);
		margin-bottom: 0.5rem;

		&_heading {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		max-height: 100%;
		display: block;

		&_msg {
			font-size: 1.3rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "700");
			& > b {
				color: map-get($map: $redMap, $key: "600");
				font-weight: 800;
			}
		}
		&_details {
			font-size: 1.2rem;
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-top: 0.3rem;
		}
		&_redirect {
			width: auto;
			max-width: max-content;
			height: 100%;
			@include flex_row(flex-start, flex-end);
			margin-top: 0.3rem;
		}
	}
}

.LegacyRedirect {
	width: 100%;
	height: auto;
	font-size: 1.5rem;
	font-weight: 600;
	color: map-get($map: $blueMap, $key: "600");
	text-decoration: none;
	@include hoverEffect($baseColor, $effectColor);
}

@media only screen and (max-width: 450px) {
	.EmarErrorSummary {
		width: 100%;
		height: 100%;
		padding: 0.2rem 0.5rem;
		padding-left: 0.3rem;
		padding-top: 0.1rem;
		display: block;

		&_top {
			margin-bottom: -0.2rem;

			&_heading {
				font-size: 1.3rem;
				font-weight: 700;
				color: map-get($map: $redMap, $key: "600");
			}
		}
		&_main {
			width: 100%;
			height: auto;
			max-height: 100%;
			display: block;

			&_msg {
				font-size: 1rem;
				font-weight: 600;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "700");
			}
			&_details {
				font-size: 1rem;
				font-weight: 500;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
}
