@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TempPassword {
	width: 100%;
	height: auto;

	&_label {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 0.5rem;
	}
	&_wrapper {
		width: 100%;
		height: 4rem;
		@include flex_row(flex-start, center);

		&_value {
			width: max-content;
			padding-left: 0.5rem;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			font-family: "Open Sans", "Roboto", "Arial", sans-serif;
		}
		&_input {
			width: 12rem;
			height: 100%;
			padding-left: 0.5rem;
			border: none;
			outline: none;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			font-family: "Open Sans", "Roboto", "Arial", sans-serif;
			border-radius: 0.5rem;
			background-color: #eaecef;
			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
		}
		&_toggle {
			height: auto;
			border: none;
			outline: none;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			margin-left: 2rem;
			background-color: transparent;
			&:hover {
				cursor: pointer;
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
	&_max {
		margin-top: 0.5rem;
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "500");
	}
	&_copy {
		width: 100%;
		margin-top: 2rem;

		&_btn {
			padding: 0.5rem 1.4rem;
			font-size: 1.5rem;
			font-weight: 600;
			color: #ffffff;
			background-color: map-get($map: $greenMap, $key: "500");
			border-radius: 0.5rem;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
