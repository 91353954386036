@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep2 {
	@include fill_container;
	padding: 0 2rem;
	display: block;

	&_info {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: 2rem;

		&_requirements {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "500");

			&_sample {
				color: map-get($map: $blueMap, $key: "600");
				font-weight: 600;
			}
		}
	}

	&_adminEmail {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		margin-bottom: 2rem;
	}
	&_isWorkingEmail {
		width: 100%;
		height: auto;
		margin-top: 3rem;
		margin-bottom: 2rem;
	}

	&_requirements {
		width: 100%;
		padding: 0 1rem;

		&_item {
			font-size: 1.3rem;
			font-size: 500;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 0.5rem;
		}
	}
	&_password {
		@include fill_container;

		&_msg {
			width: 70%;
			margin-top: 2rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");
		}
	}
}

@media only screen and (max-width: 650px) {
	.CreateUserStep2 {
		@include fill_container;
		padding: 0 1rem;
		display: block;

		&_requirements {
			width: 100%;
			padding: 0 1rem;

			&_item {
				font-size: 1.1rem;
				font-size: 500;
				color: map-get($map: $blueGreys, $key: "headings");
				margin-bottom: 0.5rem;
			}
		}
		&_password {
			@include fill_container;

			&_msg {
				width: 100%;
				margin-top: 2rem;
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}
}
