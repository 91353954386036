@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCLoadedTemplateWrapper {
	@include fill_container;

	&_name {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		@include flex_col(center, center);
	}
	&_inner {
		width: 100%;
		height: auto;
		padding: 2rem 0;
		@include flex_row(space-evenly, center, wrap);
	}
	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}
