@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationStatus {
	width: 100%;
	height: auto;
	margin-top: 4rem;
	@include flex_row(center, center);
}

.StatusIcon {
	padding: 0.5rem 1.8rem;
	border-radius: 5rem;
	width: max-content;
	height: max-content;
	@include flex_row(center, center);
	background-color: transparent;

	&_icon {
		width: 3rem;
		height: 3rem;
	}
	&_desc {
		font-size: 2.2rem;
		font-weight: 800;
		margin-left: 0.3rem;
	}
}
