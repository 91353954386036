@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AddUserType {
	width: 100%;
	height: auto;

	&_toggle {
		width: 100%;
		height: auto;

		&_btn {
			padding: 1.2rem 1.6rem;
			border-radius: 5rem;
			font-size: 1.6rem;
			font-weight: 600;
			background-color: transparent;
			color: map-get($map: $purpleMap, $key: "700");
			border: none;
			outline: none;
			cursor: pointer;
		}
	}

	&_form {
		@include fill_container;
		padding: 2rem;

		&_top {
			width: 100%;
			font-size: 1.8rem;
			font-weight: 600;
			margin-bottom: 2rem;
		}

		&_field {
			width: 100%;
			margin-bottom: 2rem;
		}
		&_actions {
			width: 100%;
			@include flex_row(flex-end, center);
		}
	}
}
