@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HiddenChars {
	width: max-content;
	min-width: 10rem;
	height: 2rem;
	@include flex_row(flex-start, center);
	border-radius: 0.5rem;

	&_hidden {
		width: max-content;
		min-width: 10rem;
		font-size: 1.7rem;
	}
	&_revealed {
		width: max-content;
		min-width: 10rem;
		font-size: 1.7rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "700");
	}

	&_toggle {
		width: max-content;
		height: auto;
		margin-left: 3rem;
		margin-top: 0.3rem;
		@include flex_row(flex-start, center);
		&:hover {
			cursor: pointer;
		}
		&:hover &_icon {
			cursor: pointer;
			fill: map-get($map: $purpleMap, $key: "600");
		}
		&:hover &_text {
			cursor: pointer;
			color: map-get($map: $purpleMap, $key: "600");
		}

		&_icon {
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
		}
		&_text {
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-left: 0.3rem;
		}
	}
}
