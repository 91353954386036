@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCOptions {
	@include fill_container;
	padding: 1rem 2rem;

	&_top {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		font-size: 1.8rem;
		font-weight: 700;
		padding: 0 2rem;
	}

	&_options {
		width: 100%;
		padding: 0 2rem;
		margin-bottom: 4rem;

		&_checkbox {
			margin-bottom: 1rem;
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		margin-top: 2rem;
		@include flex_row(flex-end, center);
	}
}

.LOCOptionsSummary {
	width: auto;
	margin-top: 2rem;

	&_label {
		font-size: 1.8rem;
		font-weight: 800;
	}

	&_desc {
		width: auto;
	}
}

.Desc {
	font-size: 1.4rem;
	font-weight: 500;
	margin: 1rem 0;

	&_name {
		font-size: 1.5rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_desc {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

.Warning {
	width: 100%;
	height: auto;
	margin-bottom: 4rem;

	&_main {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		margin-bottom: 2rem;

		&_iconWrapper {
			width: 4rem;
			height: 4rem;
			min-width: 4rem;
			min-height: 4rem;
			border-radius: 50%;
			@include flex_row(center, center);

			margin-right: 0.5rem;

			&_icon {
				// margin-top: -1rem;
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $orangeMap, $key: "500");
			}
		}

		&_text {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $orangeMap, $key: "500");
		}
	}
	&_alt {
		font-size: 1.4rem;
		font-weight: 700;
		font-style: italic;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}
