@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HomeAlertsBanner {
	width: 65rem;
	max-width: 100%;
	// height: 8rem;
	height: 10rem;
	padding: 0.5rem 0.7rem;
	border-radius: 6rem;
	position: fixed;
	position: absolute;
	left: 50%;
	top: 2rem;
	transform: translateX(calc(-50% + 3rem));
	@include flex_row(flex-start, flex-start);
	background-color: map-get($map: $blueGreyMap, $key: "50");
	box-shadow: map-get($map: $shadows, $key: "pronounced");
	border-bottom: 2px solid map-get($map: $redMap, $key: "500");

	&_iconWrapper {
		width: 9rem;
		min-width: 9rem;
		max-width: 9rem;
		height: 9rem;
		min-height: 9rem;
		max-height: 9rem;
		border-radius: 50%;
		@include flex_row(center, center);
		background-color: map-get($map: $yellowMap, $key: "100");
		margin-right: 1.5rem;

		&_icon {
			width: 7rem;
			height: 7rem;
		}
	}
	&_content {
		width: 100%;
		height: 100%;
	}
	&_close {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		position: absolute;
		top: 2.8rem;
		right: 0.2rem;
		border: 1px solid #ccc;
		@include flex_row(center, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "600");
		}
	}
}

@media only screen and (max-width: 650px) {
	.HomeAlertsBanner {
		width: 100%;
		max-width: 100vw;
		position: fixed;
		left: 0;
		top: 5.5rem;
		transform: none;
	}
}
@media only screen and (max-width: 550px) {
	.HomeAlertsBanner {
		width: 100%;
		max-width: 100vw;
		height: 7rem;
		position: fixed;
		left: 0;
		top: 5.5rem;
		transform: none;
		&_iconWrapper {
			width: 6rem;
			min-width: 6rem;
			max-width: 6rem;
			height: 6rem;
			min-height: 6rem;
			max-height: 6rem;
			border-radius: 50%;
			@include flex_row(center, center);
			background-color: map-get($map: $yellowMap, $key: "100");
			margin-right: 1.5rem;

			&_icon {
				width: 5rem;
				height: 5rem;
			}
		}
		&_content {
			width: 100%;
			height: 100%;
		}
		&_close {
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 50%;
			position: absolute;
			top: 2.8rem;
			right: 0.2rem;
			border: 1px solid #ccc;
			@include flex_row(center, center);

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
}
