@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DashboardPage {
	@include fill_container;
	// padding-top: 12rem;
	padding-top: 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 800;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}
	}
}
