@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ManageUserAccessView {
	@include fill_container;
	height: auto;
	padding: 1rem 0.5rem;
	margin-bottom: 5rem;

	&_warning {
		width: 100%;
		height: auto;
		@include flex_row(center, flex-start);
	}

	&_loading {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
		min-height: 35rem;

		&_msg {
			text-align: center;
			margin-top: 1.5rem;
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_title {
		font-size: 1.4rem;
	}
	&_main {
		width: 100%;
		height: 100%;
		min-height: 70rem;
		overflow: auto;

		&_row {
			width: 100%;
			height: auto;
			padding-left: 0.3rem;
			@include flex_row(space-between, flex-end);
		}

		&_newUser {
			@include flex_row(flex-end, flex-start);
			margin-bottom: 1rem;

			&_icon {
				width: 2.2rem;
				height: 2.2rem;
				fill: #ffffff;
			}
		}
	}
}
