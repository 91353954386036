@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.BrandLogo {
	width: 100%;
	height: auto;
	max-height: 30rem;
	@include flex_row(center, center);

	&_img {
		@include flex_row(center, center);
		height: 10rem;
		background-color: transparent;
		margin-bottom: 4rem;
	}
}
