@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HomePageMessage {
	margin-top: 2rem;
	width: 100%;
	height: auto;
	@include flex_row(center, center);
	text-align: center;
	font-size: 1.7rem;
	font-weight: 600;
}
