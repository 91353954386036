@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ManageFacilityAccessView {
	@include fill_container;
	min-height: 60rem;
	height: auto;
	border-radius: 1rem;
	padding: 1rem;
	padding-bottom: 5rem;

	&_main {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	&_title {
		font-size: 1.4rem;
	}
}
