@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserPage {
	@include fill_container;
	padding: 0 2rem;
	margin-top: 5rem;

	&_header {
		width: 100%;
		height: auto;
		@include flex_row(space-between, center);
		margin-bottom: 4rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_createBtn {
			padding: 0.7rem 1.4rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "600");
			color: map-get($map: $purpleMap, $key: "100");
			border: none;
			outline: none;
			@include flex_row(center, center);

			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}

			&_icon {
				fill: map-get($map: $purpleMap, $key: "100");
				width: 1.8rem;
				height: 1.8rem;
				margin-right: 0.5rem;
			}
		}
	}
	&_main {
		width: 100%;
		height: auto;
	}
}

.IconButton {
	@include fill_container;
	@include flex_row(center, flex-start);

	&_btnText {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $blueGreyMap, $key: "600");
		margin-right: 0.3rem;
	}
	&_isActive {
		@extend .IconButton;
		color: map-get($map: $purpleMap, $key: "600");

		&_btnText {
			@extend .IconButton_btnText;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&_icon {
			@extend .IconButton_icon;
			fill: map-get($map: $purpleMap, $key: "600");
		}
	}
}
.IconButtonActive {
	@include fill_container;
	@include flex_row(center, flex-start);

	&_btnText {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}
	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $purpleMap, $key: "700");
		margin-right: 0.3rem;
	}
}
.IconButtonDisabled {
	@include fill_container;
	@include flex_row(center, flex-start);
	opacity: 0.7;

	&:hover {
		cursor: not-allowed;
	}

	&_btnText {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $blueGreyMap, $key: "600");
		margin-right: 0.3rem;
	}
}

@media only screen and (max-width: 600px) {
	.IconButton,
	.IconButtonActive,
	.IconButtonDisabled {
		@include fill_container;

		&_btnText {
			font-size: 1.6rem;
		}
		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			margin-right: 0.3rem;
		}
	}
}

@media only screen and (max-width: 500px) {
	.UserPage {
		padding: 0 0.7rem;
	}
}
