@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityNotificationsList {
	width: max-content;
	max-width: 100%;
	height: auto;
	list-style: none;
}

.NotificationRecipient {
	width: 30rem;
	height: auto;
	padding: 1rem 0.5rem;
	@include flex_row(space-between, center);
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	margin: 1rem 0;
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $greys, $key: "chalk");
		box-shadow: map-get($map: $shadows, $key: "medium");
	}

	&_email {
		width: 80%;
		height: 100%;
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_removeBtn {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		border: none;
		outline: none;
		background-color: map-get($map: $redMap, $key: "600");
		@include flex_row(center, center);

		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "medium");
		}

		&_icon {
			fill: #ffffff;
			width: 1.7rem;
			height: 1.7rem;
		}
	}
}
