@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SidebarHelp {
	width: 100%;
	height: auto;
	max-height: 5rem;
	padding: 0.5rem 3rem;
	margin-bottom: 4rem;

	&_btn {
		background-color: transparent;
		border: none;
		outline: none;
		@include flex_row(center, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_text {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "saturated");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
			}
		}
	}
}

.CollapsedSidebarHelp {
	width: 100%;
	height: auto;
	max-height: 5rem;
	padding: 0.5rem 0;
	margin-bottom: 4rem;

	&_btn {
		background-color: transparent;
		border: none;
		outline: none;
		@include flex_row(center, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $blueGreys, $key: "saturated");
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_text {
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $blueGreys, $key: "saturated");
			&:hover {
				cursor: pointer;
				color: map-get($map: $colors, $key: "mainRed");
			}
		}
	}
}
