@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep7 {
	@include fill_container;
}

.InfoMsg {
	display: block;
	margin: 0.5rem auto;
	width: 80%;
	height: auto;

	&_msg {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "400");

		& b {
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}
.Success {
	width: 100%;
	margin-top: 1rem;
	@include flex_row(center, center);
}
