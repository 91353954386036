@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditAvatarForm {
	@include fill_container;

	&_upload {
		width: 100%;
		height: auto;
		padding: 0 2rem;
		margin-top: 1.5rem;
		@include flex_col(center, center);

		&_label {
			font-size: 1.4rem;
			font-weight: 500;
			align-self: flex-start;
			margin-bottom: 1rem;
		}
	}
	&_file {
		width: 100%;
		padding: 0 2rem;
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");

		& > b {
			color: map-get($map: $purpleMap, $key: "500");
		}
	}
	&_actions {
		width: 100%;
		padding: 0 2rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
		@include flex_row(flex-end, center);

		&_saveBtn {
			padding: 0.8rem 1.2rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "600");
			color: #ffffff;
			font-weight: 600;
			border: none;
			outline: none;
			margin-left: 1rem;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}

		&_cancelBtn {
			padding: 0.8rem 1.2rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $redMap, $key: "600");
			color: #ffffff;
			font-weight: 600;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
}
