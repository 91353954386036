@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityBadgesLegend {
	width: fit-content;
	min-width: fit-content;
	max-width: auto;
	height: auto;

	&_toggle {
		width: auto;
		margin-bottom: 2rem;

		&_btn {
			font-size: 1.4rem;
			font-weight: 600;
			border: none;
			outline: none;
			background-color: transparent;
			color: map-get($map: $purpleMap, $key: "700");
			cursor: pointer;
		}
	}

	&_details {
		@include fill_container;
		min-width: 80%;
		@include flex_row(flex-start, baseline, wrap);
		transition: 0.4s ease-in-out;

		&_example {
			// position: relative;
			min-width: auto;
			width: fit-content;
			height: fit-content;
			@include flex_col(center, center);
			margin-bottom: 1rem;
			margin-left: 1rem;
			margin-right: 1rem;

			&_label {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
}

.FacilityBadge {
	width: 4rem;
	height: 4rem;
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	background-color: #eaecef;
	border: 1px solid #ccc;
	@include flex_row(center, center);
	box-shadow: map-get($map: $shadows, $key: "medium");
	margin: 0.5rem 2rem;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: #111;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainBlue");
		}
	}

	&:hover &_icon {
		fill: map-get($map: $colors, $key: "mainBlue");
		cursor: pointer;
	}
}

.FacilityBadgeExample {
	width: fit-content;
	height: fit-content;
	position: relative;
}

.IndyBadge {
	position: absolute;
	top: 0.2rem;
	left: 1.8rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $pinkMap, $key: "600");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
	}
}
.ParentBadge {
	position: absolute;
	top: 0.2rem;
	left: 1.8rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $purpleMap, $key: "700");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
	}
}
.ChildBadge {
	position: absolute;
	top: 0.2rem;
	left: 1.8rem;
	min-width: 1.4rem;
	max-width: 1.4rem;
	min-height: 1.4rem;
	max-height: 1.4rem;
	border-radius: 50%;
	background-color: map-get($map: $tealMap, $key: "600");
	@include flex_row(center, center);

	&_label {
		font-size: 1.1rem;
		font-weight: 700;
		color: #ffffff;
		@include flex_row(center, center);
		text-align: center;
	}
}
