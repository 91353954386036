@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTableFooter {
	align-self: flex-end;
	@include fill_container;
	@include flex_row(flex-end, center);
	padding: 0.5rem 1rem;
	background-color: map-get($map: $blueGreyMap, $key: "900");
	border-radius: 0 0 1rem 1rem;

	&_addIcon {
		width: 2rem;
		height: 2rem;
	}
}

.AddRowButton {
	padding: 1rem 1.4rem;
	border-radius: 1rem;
	@include flex_row(center, center);
	background-color: map-get($map: $blueMap, $key: "600");
	border: none;
	outline: none;
	cursor: pointer;

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: #ffffff;
		margin-right: 0.3rem;
	}
	&_text {
		color: #ffffff;
		font-size: 1.6rem;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}
}
.ResetButton {
	padding: 1rem 1.4rem;
	border-radius: 1rem;
	@include flex_row(center, center);
	background-color: map-get($map: $redMap, $key: "600");
	border: none;
	outline: none;
	cursor: pointer;
	margin-right: 0.5rem;

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: #ffffff;
		margin-right: 0.3rem;
	}
	&_text {
		color: #ffffff;
		font-size: 1.6rem;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}
}
