@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResetPasswordByEmail {
	@include fill_container;
	padding: 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
		&_desc {
			margin-top: 0.5rem;
			width: 100%;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_email {
		width: 100%;
		height: auto;
		@include flex_col(center, center);

		&_label {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "400");
			align-self: flex-start;
		}

		&_inputWrapper {
			width: 100%;
			height: auto;
			@include flex_row(flex-start, flex-start);

			&_input {
				width: 100%;
				height: 3.5rem;
				border-radius: 0.5rem;
				outline: none;
				border: 1px solid #eaecef;
				margin-bottom: 2rem;
				font-size: 1.6rem;
				padding-left: 0.5rem;
				&:focus-within,
				&:hover {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
					cursor: pointer;
				}
				&::placeholder {
					color: map-get($map: $blueGreyMap, $key: "300");
				}
			}
			&_validIcon {
				width: 3.5rem;
				height: 3.5rem;
				fill: map-get($map: $greenMap, $key: "400");
				margin-left: 0.5rem;
				margin-bottom: 0.1rem;
				margin-right: auto;
				border-radius: 50%;
			}
		}
	}
	&_send {
		width: 100%;
		height: auto;

		&_sendBtn {
			width: 100%;
			padding: 1rem 1.7rem;
			border-radius: 0.5rem;
			background-color: map-get($map: $purpleMap, $key: "700");
			color: #ffffff;
			font-size: 1.7rem;
			font-weight: 600;
			border: none;
			outline: none;
			@include flex_row(center, center);
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
			&:disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
			&_icon {
				width: 2rem;
				height: 2rem;
				fill: #ffffff;
				margin-right: 0.7rem;
				// margin-left: 0.7rem;
			}
		}
	}
	&_wasSent {
		width: 100%;
		@include flex_row(center, center);

		&_wrapper {
			background-color: map-get($map: $purpleMap, $key: "600");
			padding: 1.4rem 2rem;
			border-radius: 5rem;
			text-align: center;

			&_msg {
				font-size: 1.8rem;
				font-weight: 600;
				color: #ffffff;
				margin-bottom: 1rem;
			}
			&_submsg {
				font-size: 1.6rem;
				font-weight: 500;
				color: #ffffff;
			}
		}
	}
}
