@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateNewQuestions {
	@include fill_container;
	width: 100%;
	@include flex_col(flex-start, center);

	&_heading {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "headings");
		text-align: left;
		margin-bottom: 2rem;
	}
	&_questions {
		width: 100%;
		padding: 0 1.5rem;

		&_item {
			padding-bottom: 2rem;
		}
	}
}

.QuestionNumber {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background-color: map-get($map: $blueMap, $key: "600");
	color: #ffffff;
	@include flex_row(center, center);
	font-size: 1.7rem;
	font-weight: 600;
	font-family: map-get($map: $fonts, $key: "altSans");
	margin-bottom: 1rem;
}
