@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCValidator {
	width: 100%;
	height: auto;

	&_title {
		padding: 2rem;
		padding-bottom: 1rem;
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}

	&_details {
		@include fill_container;

		&_msg {
			margin: 2rem 0;
			padding-left: 2rem;
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}

	&_actions {
		width: 100%;
		padding: 0.5rem 1rem;
		@include flex_row(flex-end, center);
	}
}

.LOCError {
	width: auto;

	&_msg {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}

.LOCErrorList {
	width: 100%;
	height: auto;
	padding: 2rem 2rem;

	&_msg {
		font-size: 1.8rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_entry {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
		padding-left: 4rem;
	}
}

.LOCErrorItem {
	width: auto;
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	font-size: 1.8rem;
	font-weight: 500;
	color: map-get($map: $blueGreyMap, $key: "600");

	&_icon {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
	}
}

.LOCOverlaps,
.LOCGaps {
	width: auto;

	&_msg {
		font-size: 1.8rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}

	&_list {
		width: auto;
	}
}
