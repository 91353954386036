@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessList {
	width: 100%;
	height: 45rem;
	list-style: none;
	overflow-y: auto;
	margin-top: 4rem;
	/* Background tracking */
	&::-webkit-scroll-track {
		background-color: #ffffff;
	}
	/* Sets width/height of 'thumb' & hides default */
	&::-webkit-scrollbar {
		appearance: none;
		-webkit-appearance: none;
		width: 0.8rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: map-get($map: $blueGreyMap, $key: "500");
		border-radius: 5rem;
	}

	&_loading {
		width: 100%;
		padding: 2rem;
		@include flex_row(center, center);
	}
}
