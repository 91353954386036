@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CustomResidentSearch {
	width: 100%;
	height: auto;

	&_main {
		width: 100%;
		height: auto;

		&_actions {
			margin-top: 4rem;
			margin-bottom: 4rem;
			height: auto;
			@include flex_row(flex-end, center);

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: #ffffff;
			}
		}
	}
	&_results {
		width: 100%;

		& span {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 2rem;

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
}

.ResidentSearchUI {
	width: 100%;
	margin-top: 5rem;
	padding-left: 4rem;

	&_fullName {
		width: 100%;
		@include flex_row(flex-start, center);

		&_first {
			margin-right: 1rem;
		}
		&_last {
			margin-left: 1rem;
		}
	}
	&_archive {
		width: auto;

		&_checkbox {
			margin-bottom: 2rem;
		}
		&_input {
			margin: 2rem 0;
		}
	}
}
