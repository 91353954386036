@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep3 {
	@include fill_container;
	width: 100%;
	padding: 0 2rem;
	@include flex_col(center, center);

	&_row {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		margin-bottom: 2rem;

		&_item {
			width: 50%;

			&:first-child {
				margin-right: 1rem;
			}
			&:last-child {
				margin-left: 1rem;
			}
		}
	}
	&_section {
		width: 100%;
		margin-bottom: 4rem;
	}
	&_unitTypes {
		width: 100%;
		height: auto;

		&_heading {
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "headings");
			margin-bottom: 1rem;
		}
	}
}

@media only screen and (max-width: 650px) {
	.CreateUserStep3 {
		@include fill_container;
		width: 100%;
		padding: 0 1rem;
		@include flex_col(center, center);

		&_row {
			width: 100%;
			height: auto;
			display: block;
			margin: 0 auto;
			margin-bottom: 2rem;

			&_item {
				width: 100%;

				&:first-child {
					margin-bottom: 1rem;
				}
				&:last-child {
					min-width: 100%;
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}
