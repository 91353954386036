@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ListItem {
	width: 100%;
	height: auto;
	max-height: 5rem;
	padding: 0.5rem 3rem;
	margin-bottom: 4rem;
	transition: all 0.3s ease-in-out;

	& a {
		@include flex_row(flex-start, center);
		text-decoration: none;
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueGreys, $key: "saturated");
		&:hover {
			cursor: pointer;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}

	& a:hover &_icon {
		cursor: pointer;
		fill: map-get($map: $colors, $key: "mainRed");
	}

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $blueGreys, $key: "saturated");
		margin-right: 0.5rem;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainRed");
		}
	}
}

.CollapsedListItem {
	width: 100%;
	height: auto;
	max-height: 5rem;
	padding: 0.5rem 0;
	margin-bottom: 4rem;
	transition: all 0.3s ease-in-out;

	& a {
		@include flex_row(center, center);
		text-decoration: none;
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueGreys, $key: "saturated");
		&:hover {
			cursor: pointer;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}
	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $blueGreys, $key: "saturated");
		margin-right: 0.5rem;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainRed");
		}
	}
}
