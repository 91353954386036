@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HintsContainer {
	width: 100%;
	max-width: 100vw;
	@include flex_col(center, center);
	&_hints {
		width: 100%;
		max-width: 100%;
		@include flex_col(center, center);
	}
}
