@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditFacilityAccess {
	@include fill_container;

	&_facilityAccess {
		width: 100%;
		height: 10rem;
	}
	&_grantAccess {
		width: 100%;
		@include flex_row(flex-start, center);
	}
	&_currentAccess {
		width: 100%;

		margin-top: 3rem;

		&_view {
			width: 100%;

			&_btn {
				font-size: 1.3rem;
				font-weight: 600;
				color: map-get($map: $purpleMap, $key: "700");
				background-color: transparent;
				border: none;
				outline: none;
				&:hover {
					cursor: pointer;
					color: map-get($map: $blueMap, $key: "600");
				}
			}
			&_noAccess {
				font-size: 1.3rem;
				font-weight: 600;
				color: map-get($map: $redMap, $key: "600");
				padding-left: 1rem;
				margin-top: 2rem;
			}
		}
		&_list {
			width: 100%;
			height: 15rem;
			overflow-y: auto;
			padding: 1rem 2rem;

			&_item {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "600");
				margin-bottom: 0.5rem;
			}
		}
	}
	&_actions {
		width: 30rem;
		height: auto;
		margin-top: 4rem;
		@include flex_row(flex-end, center);
	}
}

.AccessSummary {
	width: 100%;
	height: auto;
	margin-top: 2rem;

	&_summary {
		font-size: 1.5rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $redMap, $key: "600");
		& b {
			color: map-get($map: $purpleMap, $key: "700");
		}
	}
}
.CurrentAccess {
	width: 100%;
	height: auto;
	margin-top: 2rem;

	&_summary {
		font-size: 1.5rem;
		font-weight: 500;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "500");
		& b {
			color: map-get($map: $blueMap, $key: "700");
		}
	}
}

.Success {
	margin-top: 2rem;
	padding: 1.2rem 1.2rem;
	border-radius: 5rem;
	background-color: map-get($map: $purpleMap, $key: "700");
	width: fit-content;
	height: fit-content;
	@include flex_row(center, center);
}
