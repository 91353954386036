@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PasswordSuccess {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include flex_col(center, center);
	background-color: #ffffff;
	border-radius: 0.5rem;
	z-index: 20;

	&_icon {
		width: 10rem;
		height: 10rem;
		fill: map-get($map: $purpleMap, $key: "600");
	}

	&_messages {
		width: 100%;
		@include flex_col(flex-start, center);

		&_msg {
			font-size: 4rem;
			font-weight: 600;
			color: map-get($map: $greenMap, $key: "500");
			margin-bottom: 0.5rem;
			text-align: center;
		}
		&_msg2 {
			font-size: 2.5rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "600");
			text-align: center;
		}
		&_login {
			padding: 1.6rem 1.7rem;
			border-radius: 5rem;
			background-color: map-get($map: $purpleMap, $key: "600");
			margin-top: 2rem;
			font-size: 1.8rem;
			font-weight: 600;
			color: #ffffff;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "purpleRain");
			}
		}
	}
}
