@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSelectorOption {
	width: 100%;
	height: auto;
	padding: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1.4rem;
	font-weight: 400;
	@include flex_row(space-between, center);
	margin-bottom: 1.2rem;

	&:hover {
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
		cursor: pointer;
	}

	&_text {
		width: fit-content;
		height: fit-content;
		@include flex_col(center, flex-start);

		&_name {
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_email {
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_isSelected {
		@extend .UserSelectorOption;
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
	}

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		min-width: 1.6rem;
		min-height: 1.6rem;
		fill: map-get($map: $colors, $key: "main");
	}
}
