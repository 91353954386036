@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DeleteUserButton {
	position: absolute;
	top: -5rem;
	right: 0;
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	@include flex_row(center, center);
	background-color: map-get($map: $redMap, $key: "600");
	border: none;
	outline: none;
	box-shadow: map-get($map: $shadows, $key: "heavySubtle");
	transition: all 0.1s ease-in-out;
	&:hover {
		cursor: pointer;
	}

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $redMap, $key: "100");
	}
	&_label {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "100");
		margin-right: 0.5rem;
		transition: all 0.5s ease-in-out;
	}
}

.DeleteUserButtonLabel {
	position: absolute;
	top: -5rem;
	right: 0;
	width: 12rem;
	height: 5rem;
	border-radius: 5rem;
	@include flex_row(center, center);
	background-color: map-get($map: $redMap, $key: "600");
	border: none;
	outline: none;
	box-shadow: map-get($map: $shadows, $key: "heavySubtle");
	&:hover {
		cursor: pointer;
	}

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 5rem;
		fill: map-get($map: $redMap, $key: "100");
	}
	&_label {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "100");
		margin-right: 0.5rem;
		transition: all 0.5s ease-in-out;
	}
}
