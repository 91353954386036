@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateTableHeading {
	@include fill_container;
	@include flex_row(center, center);
	border-right: 1px solid map-get($map: $blueGreyMap, $key: "500");
	border-bottom: 4px solid map-get($map: $blueGreyMap, $key: "500");
	background-color: map-get($map: $blueGreyMap, $key: "300");

	&:last-child {
		border-right: none;
	}

	&_column {
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "800");
	}
	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $blueGreyMap, $key: "700");
	}
	&:hover &_column {
		color: map-get($map: $purpleMap, $key: "700");
		cursor: pointer;
	}
	&:hover &_icon {
		fill: map-get($map: $purpleMap, $key: "700");
		cursor: pointer;
	}
}
