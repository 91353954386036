@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationPanelHeader {
	width: 100%;

	&_top {
		width: 100%;
		height: auto;
		&_title {
			font-size: 3rem;
			font-weight: 800;
			color: map-get($map: $purpleMap, $key: "700");
		}
		&_desc {
			margin-top: 1rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 2rem;

			& > b {
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
}

.MigrationAppsList {
	width: auto;
	height: auto;
	padding: 0.5rem 2rem;

	&_list {
		width: auto;

		&_app {
			margin: 0.3rem 0;
		}
	}
}
.AppItem {
	margin: 0.3rem 0;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $purpleMap, $key: "700");
}

@media only screen and (max-width: 700px) {
	.MigrationPanelHeader {
		width: 100%;

		&_top {
			width: 100%;
			height: auto;

			&_title {
				font-size: 2.2rem;
				font-weight: 800;
				color: map-get($map: $purpleMap, $key: "700");
			}
			&_desc {
				margin-top: 1rem;
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
				margin-bottom: 2rem;
			}
		}
	}
}
