@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessActions {
	margin: 2rem 0;
	width: 100%;
	height: auto;
	@include flex_row(space-between, center);

	&_search {
		position: relative;
		width: 40%;
		min-width: 30rem;
		height: auto;
		padding: 0 0.5rem;
		@include flex_row(flex-start, center);
		border-radius: 5rem;
		background-color: #eaecef;
		&:focus-within,
		&:hover {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&_input {
			width: 100%;
			background-color: #eaecef;
			border: none;
			outline: none;
			border-radius: 5rem;
			height: 3.5rem;
			padding-left: 1rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $colors, $key: "mainBlackBlue");
		}

		&_icon {
			width: 1.4rem;
			height: 1.4rem;
			fill: map-get($map: $blueGreys, $key: "headings");
			margin-left: 0.5rem;
		}
		&_clear {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 50%;
			background-color: map-get($map: $redMap, $key: "200");
			border: none;
			outline: none;
			@include flex_row(center, center);
			&:focus-within,
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $redMap, $key: "600");
			}

			&:focus-within &_icon,
			&:hover &_icon {
				cursor: pointer;
				fill: #ffffff;
			}

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $redMap, $key: "700");
			}
		}
	}
	&_customSearch {
		width: max-content;
		margin-top: 1rem;
		padding-left: 4rem;
		text-align: center;

		&_button {
			padding: 1rem 1.4rem;
			border-radius: 5rem;
			color: map-get($map: $purpleMap, $key: "600");
			font-size: 1.5rem;
			font-weight: 500;
			border: none;
			outline: none;
			background-color: transparent;
			&:hover {
				cursor: pointer;
				background-color: map-get($map: $purpleMap, $key: "600");
				color: #ffffff;
			}
		}
	}
}
