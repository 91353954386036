@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SuccessMsg {
	width: 100%;
	display: block;
	margin: 0 auto;
	height: auto;
	min-height: 30rem;
	max-height: 100%;
	text-align: center;
	@include flex_col(center, center);

	&_badge {
		width: 8rem;
		height: 8rem;
		@include flex_row(center, center);
		border-radius: 50%;
		background-color: map-get($map: $greenMap, $key: "500");
		box-shadow: map-get($map: $hoverShadows, $key: "bright");
		margin-bottom: 1rem;

		&_icon {
			width: 6rem;
			height: 6rem;
			fill: map-get($map: $blueGreyMap, $key: "50");
			// fill: map-get($map: $greenMap, $key: "400");
		}
	}
	&_msgWrapper {
		margin-top: 0.5rem;
		width: 100%;
		height: auto;
		@include flex_row(center, center);

		&_msg {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $greenMap, $key: "500");
			font-family: map-get($map: $fonts, $key: "mainSans");
		}
	}
}
