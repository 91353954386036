@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilitySummary {
	width: auto;
	margin-top: 4rem;

	&_name {
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_address {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		font-family: map-get($map: $fonts, $key: "altSans");
	}
	&_residents {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		font-family: map-get($map: $fonts, $key: "altSans");

		& > b {
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
}
