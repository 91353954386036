@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserStep1 {
	@include fill_container;
	width: 100%;
	display: block;
	margin: 0 auto;

	&_desc {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 3rem;
	}
}

.UserTypeDesc {
	width: 100%;
	height: auto;
	display: block;
	margin-top: 2rem;

	&_name {
		font-size: 1.6rem;
		font-weight: 800;
		color: map-get($map: $purpleMap, $key: "700");
	}
	&_desc {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
}
