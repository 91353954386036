@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCHelpButton {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background-color: map-get($map: $blueMap, $key: "600");
	border: none;
	outline: none;
	@include flex_row(center, center);
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "blueOutline");
	}

	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: #ffffff;
	}
}
