@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MigrationToolPage {
	@include fill_container;

	&_content {
		width: auto;
		@include flex_row(flex-start, center);
		margin-top: 2rem;
		margin-bottom: 6rem;

		&_icon {
			width: 8rem;
			height: 8rem;
			fill: map-get($map: $blueGreyMap, $key: "600");
			margin: 0 0.5rem;
		}
	}
}

.MigrateIcon {
	width: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 8rem;
		height: 8rem;
		fill: map-get($map: $blueGreyMap, $key: "600");
		margin: 0 0.5rem;
	}
}
