@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RedirectedNotice {
	position: fixed;
	bottom: 2rem;
	left: 50%;
	width: 80%;
	margin-left: -40%;
	height: auto;
}

@media only screen and (max-width: 650px) {
	.RedirectedNotice {
		bottom: 2rem;
		left: 50%;
		width: 100%;
		max-width: 100vw;
		margin-left: -50%;
	}
}
