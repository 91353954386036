@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SkipButton {
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	background-color: transparent;
	color: map-get($map: $purpleMap, $key: "600");
	font-size: 1.6rem;
	font-weight: 600;
	border: none;
	outline: none;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}
	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
}
