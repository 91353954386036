@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AccountUnlockSwitch {
	width: auto;
	padding: 2rem;

	&_switch {
		width: auto;

		&_btn {
			padding: 0.8rem 1.6rem;
			border-radius: 5rem;
			background-color: map-get($map: $redMap, $key: "600");
			color: map-get($map: $redMap, $key: "100");
			@include flex_row(center, center);
			font-size: 1.7rem;
			font-weight: 600;
			border: none;
			outline: none;
			box-shadow: map-get($map: $shadows, $key: "light");
			&:hover {
				cursor: pointer;
			}

			&_icon {
				width: 2.5rem;
				height: 2.5rem;
				fill: map-get($map: $redMap, $key: "100");
				margin-right: 0.5rem;
			}
		}
	}
}
