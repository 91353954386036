@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ValidateOTP {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 55rem;
	height: 40rem;
	margin-top: -20rem;
	margin-left: -22.5rem;
	padding: 1rem 2rem;
	background-color: #fff;
	border-radius: 1rem;
	box-shadow: map-get($map: $shadows, $key: "focusMode");

	&_top {
		position: relative;
		width: 100%;
		height: auto;
		padding: 1rem 0;

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_close {
			position: absolute;
			top: -0.3rem;
			right: -0.3rem;

			&_icon {
				width: 2rem;
				height: 2rem;
				fill: map-get($map: $redMap, $key: "600");
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	&_fields {
		margin-top: 4rem;
		width: 100%;
		height: 40%;
		@include flex_row(space-evenly, center);
	}
	&_actions {
		width: 100%;
		height: 15rem;

		&_resend {
			margin-top: 2rem;
			width: 100%;
			@include flex_row(center, flex-start);

			&_btn {
				color: map-get($map: $purpleMap, $key: "700");
				border: none;
				outline: none;
				background-color: transparent;
				font-size: 1.3rem;
				font-weight: 500;
				margin-left: 1rem;
			}
			&_msg {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
}

.VerifyButton {
	width: 100%;
	padding: 1.3rem 1.4rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $purpleMap, $key: "600");
	color: #ffffff;
	font-size: 1.7rem;
	font-weight: 600;
	border: none;
	outline: none;
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
}

.ResendAction {
	margin-top: 2rem;
	width: 100%;
	@include flex_row(center, flex-start);

	&_btn {
		color: map-get($map: $purpleMap, $key: "700");
		border: none;
		outline: none;
		background-color: transparent;
		font-size: 1.3rem;
		font-weight: 500;
		margin-left: 1rem;
	}
	&_msg {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

@media only screen and (max-width: 570px) {
	.ValidateOTP {
		width: 97vw;
		height: 50vh;
		margin-left: -48.5vw;
		margin-top: -25vh;
	}
}
