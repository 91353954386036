@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityNotificationsPanel {
	width: 100%;
	height: auto;
	margin-bottom: 4rem;

	&_section {
		width: 100%;
		height: auto;
		&_main {
			width: 100%;
			max-width: 60rem;
		}
	}
}
