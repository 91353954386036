@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAccess {
	@include fill_container;
	min-height: 55rem;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_accessCount {
			margin-top: 1rem;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueMap, $key: "600");
			& > b {
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_selector {
		width: 100%;
		height: auto;

		&_actions {
			margin-top: 4rem;
			@include flex_row(flex-start, center);
		}
	}
	&_list {
		width: 100%;

		&_label {
			margin-top: 4rem;
			font-size: 2rem;
			font-weight: 600;
		}
	}
}
