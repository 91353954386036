@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityInfo {
	@include fill_container;

	&_header {
		width: auto;
		height: auto;

		&_title {
			width: max-content;
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_subtitle {
			@include flex_row(flex-start, center);
			width: max-content;
			font-size: 1.4rem;
			font-weight: 500;
			font-family: "Open Sans", "Roboto", "Arial", sans-serif;
			color: map-get($map: $blueGreyMap, $key: "400");

			& > b {
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "500");
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
	&_main {
		width: 100%;
		height: auto;
		margin-bottom: 10rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
			margin-bottom: 3rem;
		}
	}
}
