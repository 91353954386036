@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.HomePageMessages {
	width: 100%;
	height: auto;
	padding: 0 10rem;
	@include flex_col(center, center);
}

@media only screen and (max-width: 1000px) {
	.HomePageMessages {
		width: 100%;
		height: auto;
		padding: 0 5rem;
		@include flex_col(center, center);
	}
}
@media only screen and (max-width: 800px) {
	.HomePageMessages {
		width: 100%;
		height: auto;
		padding: 0 5rem;
		@include flex_col(center, center);
	}
}
@media only screen and (max-width: 650px) {
	.HomePageMessages {
		width: 100%;
		height: auto;
		padding: 0 5rem;
		@include flex_col(center, center, wrap);
	}
}
