@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateDescInput {
	width: 70rem;
	height: auto;
	display: block;
	margin: 0 auto;
	margin-bottom: 2rem;
	@include flex_row(center, center);
}
