@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserProfileActions {
	width: 100%;
	height: auto;
	margin-bottom: 5rem;
	@include flex_row(space-between, center, wrap);

	&_left {
		@include flex_row(flex-start, center);
	}
	&_right {
		margin-left: auto;
		@include flex_row(flex-end, center);
	}
}
