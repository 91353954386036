@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCApiValidatorResults {
	width: 100%;
	height: auto;

	&_title {
		padding: 2rem;
		padding-bottom: 1rem;
		font-size: 1.7rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
	&_issues {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}

	&_details {
		@include fill_container;

		&_msg {
			margin: 2rem 0;
			padding-left: 2rem;
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}

.LOCError {
	width: auto;

	&_msg {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
}

.ErrorList {
	width: 100%;
	height: auto;
	padding: 2rem 2rem;

	&_msg {
		font-size: 1.8rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
	}
	&_entry {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
		padding-left: 4rem;
	}
}

.ErrorItem {
	width: auto;
	height: auto;
	@include flex_row(flex-start, center);
	margin: 1rem 0;
	font-size: 1.8rem;
	font-weight: 500;
	// font-family: map-get($map: $fonts, $key: "altSans");
	color: map-get($map: $blueGreyMap, $key: "600");

	&_msg {
		font-size: 1.8rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
		word-wrap: break-word;
	}

	&_icon {
		width: 3rem;
		min-width: 3rem;
		height: 3rem;
		min-height: 3rem;
		fill: map-get($map: $redMap, $key: "600");
		align-self: baseline;
		margin-right: 1rem;
	}
}

.SuccessMsg {
	width: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		fill: map-get($map: $greenMap, $key: "500");
		margin-right: 1rem;
	}
	&_msg {
		font-size: 2rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "700");

		& > b {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
}
