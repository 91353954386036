@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserFacilityAccess {
	width: 100%;

	&_count {
		width: 100%;

		&_value {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");

			& > b {
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_main {
		@include fill_container;

		&_toggleBtn {
			border: none;
			outline: none;
			padding: 0.6rem 1.2rem;
			border-radius: 0.5rem;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "700");
			cursor: pointer;
			background-color: map-get($map: $purpleMap, $key: "50");
			margin-bottom: 1rem;
		}
	}
	&_list {
		width: 100%;
		height: 50rem;

		&_search {
			width: 100%;
			margin-top: 1rem;

			&_input {
				width: 25rem;
				height: 3rem;
				border-radius: 0.5rem;
				border: none;
				outline: none;
				padding-left: 0.5rem;
				&:focus-within,
				&:hover {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
					cursor: pointer;
				}
				&::placeholder {
					color: map-get($map: $blueGreyMap, $key: "300");
				}
			}
		}
	}
}
