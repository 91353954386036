@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.OfflineIndicator {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -0%);
	width: 45rem;
	max-width: 100vw;
	height: 18rem;
	padding: 1rem;
	background-color: map-get($map: $redMap, $key: "600");
	border-radius: 1rem;
	z-index: 10;
	box-shadow: map-get($map: $shadows, $key: "pronounced");

	&_top {
		width: 100%;
		height: 1.8rem;
		@include flex_row(flex-end, center);

		&_icon {
			width: 1.8rem;
			width: 1.8rem;
			fill: #ffffff;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&_wrapper {
		width: 100%;
		height: auto;
		// background-color: #ffffff;
		@include flex_row(center, flex-start);

		&_icon {
			width: 8rem;
			height: 8rem;
			fill: #ffffff;
		}
	}
	&_message {
		width: 100%;
		height: 100%;
		@include flex_col(flex-start, center);

		&_title {
			font-weight: 600;
			color: #ffffff;
			font-size: 2.5rem;
		}
		&_subtitle {
			font-weight: 500;
			color: #ffffff;
			font-size: 1.6rem;
		}
	}
}
