@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.YourAppsPage {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		@include flex_row(center, center);

		&_title {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_apps {
		width: 100%;
		height: auto;
		@include flex_row(space-evenly, flex-start, wrap);
	}
}
