@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EmarAppLogin {
	position: relative;
	width: 20rem;
	height: 15rem;
	border-radius: 0.5rem 0.5rem;
	@include flex_col(center, center);
	margin: 0 1rem;
	margin-bottom: 10rem;
	background-color: transparent;
	outline: none;
	border: none;
	background-color: #ffffff;
	border-top: 5px solid map-get($map: $brandColors, $key: "primary");
	// background-color: #3a78b6;
	z-index: 1;

	&_logo {
		width: 8.5rem;
		height: 8.5rem;
		border-radius: 50%;
		margin-bottom: 0.3rem;
	}

	&_name {
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $brandColors, $key: "secondary");
		text-decoration: underline map-get($map: $brandColors, $key: "primary");
		text-decoration-thickness: 2px;
	}
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	&:hover &_icon {
		fill: map-get($map: $brandColors, $key: "primary");
	}
	&:hover &_name {
		color: map-get($map: $brandColors, $key: "primary");
	}
	&:hover {
		@include brandBlueShadow;
	}
}
.AccessDisabledReason {
	position: absolute;
	top: 105%;
	left: 0.5rem;
	width: fit-content;
	height: fit-content;
	max-width: 20rem;
	max-height: 8rem;
	@include flex_col(center, flex-start);
	text-align: center;
	z-index: 1;

	&_text {
		width: 100%;
		text-align: center;
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
	}
	&_cta {
		width: 100%;
		text-align: center;
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $blueMap, $key: "600");
	}
}
.EmarFacilitySelector {
	width: auto;
	@include flex_row(flex-start, center);
	margin-right: auto;
}
.EmarActions {
	width: auto;
	@include flex_row(flex-end, center);
	margin-left: auto;
}
