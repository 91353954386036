@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MultiStepCounter {
	width: 100%;
	height: auto;
	@include flex_row(center, center);
}

.StepIndicator {
	width: 1.8rem;
	height: 1.8rem;
	border-radius: 50%;
	background-color: #eaecef;
	border: none;
	outline: none;
	margin: 0 0.5rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}

	&_isActive {
		@extend .StepIndicator;
		background-color: map-get($map: $colors, $key: "main");
	}
	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
}
