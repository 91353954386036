@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.removeItem {
	opacity: 0;
	transition: opacity 1.2s ease-out;
}

.LOCTemplateTableRow {
	width: 100%;
	height: 5rem;
	border-bottom: 1px solid map-get($map: $blueGreyMap, $key: "400");
	position: relative;

	&_inner {
		@include fill_container;
		@include flex_row(space-evenly, center);
	}

	&:nth-child(even) {
		background-color: map-get($map: $blueGreyMap, $key: "300");
	}
}
.LOCTemplateTableRow:hover .DeleteButton {
	display: block;
}

.DeleteButton {
	position: absolute;
	@include flex_row(center, center);
	display: none;
	top: 1.3rem;
	left: 95%;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: map-get($map: $redMap, $key: "600");
	border: none;
	outline: none;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavy");
	}
	&_icon {
		width: 1.7rem;
		height: 1.7rem;
		fill: #ffffff;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}
}
