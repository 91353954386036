@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Steps {
	width: auto;

	&_list {
		list-style: decimal;
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}

.Step {
	font-size: 1.3rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "mainSans");
	color: map-get($map: $blueGreyMap, $key: "600");
}

.LOCTip {
	width: 100%;
	height: auto;
	padding: 2rem 1rem;

	&_top {
		width: 100%;
		margin-bottom: 2rem;
		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
			text-decoration: underline;
			margin-bottom: 0.5rem;
		}
		&_desc {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_steps {
		width: 100%;
		padding: 1rem 2rem;
	}
	&_label {
		font-size: 1.2rem;
		color: map-get($map: $blueGreyMap, $key: "400");
	}
	&_gif {
		width: 100%;
		height: auto;
		padding: 1rem 10rem;
		max-height: 15rem;
		margin-bottom: 15rem;
	}
}
