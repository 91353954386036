@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EnabledIndicator {
	width: max-content;
	height: auto;
	@include flex_row(flex-start, center);

	&_icon {
		width: 2.2rem;
		height: 2.2rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
	&_label {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $greenMap, $key: "500");
		margin-left: 0.5rem;
	}
}
