@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserForm {
	width: 100%;
	max-width: 90rem;
	max-height: 100%;
	@include flex_col(center, center);
	height: 100%;
	padding: 0.5rem 2rem;
	border-radius: 1rem;
	background-color: transparent;

	&_main {
		@include fill_container;
		max-width: 100%;
		max-height: 100%;
		padding-top: 0;

		&_step1 {
			width: 100%;
			@include flex_row(center, flex-start);
		}
	}
	&_confirmDialog {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

@media only screen and (max-width: 650px) {
	.CreateUserForm {
		@include fill_container;
		padding: 0.5rem 1rem;
	}
}
