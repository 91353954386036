@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DropdownOption {
	width: 100%;
	padding: 1.2rem 1rem;
	font-size: 1.4rem;
	font-weight: 500;
	@include flex_row(flex-start, center);
	color: map-get($map: $blueGreys, $key: "headings");
	margin-bottom: 1.5rem;
	outline: none;

	&:hover &_label {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
	}

	&:hover {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}
	&:focus,
	&:focus-visible,
	&:focus-within {
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}

	&_isSelected {
		@extend .DropdownOption;
		cursor: pointer;
		color: map-get($map: $colors, $key: "main");
		background-color: map-get($map: $greys, $key: "chalk");
	}
	&_isDisabled {
		@extend .DropdownOption;
		opacity: 0.5;
		cursor: not-allowed;
		&:hover {
			cursor: not-allowed;
		}
		& span {
			cursor: not-allowed;
		}
	}
	&_checked {
		margin-left: auto;
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $colors, $key: "main");
	}
}
