@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCValidatorSummary {
	width: 100%;
	height: auto;
	padding: 2rem;
	border-bottom: 2px solid #ccc;
	margin-bottom: 2rem;

	&_tableName {
		width: 100%;
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_details {
		width: 100%;
		height: auto;
	}
}
