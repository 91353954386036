@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Illustration {
	display: block;
	margin: 2rem auto;
	width: 50rem;
	height: 60rem;

	&_img {
		@include fill_container;
	}
}

@media only screen and (max-width: 550px) {
	.Illustration {
		width: 100%;
		max-width: 100vw;
		height: 35rem;
	}
}
