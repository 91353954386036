@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentsTableRow {
	position: relative;
	width: 100%;
	height: 5.5rem;
	padding: 0.5rem 1rem;
	z-index: 1;

	&_inner {
		@include flex_row(space-evenly, center);
		@include fill_container;

		&_name {
			@include flex_row(flex-start, center);
			width: 25%;
			height: 100%;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "800");
			&:hover {
				cursor: pointer;
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
		&_age {
			@include flex_row(center, center);
			width: 25%;
			height: 100%;
			font-size: 1.5rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_unitType {
			@include flex_row(center, center);
			width: 25%;
			height: 100%;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_archive {
			width: 25%;
			height: 100%;
			font-size: 1.5rem;
			font-weight: 600;
			@include flex_row(flex-end, center);
		}
	}

	&:nth-child(odd) {
		border: 1px solid map-get($map: $blueGreyMap, $key: "200");
		border-left: none;
		border-right: none;
	}
}

.UnarchiveButton {
	padding: 0.5rem 1.3rem;
	background-color: transparent;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $redMap, $key: "600");
	color: map-get($map: $redMap, $key: "700");
	outline: none;
	font-size: 1.4rem;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $redMap, $key: "600");
		color: #ffffff;
	}
}
.ArchiveButton {
	padding: 0.5rem 1.3rem;
	background-color: map-get($map: $greenMap, $key: "500");
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $greenMap, $key: "500");
	color: #ffffff;
	outline: none;
	font-size: 1.4rem;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $greenMap, $key: "500");
		color: #ffffff;
	}
}
