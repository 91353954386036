@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.OTPPreviewer {
	width: auto;
	height: auto;
	padding-bottom: 4rem;

	&_label {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}

	&_value {
		width: 100%;
		@include flex_row(flex-start, center);

		&_hidden {
			width: max-content;
			min-width: 15rem;
			min-height: 3rem;
			border-radius: 0.5rem;
			padding: 0.5rem 0.8rem;
			padding-right: 2rem;
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			background-color: #eaecef;
		}
		&_visible {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			letter-spacing: 0.22rem;
		}

		&_toggle {
			width: max-content;
			@include flex_row(flex-start, center);
			margin-left: 1rem;
			&:hover {
				cursor: pointer;
			}
			&:hover &_label {
				cursor: pointer;
				color: map-get($map: $blueGreyMap, $key: "800");
			}
			&:hover &_icon {
				fill: map-get($map: $blueGreyMap, $key: "800");
				cursor: pointer;
			}

			&_label {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
			&_icon {
				width: 1.8rem;
				height: 1.8rem;
				fill: map-get($map: $blueGreyMap, $key: "500");
				margin-right: 0.5rem;
			}
		}
	}
	&_copy {
		width: max-content;
		@include flex_row(flex-start, center);
		margin-top: 0.5rem;

		&:hover {
			cursor: pointer;
		}
		&:hover &_label {
			color: map-get($map: $purpleMap, $key: "700");
			cursor: pointer;
		}
		&:hover &_icon {
			fill: map-get($map: $purpleMap, $key: "700");
			cursor: pointer;
		}

		&_label {
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-left: 0.3rem;
		}

		&_icon {
			width: 1.5rem;
			height: 1.5rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
