@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateTableBody {
	width: 100%;
	height: 50rem;
	background-color: #ffffff;
	border: none;

	&:disabled {
		opacity: 0.7;
	}

	&_body {
		@include fill_container;
		overflow-y: auto;

		/* Background Tracking */
		&::-webkit-scroll-track {
			background-color: #ffffff;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.7rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "500");
			border-radius: 5rem;
		}
	}
}
