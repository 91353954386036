@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentsTableColumn {
	@include fill_container;
	@include flex_row(center, center);

	&:hover {
		cursor: pointer;
	}

	&_inner {
		@include fill_container;
		@include flex_row(center, center);

		&_column {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "100");
		}
		&_icon {
			width: 1.7rem;
			height: 1.7rem;
			fill: map-get($map: $blueGreyMap, $key: "100");
			margin-left: 0.3rem;
		}
		&:nth-child(1) {
			justify-self: flex-start;
		}
		&:nth-child(4) {
			// justify-self: flex-end;
			margin-left: auto;
		}
	}
}
