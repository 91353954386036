.slide-in-right {
	-webkit-animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		both;
	animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@mixin slide_from_right {
	@extend .slide-in-right;
}

.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-fwd-center {
	0% {
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}
@keyframes slide-in-fwd-center {
	0% {
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}

@mixin slide_in_center {
	@extend .slide-in-fwd-center;
}

.pulse {
	-webkit-animation: pulse 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 4 forwards;
	animation: pulse 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 4 forwards;
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@mixin pulse {
	@extend .pulse;
}

@keyframes beacon {
	0% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		z-index: inherit;
	}
	70% {
		-webkit-box-shadow: 0 0 0 30px hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 10px hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
}
.beacon {
	-webkit-animation: beacon 0.8s 20 forwards;
	animation: beacon 0.8s 20 forwards;
	z-index: inherit;
}
@mixin beacon {
	@extend .beacon;
}

@keyframes customFlash {
	0% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0.4);
		z-index: inherit;
	}
	70% {
		-webkit-box-shadow: 0 0 0 30px hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 10px hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		box-shadow: 0 0 0 0 hsla(352, 70%, 50%, 0);
		z-index: inherit;
	}
}
.customFlash {
	-webkit-animation: customFlash 0.8s 20 forwards;
	animation: customFlash 0.8s 20 forwards;
	z-index: inherit;
}
@mixin customFlash {
	@extend .customFlash;
}
