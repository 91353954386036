@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTemplateController {
	@include fill_container;

	&_top {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
			@include flex_row(flex-start, center);
		}
		&_desc {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		padding: 0 0;
		border-radius: 1rem;
		// border: 1px solid #ccc;

		&_typeSelector {
			width: 100%;
			padding-bottom: 2rem;
			margin-bottom: 6rem;
			// border-bottom: 2px solid #ccc;
			@include flex_row(center, center);
		}
	}
}

.FacilityNotice {
	width: 100%;
	height: auto;
	margin: 2rem 0;
	@include flex_row(center, center);

	&_msg {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $purpleMap, $key: "700");
	}
}

.FacilityIndicator {
	width: auto;
	align-self: center;
	font-size: 1.8rem;
	font-weight: 600;
	color: map-get($map: $purpleMap, $key: "600");
	margin-left: 1rem;
}
