@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LockoutIcon {
	width: auto;
	height: auto;
	border-radius: 50%;

	&_icon {
		width: 2rem;
		height: 2rem;
		fill: map-get($map: $redMap, $key: "500");
		border-radius: 50%;
	}
}
