@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoadingMsg {
	width: auto;
	height: auto;
	@include flex_col(center, center);

	&_loader {
		@include fill_container;
		@include flex_row(center, center);
	}
	&_msg {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
		@include flex_row(center, center);
	}
}
