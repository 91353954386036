@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

$base: map-get(
	$map: $redMap,
	$key: "400",
);
$alt: map-get(
	$map: $redMap,
	$key: "200",
);

.AnchorLink {
	width: auto;
	font-size: 1.5rem;
	font-weight: 500;
	color: map-get($map: $redMap, $key: "400");
	text-decoration: none;
	@include hoverEffect($base, $alt);
	&:hover {
		cursor: pointer;
	}
}
