@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FeedbackWidgetStep {
	@include fill_container;
	@include flex_row(center, flex-start);
	position: relative;
	z-index: 100;

	&_back {
		width: 1.8rem;
		height: 1.8rem;
		position: absolute;
		top: -3.5rem;
		left: 2rem;
		fill: map-get($map: $blueGreyMap, $key: "400");
		&:hover {
			cursor: pointer;
		}
	}
}
