@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAccessEntry {
	width: 100%;
	height: auto;
	padding: 0.5rem 1rem;
	background-color: #ffffff;
	border-radius: 1rem;
	margin: 0.5rem 0;
	@include flex_row(space-between, center);

	&_facility {
		width: max-content;
		height: 100%;
		@include flex_row(flex-start, center);

		&_badge {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			background-color: map-get($map: $blueGreyMap, $key: "100");
			@include flex_col(center, center);
			margin-right: 0.5rem;
			box-shadow: map-get($map: $shadows, $key: "light");

			&_icon {
				margin-top: 0.3rem;
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $blueGreyMap, $key: "700");
				&:hover {
					cursor: pointer;
					fill: map-get($map: $purpleMap, $key: "700");
				}
			}
		}
		&_desc {
			@include flex_col(flex-start, flex-start);

			&_name {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
			&_location {
				font-size: 1.2rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
	&_actions {
		width: max-content;
		height: 100%;

		&_icons {
			position: relative;
			@include fill_container;
			@include flex_row(flex-end, center);

			&_icon {
				width: 1.7rem;
				height: 1.7rem;
				fill: map-get($map: $blueGreyMap, $key: "500");
				margin: 0 0.5rem;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $redMap, $key: "600");
				}
			}
		}
	}

	&_confirmModal {
		@include fill_container;
		@include flex_row(flex-end, center);
	}
}

.MenuOptions {
	position: absolute;
	top: -3rem;
	right: 0;
	width: 18rem;
	height: 10rem;
	border-radius: 1rem;
	background-color: #ffffff;
	box-shadow: map-get($map: $shadows, $key: "medium");
	z-index: 100;

	&_top {
		width: 100%;
		height: 2.5rem;
		padding: 0 0.5rem;
		@include flex_row(flex-end, center);

		&_close {
			width: 1.3rem;
			height: 1.3rem;
			fill: map-get($map: $redMap, $key: "600");
			cursor: pointer;
		}
	}
	&_list {
		@include fill_container;
		padding: 1rem;
		list-style: none;

		&_item {
			width: 100%;
			padding: 0.3rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin-bottom: 0.5rem;
			// hover effect local variables
			$base: map-get(
				$map: $purpleMap,
				$key: "700",
			);
			$hover: map-get(
				$map: $purpleMap,
				$key: "300",
			);
			@include hoverEffect($base, $hover);

			&:hover {
				cursor: pointer;
				color: map-get($map: $purpleMap, $key: "700");
			}
		}
	}
}

.ChangeNotice {
	width: fit-content;
	height: 3.5rem;
	padding: 2rem 2rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $purpleMap, $key: "600");
	box-shadow: map-get($map: $shadows, $key: "medium");
	margin: 2rem 0;

	&_inner {
		@include fill_container;
		@include flex_row(flex-start, center);

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $blueGreyMap, $key: "200");
			margin-right: 0.5rem;
		}
		&_msg {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "200");
		}
	}
}
