@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ArchiveStatus {
	width: auto;
	font-size: 1.5rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
}

.ActiveStatus {
	width: auto;
	font-size: 1.5rem;
	font-weight: 600;
	color: map-get($map: $greenMap, $key: "500");
}

.ResidentStatus {
	width: 100%;
	height: auto;
	padding-left: 1rem;
}
