@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSearchDetails {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
		&_id {
			@include flex_row(flex-start, center);
			width: 100%;
			margin-top: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-size: 1.6rem;
			font-weight: 600;
		}
	}
	&_desc {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}
	&_deleteIcon {
		width: 1.8rem;
		height: 1.8rem;
		fill: #ffffff;
		margin-right: 0.3rem;
	}
}

.DeletedIndicator {
	width: auto;
	@include flex_row(flex-start, center);
	margin-top: 1rem;

	&_msg {
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $redMap, $key: "600");
		margin-right: 1rem;
	}
	&_bubble {
		width: 12rem;
		height: auto;
		padding: 0.7rem 1rem;
		border-radius: 5rem;
		background-color: map-get($map: $redMap, $key: "600");
		@include flex_row(center, center);
		// box-shadow: map-get($map: $shadows, $key: "redOutline");
		@include redShadow2;

		&_text {
			text-align: center;
			font-size: 1.7rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "50");
		}
	}
}
