@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCLoadedTemplateValidator {
	@include fill_container;
	padding: 2rem 1rem;

	&_info {
		width: 100%;
		display: block;
		padding: 0 2rem;
	}
	&_summary {
		width: 100%;
		height: auto;
	}
}

.Name {
	font-size: 2rem;
	font-weight: 700;
	color: map-get($map: $blueGreyMap, $key: "600");
	@include flex_row(flex-start, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
	}
}
.Desc {
	font-size: 2rem;
	font-weight: 700;
	color: map-get($map: $blueGreyMap, $key: "600");
	@include flex_row(flex-start, center);

	&_icon {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
	}
}
