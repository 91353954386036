@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Empty {
	width: max-content;
	height: max-content;

	&_inner {
		width: max-content;
		height: max-content;
		@include flex_col(center, center);

		&_icon {
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $colors, $key: "mainBlackBlue");
		}
		&_text {
			@include flex_col(center, center);
			text-align: center;

			&_heading {
				font-size: 1.6rem;
				font-weight: 700;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}
			&_subheading {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreys, $key: "headings");
			}
		}
		&_optional {
			width: max-content;
			height: max-content;
		}
	}
}
