@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityBadge {
	width: 4rem;
	height: 4rem;
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	background-color: #eaecef;
	@include flex_row(center, center);

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: #111;
		&:hover {
			cursor: pointer;
			fill: map-get($map: $colors, $key: "mainBlue");
		}
	}

	&:hover &_icon {
		fill: map-get($map: $colors, $key: "mainBlue");
		cursor: pointer;
	}
}
