@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ContactUs {
	width: 100%;
	height: auto;
	@include flex_row(space-evenly, center, wrap);
}

.ContactCard {
	width: 35rem;
	height: 35rem;
	padding: 1rem 0;
	background-color: #ffffff;
	border: 1px solid #000;
	border-radius: 2rem;
	@include flex_col(center, center);
	margin: 1rem;

	&_wrapper {
		width: 15rem;
		min-width: 15rem;
		min-height: 15rem;
		height: 15rem;
		border-radius: 50%;
		background-color: map-get($map: $brandColors, $key: "primary");
		@include flex_row(center, center);
		margin-bottom: 2rem;

		&_icon {
			width: 10rem;
			height: 10rem;
			fill: #ffffff;
		}
	}
	&_content {
		@include fill_container;
		padding: 1rem 2rem;
	}
}
// CONTACT CARD(S) //
.FormCard,
.PhoneCard,
.EmailCard {
	@include fill_container;
	display: block;

	&_item {
		width: 100%;
		@include flex_row(flex-start, center);
		margin: 1rem 0;

		&_icon {
			width: 1.5rem;
			height: 1.5rem;
			fill: map-get($map: $brandColors, $key: "primary");
			margin-right: 0.5rem;
		}

		&_label {
			font-size: 1.5rem;
		}
		&_link {
			display: block;
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $brandColors, $key: "secondary");
			margin-left: 0.5rem;
			text-decoration: none;
			@include hoverEffect(
				map-get($map: $brandColors, $key: "secondary"),
				map-get($map: $brandColors, $key: "primary")
			);
		}
	}
	&_actions {
		width: 100%;
		height: 5rem;
		margin-top: 2rem;
		@include flex_row(center, flex-end);

		&_callNow {
			padding: 1.2rem 1.5rem;
			border-radius: 5rem;
			background-color: map-get($map: $brandColors, $key: "primary");
			color: #ffffff;
			font-size: 1.6rem;
			font-weight: 600;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "medium");
			}
		}
	}
}
.PhoneCard {
	@include fill_container;
	&_link {
		font-size: 1.6rem;
		font-weight: 600;
	}
}
.RedirectCard {
	@include fill_container;
	&_link {
		font-size: 1.6rem;
		font-weight: 600;
	}
}
