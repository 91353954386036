@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Step {
	@include fill_container;
	position: relative;

	&_icon {
		width: 100%;
		height: auto;
		margin-top: 2rem;
		padding-top: 2rem;
		@include flex_row(center, flex-start);
	}

	&_header {
		margin-top: 2rem;
		width: 100%;
		height: 15rem;
		@include flex_col(center, center);
		margin-bottom: 2rem;

		&_title {
			text-align: center;
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $colors, $key: "main");
		}
		&_desc {
			text-align: center;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreys, $key: "headings");
		}
	}
	&_inner {
		@include fill_container;
		height: auto;
		min-height: 20rem;
	}
	&_skip {
		width: 100%;
		@include flex_row(center, center);
	}

	&_buttons {
		width: 100%;
		height: auto;
		margin-top: 4rem;
		padding: 2rem 2rem;
		@include flex_row(space-between, flex-start);

		&_icon {
			width: 1.5rem;
			height: 1.5rem;
			fill: #ffffff;
		}
	}
}

.IconBadge {
	position: absolute;
	top: -10%;
	left: 50%;
	transform: translate(-50%, -5%);
	width: 12rem;
	height: 12rem;
	border-radius: 50%;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");
	@include flex_row(center, center);
	box-shadow: map-get($map: $shadows, $key: "medSubtle");

	&_icon {
		width: 7rem;
		height: 7rem;
		// fill: map-get($map: $colors, $key: "main");
		fill: map-get($map: $greys, $key: "whiteish");
	}
}

.StepButton {
	max-height: 4.6rem;
	padding: 0.7rem 1.5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "main");
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 1.6rem;
	font-weight: 600;
	@include flex_row(center, center);
	&:focus-within,
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "mainOutline");
	}
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

@media only screen and (max-width: 650px) {
	.Step {
		@include fill_container;
	}
	.IconBadge {
		width: 8rem;
		height: 8rem;
	}
}
