@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.BadgesLegend {
	width: fit-content;
	height: auto;

	&_toggle {
		width: auto;
		margin-bottom: 2rem;

		&_btn {
			font-size: 1.4rem;
			font-weight: 600;
			border: none;
			outline: none;
			background-color: transparent;
			color: map-get($map: $purpleMap, $key: "700");
			cursor: pointer;
		}
	}

	&_details {
		@include fill_container;
		width: 80%;
		@include flex_row(flex-start, baseline, wrap);
		transition: 0.4s ease-in-out;

		&_example {
			min-width: auto;
			width: fit-content;
			height: fit-content;
			@include flex_col(center, center);
			margin-bottom: 1rem;
			margin-left: 1rem;
			margin-right: 1rem;

			&_label {
				font-size: 1.3rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
}
