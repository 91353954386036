@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserSuspensionSwitch {
	width: auto;
	margin-bottom: 2rem;

	&_details {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		margin-bottom: 0.3rem;
	}
}
