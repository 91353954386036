@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilitySearchDetails {
	@include fill_container;

	&_id {
		font-size: 1.6rem;
		font-weight: 600;
		@include flex_row(flex-start, center);
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 1rem;

		& > b {
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}

	&_about {
		width: auto;
		height: auto;
	}

	&_main {
		@include fill_container;
	}

	&_desc {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}
	&_apps {
		width: auto;

		&_warning {
			margin-bottom: 4rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
}
.FacilityAddress {
	width: 100%;

	&_name {
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
}
.AddressUI {
	display: block;

	&_text {
		margin: 0.3rem 0;
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "headings");
	}
}
.FacilitySuspendButton {
	width: auto;
	margin-bottom: 2rem;

	&_details {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 2rem;
	}

	&_label {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		margin-bottom: 0.3rem;
	}

	&_isSuspended {
		margin-top: 2rem;
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
	}
}
