@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAvatar {
	min-width: max-content;
	min-height: max-content;
	border-radius: 50%;
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	background-color: #fff;

	&_inner {
		border-radius: 50%;

		&_fallback {
			background-color: transparent;
			border-radius: 50%;
			fill: map-get($map: $blueGreyMap, $key: "600");
		}
		&_img {
			border-radius: 50%;
			background-color: transparent;
		}
	}
}
