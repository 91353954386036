@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateUserSummary {
	@include fill_container;
	display: block;

	&_summary {
		@include fill_container;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin-bottom: 2rem;
		}
		&_details {
			width: auto;

			&_item {
				width: auto;
				margin-bottom: 2rem;

				&_label {
					font-size: 1.5rem;
					font-weight: 600;
					color: map-get($map: $blueGreyMap, $key: "400");
				}
				&_value {
					font-size: 1.7rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "700");
				}
				&_phone {
					font-size: 1.65rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "700");
					font-family: map-get($map: $fonts, $key: "altSans");
				}
			}
		}
	}

	&_more {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		margin-bottom: 4rem;
	}

	&_actions {
		margin-top: 2rem;
		width: 100%;
		@include flex_row(flex-end, center);
	}
}

.SummaryItem {
	width: auto;
	margin-bottom: 2rem;

	&_label {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "400");
	}
	&_value {
		font-size: 1.7rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_phone {
		font-size: 1.65rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}

.HiddenPassword {
	width: max-content;
	min-width: 20rem;
	height: 3.5rem;
	border-radius: 0.5rem;
	@include flex_row(flex-start, center);

	&_val {
		font-size: 1.7rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_toggle {
		margin-left: auto;
		@include flex_row(flex-start, center);

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $blueGreyMap, $key: "500");
			margin-left: 1rem;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $blueGreyMap, $key: "700");
			}
		}
	}
}
