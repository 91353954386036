@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Dropdown {
	min-width: max-content;
	min-height: max-content;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_inputWrapper {
		position: relative;
		width: 20rem;
		height: 3.5rem;
		border-radius: 0.5rem;
		background-color: #ffffff;
		@include flex_row(flex-start, center);
		border: 1px solid #ccc;

		&:hover,
		&:focus {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}

		&_input {
			width: 100%;
			height: 100%;
			padding-left: 0.5rem;
			font-size: 1.6rem;
			font-weight: 500;
			border-radius: 0.5rem;
			color: map-get($map: $colors, $key: "mainBlackBlue");
			outline: none;
			border: none;
			background-color: #ffffff;
		}
		&_closeIcon {
			width: 2rem;
			height: 2rem;
			margin-left: auto;
			margin-right: 0.5rem;
		}
	}
}
