@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LOCTableColumns {
	width: 100%;
	height: 5rem;

	&_inner {
		@include fill_container;
		@include flex_row(space-evenly, center);
	}
}
