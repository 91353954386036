@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginError {
	@include fill_container;
	max-height: 100vh;
	@include flex_col(flex-start, flex-start);

	&_wrapper {
		width: 100%;
		height: auto;
		@include flex_row(center, center);

		&_icon {
			width: 20rem;
			height: 20rem;
			fill: map-get($map: $redMap, $key: "600");
		}
		&_title {
			font-size: 8rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_actions {
		margin-top: 4rem;
		width: 100%;
		@include flex_row(center, center);
	}
}
.LoginAction {
	padding: 2rem 3.6rem;
	border-radius: 1rem;
	background-color: map-get($map: $redMap, $key: "600");
	color: #ffffff;
	font-size: 2rem;
	font-weight: 600;
	border: none;
	outline: none;
	margin: 0 1rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "medium");
	}
}
.ResetAction {
	padding: 2rem 3.6rem;
	border-radius: 1rem;
	background-color: map-get($map: $blueMap, $key: "600");
	color: #ffffff;
	font-size: 2rem;
	font-weight: 600;
	border: none;
	outline: none;
	margin: 0 1rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "medium");
	}
}
.DesignUI {
	display: block;
	margin: 2rem auto;
	width: 50rem;
	height: 60rem;

	&_img {
		@include fill_container;
	}
}
.ErrorDetails {
	display: block;
	margin: 0 auto;
	width: 80%;
	margin-top: 4rem;
	margin-bottom: 4rem;
	text-align: center;
	@include flex_col(center, center);

	&_reason {
		font-size: 4rem;
		font-weight: 700;
		color: map-get($map: $redMap, $key: "500");
	}
	&_desc {
		font-size: 3rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "500");
	}
}

@media only screen and (max-width: 570px) {
	.LoginError {
		@include fill_container;
		padding: 0 0;
		margin: 0 0;
	}

	// artwork UI
	.DesignUI {
		display: block;
		margin: 1rem auto;
		width: 100%;
		height: 60vh;

		&_img {
			@include fill_container;
		}
	}

	// error details
	.ErrorDetails {
		width: 100%;
		max-width: 100vw;
		height: auto;
		margin-top: 2rem;
		margin-bottom: 2rem;
		text-align: center;
		@include flex_col(center, center);
		&_reason {
			width: 100%;
			@include flex_row(center, center);
			text-align: center;
			font-size: 3.6rem;
			&_reason {
				font-weight: 700;
			}
			color: map-get($map: $redMap, $key: "500");
		}
		&_desc {
			width: 100%;
			@include flex_row(center, center);
			text-align: center;
			font-size: 2.7rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "500");
		}
	}

	// action buttons
	.ResetAction,
	.LoginAction {
		padding: 1.5rem 2rem;
		font-size: 1.8rem;
	}
}
@media only screen and (max-width: 460px) {
	.LoginError {
		@include fill_container;
		padding: 0 0;
		margin: 0 0;
	}
	// artwork UI
	.DesignUI {
		display: block;
		margin: 0 auto;
		width: 100%;
		height: 60%;
		margin-bottom: 1rem;

		&_img {
			@include fill_container;
		}
	}

	// error details
	.ErrorDetails {
		width: 100%;
		height: auto;
		max-width: 100vw;
		margin-top: 2rem;
		margin-bottom: 2rem;
		text-align: center;
		@include flex_col(center, center);

		&_reason {
			font-weight: 700;
		}

		&_desc {
			font-size: 2.2rem;
		}
	}

	.ResetAction,
	.LoginAction {
		padding: 1.2rem 1.6rem;
		font-size: 1.6rem;
	}
}
