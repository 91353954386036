@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SetResetMethodPreference {
	width: 100%;
	height: auto;

	&_summary {
		width: 100%;
		height: auto;
	}
	&_preference {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		padding-left: 1.5rem;
		margin-top: 1rem;

		&_label {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_selection {
			width: auto;
			margin-left: 1rem;
			margin-left: auto;
		}
	}
}
