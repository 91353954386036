@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.StrengthIndicator {
	width: 100%;
	height: auto;
	margin-top: 1rem;
	margin-left: 1rem;
	@include flex_row(flex-start, center);

	&_strength {
		width: 18rem;
		height: 0.5rem;
		background-color: map-get($map: $colors, $key: "mainGrey");
		border: 1px solid #ccc;
		border-radius: 5rem;
		@include flex_row(flex-start, center);

		&_bar {
			width: 30%;
			height: 0.5rem;
			border-radius: 5rem;
			background-color: map-get($map: $colors, $key: "mainOrange");
		}
	}
	&_label {
		margin-left: 0.3rem;
	}
}
