@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PortalOptions {
	@include fill_container;
	display: block;
	margin: 1rem auto;

	&_grid {
		width: 100%;
		height: auto;
		min-height: 40vh;
		@include flex_row(space-evenly, center, wrap);
	}
}

a[href^="/portal"] {
	text-decoration-color: map-get($map: $purpleMap, $key: "500");
}

.PortalPageCard {
	width: 15rem;
	height: 10rem;
	background-color: #ffffff;
	border-radius: 0.5rem;
	margin: 1rem;
	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "medSubtle");
	}

	&_top {
		width: 100%;
		height: 80%;
		@include flex_col(center, center);
		padding-top: 1rem;

		&_icon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $blueGreyMap, $key: "600");
			margin-bottom: 0.5rem;
		}
		&_name {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
	&_pageLink {
		width: 100%;
		height: 20%;
		padding: 0 1rem;
		@include flex_row(flex-end, center);

		&_text {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $purpleMap, $key: "600");
			text-decoration: none;
		}
		&_icon {
			margin-left: 0.3rem;
			width: 1.4rem;
			height: 1.4rem;
			fill: map-get($map: $purpleMap, $key: "600");
		}
	}
}

@media only screen and (max-width: 650px) {
	.PortalOptions {
		@include fill_container;
		display: block;
		margin: 1rem auto;

		&_grid {
			width: 100%;
			height: auto;
			min-height: 40vh;
			@include flex_row(space-evenly, center, wrap);
			padding-bottom: 6rem;
		}
	}
}
