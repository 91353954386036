@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserBadge {
	position: relative;
	width: max-content;
	height: max-content;
	@include flex_row(center, center);
	border-radius: 50%;
	box-shadow: map-get($map: $shadows, $key: "subtle");

	&_initials {
		@include fill_container;
		@include flex_row(center, center);
		background-color: transparent;
	}
}

.AdminBadge {
	position: absolute;
	top: 0.1rem;
	right: -0.1rem;
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 50%;
	background-color: map-get($map: $yellowMap, $key: "200");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	@include flex_row(center, center);

	& span {
		color: map-get($map: $yellowMap, $key: "200");
		font-size: 0.8rem;
	}
}
.SuperUserBadge {
	position: absolute;
	top: 0.1rem;
	right: -0.1rem;
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 50%;
	background-color: map-get($map: $redMap, $key: "600");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	@include flex_row(center, center);
	& span {
		font-size: 0.8rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "50");
		margin-top: -0.08rem;
	}
}
.FacilityAdminBadge {
	position: absolute;
	top: 0.1rem;
	right: -0.1rem;
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 50%;
	background-color: map-get($map: $redMap, $key: "300");
	box-shadow: map-get($map: $shadows, $key: "medSubtle");
	@include flex_row(center, center);
	& span {
		font-size: 0.8rem;
		color: map-get($map: $redMap, $key: "700");
	}
}
.UserToolTip {
	position: absolute;
	top: -1rem;
	left: 50%;
	transform: translate(-50%, -50%);
	width: max-content;
	height: max-content;
	padding: 0.3rem 0.5rem;
	border-radius: 0.5rem;
	background-color: map-get($map: $colors, $key: "mainBlackBlue");

	&_userName {
		font-size: 1.1rem;
		font-weight: 600;
		color: #ffffff;
	}
}
