@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAccessRow {
	width: 100%;
	height: 6rem;
	padding: 0.5rem 1rem;
	@include flex_row(space-evenly, center);

	&_userCol {
		position: relative;
		width: 30%;
		height: 100%;
		@include flex_row(flex-start, center);
		&:hover {
			cursor: pointer;
		}
		&:hover &_name {
			color: map-get($map: $purpleMap, $key: "600");
			cursor: pointer;
		}

		&_name {
			margin-left: 0.3rem;
			font-size: 1.4rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_lockIcon {
			position: absolute;
			top: -0.8rem;
			right: -0.6rem;
			width: 2rem;
			height: 2rem;
			fill: map-get($map: $redMap, $key: "500");
			filter: drop-shadow(3px 4px 16px rgba(0, 0, 0, 0.55));
		}
		&_email {
			position: absolute;
			top: 3rem;
			left: 4.5rem;
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_legacy {
		// width: 14.5%; // w/ EMAR/EPAY
		// width: 22.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		width: 17.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		height: 100%;
		padding: 0 1rem;

		&_access {
			// @include flex_row(center, center);
			@include flex_row(flex-start, center);
			width: 100%;
			height: 100%;
		}
	}
	&_tracker {
		// width: 14.5%; // w/ EMAR/EPAY
		width: 17.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		height: 100%;
		padding: 0 1rem;

		&_access {
			@include flex_row(center, center);
			width: 100%;
			height: 100%;
		}
	}
	&_portal {
		// width: 14.5%; // w/ EMAR/EPAY
		width: 17.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		height: 100%;
		padding: 0 1rem;

		&_access {
			// @include flex_row(center, center); // w/ EMAR/EPAY
			@include flex_row(flex-end, center);
			width: 100%;
			height: 100%;
		}
	}
	&_emar {
		width: 14.5%;
		// width: 17.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		height: 100%;
		padding: 0 1rem;

		&_access {
			@include flex_row(center, center);
			width: 100%;
			height: 100%;
		}
	}
	&_ePay {
		width: 14.5%;
		// width: 17.5%; // change to: 17.5% to accomodate 'AdminPortal' column
		height: 100%;
		padding: 0 1rem;

		&_access {
			@include flex_row(center, center);
			width: 100%;
			height: 100%;
		}
	}

	&_deactivate {
		width: auto;
		margin-left: auto;

		&_col {
			@include fill_container;

			&_btn {
				padding: 0.5rem 1.3rem;
				background-color: transparent;
				border-radius: 0.5rem;
				border: 2px solid map-get($map: $redMap, $key: "600");
				color: map-get($map: $redMap, $key: "700");
				outline: none;
				font-size: 1.4rem;
				font-weight: 500;

				&:hover {
					cursor: pointer;
					background-color: map-get($map: $redMap, $key: "600");
					color: #ffffff;
				}
			}
		}
	}

	&:nth-child(odd) {
		border: 1px solid map-get($map: $blueGreyMap, $key: "200");
		border-left: none;
		border-right: none;
	}
}

.LockoutIcon {
	position: absolute;
	top: -0.5rem;
	left: -0.6rem;
	width: 2rem;
	height: 2rem;
	fill: map-get($map: $redMap, $key: "500");
	filter: drop-shadow(3px 4px 16px rgba(0, 0, 0, 0.55));
}

.SuspendButton {
	padding: 0.5rem 1.3rem;
	background-color: transparent;
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $redMap, $key: "600");
	color: map-get($map: $redMap, $key: "700");
	outline: none;
	font-size: 1.4rem;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $redMap, $key: "600");
		color: #ffffff;
	}
	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}
.UnsuspendButton {
	padding: 0.5rem 1.3rem;
	background-color: map-get($map: $greenMap, $key: "500");
	border-radius: 0.5rem;
	border: 2px solid map-get($map: $greenMap, $key: "500");
	color: #ffffff;
	outline: none;
	font-size: 1.4rem;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: map-get($map: $greenMap, $key: "500");
		color: #ffffff;
	}
	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}
