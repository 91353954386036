@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserDropdown {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	min-width: 35rem;
	border-radius: 0.5rem;
	position: relative;

	&_input {
		position: relative;
		width: 100%;
		height: 3.5rem;
		padding: 0.5rem;
		font-size: 1.4rem;
		border-radius: 0.5rem;
		border: 1px solid map-get($map: $blueGreyMap, $key: "300");
		@include flex_row(flex-start, center);
		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
		& > b {
			font-weight: 400;
			margin: 0 0.3rem;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&:last-child {
			color: map-get($map: $blueGreyMap, $key: "600");
		}

		&_downCaret {
			position: absolute;
			top: 1rem;
			right: 0.5rem;
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreyMap, $key: "600");
			z-index: 2;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $purpleMap, $key: "600");
			}
		}

		&_placeholder {
			padding-left: 0.5rem;
			color: map-get($map: $blueGreyMap, $key: "500");
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
		}
	}
}
