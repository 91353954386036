@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AddQuestions {
	@include fill_container;
	max-width: 60rem;
	display: block;
	margin: 0 auto;

	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
